import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { object, string, ref } from 'yup';

import { Link as L } from 'react-router-dom';
import { Header } from '@assets/styles/commonComponents';
import { ButtonBase, TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { passwordRegex } from '@utils/constants/regex';

import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

interface IProps {
  handleSubmit: any;
}

const CancelButton = styled(L)`
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: ${fontSizes.paragraph1};
`;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: 300,
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    submit: {
      marginTop: 100
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 50
    },
    customerColor: {
      color: theme.palette.secondary.main
    }
  })
);

const newPasswordSchema = object({
  newPass: string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters longs.')
    .matches(
      passwordRegex,
      'Password must contain a capital letter, a special character and a digit.'
    ),
  confirmPass: string()
    .required('Password confirm is required')
    .oneOf([ref('newPass')], 'Passwords do not match')
});

const NewPassword: React.FC<IProps> = ({ handleSubmit }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      newPass: '',
      confirmPass: ''
    },
    validationSchema: newPasswordSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ newPass }) => {
      handleSubmit(newPass);
    }
  });

  return (
    <React.Fragment>
      <Header>
        <h1>Change Password</h1>
      </Header>
      <form
        className={classes.root}
        onSubmit={formik.handleSubmit}
        color="primary"
      >
        <p className={classes.description}>Please enter your new password.</p>
        <TextField
          required
          id="password"
          name="newPass"
          type="password"
          color="secondary"
          label="New Password"
          placeholder="Enter your new password"
          value={formik.values.newPass}
          onChange={formik.handleChange}
          error={!!formik.errors.newPass}
          helperText={formik.errors.newPass}
        />
        <TextField
          required
          type="password"
          color="secondary"
          id="confirm_password"
          name="confirmPass"
          label="Re-write new password"
          placeholder="Re-write your new password"
          value={formik.values.confirmPass}
          onChange={formik.handleChange}
          error={!!formik.errors.confirmPass}
          helperText={formik.errors.confirmPass}
        />
        <ButtonBase type="submit" className={classes.button}>
          Change
        </ButtonBase>
        <CancelButton className={classes.customerColor} to={'/settings'}>
          Cancel
        </CancelButton>
      </form>
    </React.Fragment>
  );
};

export default NewPassword;
