import { createStyles, makeStyles } from '@material-ui/core';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

export const globalStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      position: 'relative',
      padding: '0px 40px', //?
      [theme.breakpoints.down('md')]: {
        padding: '15px 25px 15px 15px'
      }
    },
    wrapper2: {
      position: 'relative',
      padding: '30px 60px',
      [theme.breakpoints.down('md')]: {
        padding: '15px 25px 15px 15px'
      }
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      width: '100%',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: fontSizes.h5,
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      padding: '17px 0 !important',
      marginBottom: '20px !important',
      color: `${colors.white} !important`,
      backgroundColor: `${theme.palette.secondary.main} !important`
    },
    button2: {
      width: '100%',
      marginTop: 50
    },
    buttonOutlined: {
      width: '100%',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: fontSizes.h5,
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      padding: '17px 0 !important',
      marginBottom: '20px !important',
      backgroundColor: `${colors.white} !important`,
      color: `${theme.palette.secondary.main} !important`,
      border: `1px solid ${theme.palette.secondary.main} !important`
    },
    bold: {
      fontWeight: 'bold'
    },
    disabled: {
      backgroundColor: `${theme.palette.secondary.main}30`
    },
    active: {
      color: colors.lightPink
    },
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.lightInputText
    },
    contentWrapper: {
      padding: '25px 40px',
      [theme.breakpoints.down('md')]: {
        padding: '15px'
      }
    }
  })
);
