export default {
  SCORE: 1,
  REPORT: 2,
  ALERTS: 4,
  RESTORE: 8,
  SCORE_CHANGE: 16,
  SCORE_HISTORY: 32,
  SCORE_FACTORS: 64,
  SCORE_DETAILS: 128,
  REPORT_SCORE: 256,
  REPORT_ACCOUNTS: 512,
  REPORT_OTHER: 1024,
  WEEKLY_UPDATE: 2048,
  THREE_B: 4096,
  THREE_B_ALERTS: 8192,
  REAL_TIME_ALERTS: 16384,
  SCORE_UP: 32768
  /*
  : 2048,
  : 4906,
  : 8192,
  : 16384,
  : 32768,
  : 65536,
  : 131072,
  : 262144,
  : 524288,
  : 1048576,*/
};

// public static final int SCORE = 1;
//     REPORT = 2;
//     ALERTS = 4;
//     RESTORE = 8;
//     SCORE_CHANGE = 16;
//     SCORE_HISTORY = 32;
//     SCORE_FACTORS = 64;
//     SCORE_DETAILS = 128;
//     REPORT_SCORE = 256;
//     REPORT_ACCOUNTS = 512;
//     REPORT_OTHER = 1024;
//     WEEKLY_UPDATE = 2048;
//     THREE_B = 4096;
//     THREE_B_ALERTS = 8192;
//     REAL_TIME_ALERTS = 16384;
