import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@assets/styles/colors';

import Monitoring from '@components/Monitoring';
import ICreditMonitoring from '@interfaces/creditMonitoring';
import fontSizes from '@assets/styles/fontSizes';
import NoData from '@components/NoData';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.lightInputText
    }
  })
);

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  margin: 0 -30px -30px -30px;
  background: ${colors.background};
`;

const Card = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
`;

const Alerts: React.FC<IProps> = () => {
  const classes = useStyles();
  const { dashboardStore } = useStores();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    dashboardStore.getCreditMonitoring();
  }, [dashboardStore]);

  useEffect(() => {
    if (dashboardStore.creditMonitoring) {
      setData(dashboardStore.creditMonitoring);
    }
  }, [dashboardStore.creditMonitoring, setData]);
  return (
    <>
      {!data.length && !dashboardStore.creditMonitoringLoaded && (
        <div className={classes.loading}>
          <p>Loading your data</p>
        </div>
      )}

      {!data.length && dashboardStore.creditMonitoringLoaded && (
        <Card style={{ display: 'flex' }}>
          <NoData text="You have no alerts to display." />
        </Card>
      )}
      {data.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: fontSizes.paragraph1
            }}
          >
            <p>Credit Monitoring</p>
            <a href={'/#'}>View all</a>
          </div>
          <Wrapper>
            {data.slice(0, 4).map((m: ICreditMonitoring) => (
              <Monitoring showMargin={true} data={m} key={m.id} multi={false} />
            ))}
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default observer(Alerts);
