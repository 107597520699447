import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import { Route } from '@utils/enums/routes';

import FailSrc from '@assets/icons/fail';

interface IVerificationFailureProps {}

const useStyles = makeStyles(theme => ({
  button: {
    padding: '17px 0',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '0.075em',
    textTransform: 'uppercase',
    color: colors.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: fontSizes.h5,
    width: '100%',
    marginTop: 100,
    marginBottom: 20
  }
}));

const Icon = styled.div`
  display: flex;
  margin: auto;
  margin-top: -20px;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: ${fontSizes.h2};
  font-weight: bold;
  margin-bottom: 40px;
`;

const Container = styled.div`
  text-align: center;
`;

const VerificationFailure: React.FC<IVerificationFailureProps> = () => {
  const classes = useStyles();
  return (
    <Container>
      <Icon>
        <FailSrc />
      </Icon>
      <Title>Mobile Link Failure</Title>
      <p>We were unable to sufficiently confirm your identity.</p>
      <ButtonBase
        className={classes.button}
        component={Link}
        to={`/${Route.EID}`}
      >
        Ok
      </ButtonBase>
    </Container>
  );
};

export default VerificationFailure;
