import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase, TextField } from '@material-ui/core';
import { Header } from '@assets/styles/commonComponents';

import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';

import { Route } from '@utils/enums/routes';

import LogoSrc from '@assets/images/logo.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: 80,
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    submit: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginBottom: 20,
      marginTop: 100
    },
    disabled: {
      backgroundColor: `${theme.palette.secondary.main}30`
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1,
      textAlign: 'left'
    },
    customerColor: {
      color: theme.palette.secondary.main
    },
    linkColor: {
      color: theme.palette.action.active
    }
  })
);

interface IProps {
  code: string;
  SMSCode: string;
  handleSubmit: any;
  setCode: Function;
  resendCode: Function;
  transactionKey: string;
}

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Resend = styled.a`
  display: block;
  text-align: right;
  font-size: ${fontSizes.paragraph2};
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  font-size: ${fontSizes.h5};
`;

const AnswerText = styled.div`
  text-align: center;
`;

const Title = styled.span`
  font-size: ${fontSizes.h1};
`;

const VerificationCode: React.FC<IProps> = ({
  handleSubmit,
  code,
  transactionKey,
  setCode,
  SMSCode,
  resendCode
}) => {
  const classes = useStyles();
  const { userStore, appStore } = useStores();
  const { verifyInProgress, sendingNewCodeInProgress } = userStore;

  const config = appStore.getConfig();

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <React.Fragment>
      <Header>
        <Title>{config.mobileIdentityScreenTitleText}</Title>
        <br />
        {appStore.config.hasHeaderLogo && <Logo src={config.logo || LogoSrc} />}
      </Header>
      {/* <Container> */}
      <form className={classes.root} onSubmit={submit} color="primary">
        <p className={classes.description}>
          Please enter your verification code.
        </p>
        <TextField
          required
          type="number"
          id="verCode"
          value={code}
          color="secondary"
          label="Verification code"
          placeholder="Enter your verification code"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setCode(target.value);
          }}
        />

        {!sendingNewCodeInProgress && (
          <Resend
            className={classes.linkColor}
            href={'#'}
            onClick={() => {
              resendCode();
            }}
          >
            <span>Send a new code</span>
          </Resend>
        )}
        {sendingNewCodeInProgress && (
          <Resend className={classes.linkColor}>
            <span>Sending new code...</span>
          </Resend>
        )}

        {SMSCode && <span>Your code is: {SMSCode}</span>}
        <ButtonBase
          disabled={!code || verifyInProgress}
          type="submit"
          classes={{ root: classes.submit, disabled: classes.disabled }}
        >
          CONTINUE
        </ButtonBase>
        <AnswerText>
          <CustomLink className={classes.customerColor} to={`/${Route.EID}`}>
            Answer financial questions instead
          </CustomLink>
        </AnswerText>
      </form>
      {/* </Container> */}
    </React.Fragment>
  );
};

export default observer(VerificationCode);
