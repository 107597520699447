import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ContentWrapper from '@components/ContentWrapper';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';
import ButtonBase from '@material-ui/core/ButtonBase';
import fontSizes from '@assets/styles/fontSizes';
import { Events } from '@utils/enums/events';

import { Route } from '@utils/enums/routes';

import FailSrc from '@assets/icons/id_fail';

interface IEIdFailedProps {}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
`;

const Icon = styled.div`
  display: flex;
  margin: auto;
  margin-top: -20px;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: ${fontSizes.h2};
  font-weight: bold;
  margin-bottom: 40px;
`;

const Container = styled.div`
  text-align: center;
`;

export enum EidFailedRedirect {
  NONE = 'none',
  CLOSE = 'close',
  DEFAULT = 'default'
}

const EIdFailed: React.FC<IEIdFailedProps> = () => {
  const gs = globalStyles();
  const { appStore } = useStores();
  const { eidFailedRedirect } = appStore.getConfig();

  return (
    <ContentWrapper hasWrapper={!appStore.config.popupMode}>
      <Wrapper className={gs.wrapper}>
        <Container>
          <Icon>
            <FailSrc />
          </Icon>
          <Title>Identification Failure</Title>
          <p>We were unable to verify your identity at this time.</p>
          <p>
            Once you establish a credit record, you should be able to try again
            in approximately 30-days.
          </p>
          {eidFailedRedirect === EidFailedRedirect.DEFAULT && (
            <ButtonBase
              style={{ marginTop: 70 }}
              className={gs.button}
              component={Link}
              to={`/${Route.IDENTITY}`}
            >
              OK
            </ButtonBase>
          )}
          {eidFailedRedirect === EidFailedRedirect.CLOSE && (
            <ButtonBase
              style={{ marginTop: 70 }}
              className={gs.button}
              onClick={() => {
                window.parent.postMessage(
                  { type: Events.IDENTITY_FAILED_CLOSE_BTN_CLICK },
                  '*'
                );
              }}
            >
              Close
            </ButtonBase>
          )}
        </Container>
      </Wrapper>
    </ContentWrapper>
  );
};

export default EIdFailed;
