import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { globalStyles } from '@assets/styles/globalStyles';
import Button from '@material-ui/core/Button';

import ContentWrapper from '@components/ContentWrapper';

import UserNotFoundIcon from '@assets/images/user_not_found.svg';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const UserNotFound: React.FC<IProps> = () => {
  const gs = globalStyles();

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <Logo src={UserNotFoundIcon} />
        <h2>User Not Found</h2>
        <p>
          We are unable to locate your account <br />
          at this time.
        </p>
        <Button
          to="/login"
          component={Link}
          color="secondary"
          variant="contained"
          className={gs.button2}
        >
          BACK TO LOGIN
        </Button>
      </Wrapper>
    </ContentWrapper>
  );
};

export default UserNotFound;
