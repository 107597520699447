import React from 'react';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import FailSrc from '@assets/images/fail.svg';

interface IQuizFailureProps {
  setStep: Function;
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: '17px 0',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '0.075em',
    textTransform: 'uppercase',
    color: colors.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: fontSizes.h5,
    width: '100%',
    marginTop: 70,
    marginBottom: 20
  }
}));

const Icon = styled.img`
  display: flex;
  margin: auto;
  margin-top: -20px;
`;

const Title = styled.h2`
  font-size: ${fontSizes.h2};
  font-weight: bold;
  margin-bottom: 40px;
`;

const Container = styled.div`
  text-align: center;
`;

const QuizFailure: React.FC<IQuizFailureProps> = ({ setStep }) => {
  const classes = useStyles();

  const resetQuiz = () => setStep(0);

  return (
    <Container>
      <Icon src={FailSrc} />
      <Title>Quiz Failure</Title>
      <p>
        We were unable to accurately verify enough of your responses to
        continue.
      </p>
      <p>A new set of questions has been provided, please try again.</p>
      <ButtonBase
        className={classes.button}
        style={{ marginTop: 70 }}
        onClick={resetQuiz}
      >
        TRY AGAIN
      </ButtonBase>
    </Container>
  );
};

export default QuizFailure;
