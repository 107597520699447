import React, { useState } from 'react';
import styled from 'styled-components';
import * as api from '@api/';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';

import ContentWrapper from '@components/ContentWrapper';
import Success from '@components/ChangeRecoveryQuestion/Success';
import CurrentPassword from '@components/ChangeRecoveryQuestion/CurrentPassword';
import RecoveryQuestion from '@components/ChangeRecoveryQuestion/RecoveryQuestion';
import NewRecoveryQuestion from '@components/ChangeRecoveryQuestion/NewRecoveryQuestion';
import NewRecoveryQuestionAnswer from '@components/ChangeRecoveryQuestion/NewRecoveryQuestionAnswer';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
`;

const ChangeRecoveryQuestion: React.FC<IProps> = () => {
  const gs = globalStyles();
  const { userStore, notifyStore } = useStores();
  const [step, setStep] = useState<number>(0);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [recQuestion, setRecQuestion] = useState<number>(0);
  const [recQuestionAnswer, setRecQuestionAnswer] = useState<string>('');
  const [newRecQuestion, setNewRecQuestion] = useState<number>(0);
  const [newRecQuestionAnswer, setNewRecQuestionAnswer] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const checkPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await api.actionToken(
        userStore.user!.email,
        currentPassword
      );
      setToken(response.data.token);
      setRecQuestion(response.data.recoveryQuestion);
      nextStep();
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  const checkRecQuestion = () => {
    nextStep();
  };

  const changeQuestion = () => {
    nextStep();
  };

  const submitNewRecQuestionAnswer = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    try {
      await api.changeRecoveryQuestion(
        newRecQuestion,
        newRecQuestionAnswer,
        recQuestionAnswer,
        token
      );
      nextStep();
    } catch (error) {
      notifyStore.error('Recovery question change failed');
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CurrentPassword
            password={currentPassword}
            handleSubmit={checkPassword}
            setPassword={setCurrentPassword}
          />
        );
      case 1:
        return (
          <RecoveryQuestion
            recQuestion={recQuestion}
            recQuestionAnswer={recQuestionAnswer}
            setRecQuestionAnswer={setRecQuestionAnswer}
            handleSubmit={checkRecQuestion}
          />
        );
      case 2:
        return (
          <NewRecoveryQuestion
            question={newRecQuestion}
            setQuestion={setNewRecQuestion}
            handleSubmit={changeQuestion}
          />
        );
      case 3:
        return (
          <NewRecoveryQuestionAnswer
            newRecQuestionAnswer={newRecQuestionAnswer}
            setNewRecQuestionAnswer={setNewRecQuestionAnswer}
            handleSubmit={submitNewRecQuestionAnswer}
          />
        );
      case 4:
        return <Success />;
    }
  };

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <div>{renderContent(step)}</div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default ChangeRecoveryQuestion;
