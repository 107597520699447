export default {
  login: '/users/login',
  register: '/users/register',
  users: '/users',
  identity: '/users/identity',
  quiz: '/users/get-quiz',
  verifyQuiz: '/users/verify-quiz',
  verifyPhone: '/users/get-mobile',
  verifyDITIdentity: '/users/dit-identity',
  sendSMFALink: (token: string) => `/users/smfa-send-link/${token}`,
  verifySMFA: (token: string) => `/users/smfa-verify-status/${token}`,
  sendCode: '/users/send-code',
  sendNewCode: '/users/renew-code',
  verifyCode: '/users/verify-code',
  verifyEnrollment: '/users/enrollment-check',
  efxConfig: '/users/efx-config',
  scoreLatest: '/users/efx-latest-scores',
  scoreUp: '/users/efx-score-up',
  scoreHistory: '/users/efx-score-history',
  reportLatest: '/users/efx-latest-report',
  reportDownload: (reportId: string | number, token: string) =>
    `/v1/creditReport/${reportId}/print?access_token=${token}`,
  reportSummary: '/users/efx-latest-report/summary',
  creditMonitoring: '/users/efx-alerts',
  actionToken: '/users/action-token',
  changePassword: '/users/change-password',
  changeEmail: '/users/change-email',
  closeAccount: '/users/close-account',
  changeRecoveryQuestion: '/users/change-recovery',
  passwordRecovery: '/users/password-recovery',
  recoveryToken: '/users/recovery-token',
  passwordReset: '/users/password-reset',
  refreshToken: '/users/refresh-token',
  updateAlertNotifications: '/users/change-notifications',
  preauthLogin: (token: string) => `/users/preauth-token/${token}`
};
