import React from 'react';
import styled from 'styled-components';

import { createStyles, makeStyles } from '@material-ui/core';
import { globalStyles } from '@assets/styles/globalStyles';
import colors from '@assets/styles/colors';

import ContentWrapper from '@components/ContentWrapper';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    list: {
      margin: '30px 15px',
      padding: 0,
      maxWidth: 300,
      color: colors.violet,
      '& > li': {
        marginBottom: 20
      },
      '& > li span': {
        color: colors.lightBlack,
        fontWeight: 300
      }
    }
  })
);

const Wrapper = styled.div`
  text-align: left;
  position: relative;
`;

// const Link = styled.a`
//   color: ${colors.lightPink};
// `;

const IdRestore: React.FC<IProps> = () => {
  const classes = useStyles();
  const gs = globalStyles();

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <h1>Equifax® ID Restoration</h1>
        <div>
          <h3>Has your identity been compromised?</h3>
          <ul className={classes.list}>
            <li>
              <span>A specialist will help you work through the process.</span>
            </li>
            <li>
              <span>
                Once your issues are resolved, we continue to monitor the
                situation for three months.
              </span>
            </li>
            <li>
              <span>
                Call today so that we may help you verify and begin resolving
                any discrepancies.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <p className={gs.bold}>
            Call 1-877-368-4940 today to get started <br />
            {/*or <Link href="#">click here</Link> to learn more.*/}
          </p>
        </div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default IdRestore;
