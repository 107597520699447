import { observer } from 'mobx-react';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useStores } from '@utils/hooks/useStores';

import CustomSwitch from '@components/CustomSwitch';
import ContentWrapper from '@components/ContentWrapper';

import fontSizes from '@assets/styles/fontSizes';
import { globalStyles } from '@assets/styles/globalStyles';
import { createStyles, makeStyles } from '@material-ui/core';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      minHeight: 300
    }
  })
);

const Title = styled.h1`
  font-weight: 600;
  font-size: ${fontSizes.h1};
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  > div {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
  }
`;

const AlertNotifications: React.FC<IProps> = () => {
  const gs = globalStyles();
  const classes = useStyles();
  const {
    userStore: { user, updateUserNotifications }
  } = useStores();
  const [notifications, setNotifications] = useState<any>({
    emailMsg: false,
    smsMsg: false
  });

  useEffect(() => {
    setNotifications({
      emailMsg: user.emailMsg,
      smsMsg: user.smsMsg
    });
  }, [user]);

  return (
    <ContentWrapper stretch={true}>
      <div className={`${gs.wrapper} ${classes.wrapper}`}>
        <div>
          <div>
            <Title>Alert notifications</Title>
          </div>
          <Wrapper>
            <div>
              <span>Email messages</span>
              <CustomSwitch
                checked={notifications.emailMsg}
                onChange={() => {
                  updateUserNotifications({
                    emailMsg: !notifications.emailMsg,
                    smsMsg: notifications.smsMsg
                  });
                }}
              />
            </div>
            <div>
              <span>SMS messages</span>
              <CustomSwitch
                checked={notifications.smsMsg}
                onChange={() => {
                  updateUserNotifications({
                    emailMsg: notifications.emailMsg,
                    smsMsg: !notifications.smsMsg
                  });
                }}
              />
            </div>
          </Wrapper>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default observer(AlertNotifications);
