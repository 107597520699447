import React from 'react';
import styled from 'styled-components';

import { Link as L } from 'react-router-dom';
import { Header } from '@assets/styles/commonComponents';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase, InputLabel, FormControl, Select } from '@material-ui/core';

import questions from '@utils/constants/questions';

import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

interface IProps {
  question: number;
  handleSubmit: any;
  setQuestion: Function;
}

const CancelButton = styled(L)`
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: ${fontSizes.paragraph1};
`;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: 300,
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 100
    },
    customerColor: {
      color: theme.palette.secondary.main
    }
  })
);

const NewRecoveryQuestion: React.FC<IProps> = ({
  question,
  setQuestion,
  handleSubmit
}) => {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<any>) => {
    setQuestion(e.target.value);
  };

  return (
    <React.Fragment>
      <Header>
        <h1>
          Change Recovery <br />
          Question
        </h1>
      </Header>
      <form className={classes.root} onSubmit={handleSubmit} color="primary">
        <p className={classes.description}>
          Please select your new recovery question.
        </p>
        <FormControl>
          <InputLabel htmlFor="question">Question</InputLabel>
          <Select
            native
            value={question}
            color="secondary"
            onChange={handleChange}
            inputProps={{
              id: 'question',
              name: 'question'
            }}
          >
            {questions.map((q: string, i: number) => (
              <option value={i} key={i}>
                {q}
              </option>
            ))}
          </Select>
        </FormControl>
        <ButtonBase type="submit" className={classes.button}>
          Change
        </ButtonBase>
        <CancelButton className={classes.customerColor} to={'/settings'}>
          Cancel
        </CancelButton>
      </form>
    </React.Fragment>
  );
};

export default NewRecoveryQuestion;
