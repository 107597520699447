import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import instance, { sAxios, eAxios } from '@utils/api';
//@ts-ignore
import LoadingBar from 'react-top-loading-bar';
import { useStores } from '@utils/hooks/useStores';

export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  const inc = useCallback(() => setCounter(counter => counter + 1), [
    setCounter
  ]); // add to counter
  const dec = useCallback(() => setCounter(counter => counter - 1), [
    setCounter
  ]); // remove from counter

  const interceptors = useMemo(
    () => ({
      request: (config: any) => {
        inc();
        return config;
      },
      response: (response: any) => {
        dec();
        return response;
      },
      error: (error: any) => {
        dec();
        return Promise.reject(error);
      }
    }),
    [inc, dec]
  ); // create the interceptors

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = instance.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    const resInterceptor = instance.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );

    // add request interceptors
    const sReqInterceptor = sAxios.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    const sResInterceptor = sAxios.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );

    // add request interceptors
    const eReqInterceptor = eAxios.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    const eResInterceptor = eAxios.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      // remove all intercepts when done
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
      sAxios.interceptors.request.eject(sReqInterceptor);
      sAxios.interceptors.response.eject(sResInterceptor);
      eAxios.interceptors.request.eject(eReqInterceptor);
      eAxios.interceptors.response.eject(eResInterceptor);
    };
  }, [interceptors]);

  return [counter > 0];
};

export const ApiLoader: React.FC = () => {
  const { appStore } = useStores();
  const [loading] = useAxiosLoader();
  const loader: any = useRef(null);
  const color = appStore.config.theme || '#EF4D7E';

  useEffect(() => {
    if (loading) {
      if (loader.current) loader.current.continousStart();
    } else {
      if (loader.current) loader.current.complete();
    }
  }, [loading]);

  return loading ? (
    <LoadingBar className={'loader'} height={4} color={color} ref={loader} />
  ) : (
    <></>
  );
};
