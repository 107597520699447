import styled from 'styled-components';
import { Link } from 'react-router-dom';

import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

export const NavLink = styled(Link)`
  line-height: 16px;
  margin-right: 50px;
  text-decoration: none;
  font-size: ${fontSizes.h4};
  color: ${colors.lightBlack};
`;
