import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import useWindowSize from '@utils/helpers/resize';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

import LineChart from './LineChart';
import Modal from '@components/Modal';

import Utils from '@utils/efxutil';
import colors from '@assets/styles/colors';
import Flags from '@utils/constants/flags';
import fontSizes from '@assets/styles/fontSizes';
import { checkFlag } from '@utils/helpers/checkPermission';
import NoData from '@components/NoData';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex'
    },
    boxTitle: {
      margin: 0,
      fontWeight: 400,
      marginBottom: 20,
      fontSize: fontSizes.paragraph1
    },
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > div': {
          margin: 0,
          marginBottom: 10
        }
      }
    },
    lineChart: {
      width: '100%',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'hidden'
      }
    },
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.lightInputText
    },
    link: {
      paddingBottom: 3,
      color: theme.palette.secondary.main
    }
  })
);

interface IProps {
  activeStep: number;
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
`;

const Card = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
`;

const ScoreHistory: React.FC<IProps> = ({ activeStep }) => {
  const classes = useStyles();
  const { dashboardStore, appStore } = useStores();

  const [provider, setProvider] = useState<any>(null);
  const [sh, setSh] = useState<any>(null);
  const [sc, setSc] = useState<any>(null);
  const [score, setScore] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const ref = useRef(null);
  const ww = useWindowSize().width;

  useEffect(() => {
    dashboardStore.getLatestScore().then(res => {
      dashboardStore.getScoreHistory();
    });
  }, [dashboardStore]);

  useEffect(() => {
    if (
      dashboardStore.scoreHistory &&
      dashboardStore.latestScore &&
      dashboardStore.latestScore.providerViews
    ) {
      setProvider(
        dashboardStore.latestScore.providerViews[activeStep].provider
      );
      setScore(dashboardStore.latestScore.providerViews[activeStep].score);
      setSh(Utils._processScoreHistory(dashboardStore.scoreHistory));
      setSc(Utils._processScores(dashboardStore.latestScore));
    }
  }, [dashboardStore.latestScore, dashboardStore.scoreHistory, activeStep]);

  return (
    <div className={classes.root}>
      <Wrapper className={classes.wrapper}>
        {!sh && !dashboardStore.scoreHistoryLoaded && (
          <div className={classes.loading}>
            <p>Loading your data</p>
          </div>
        )}

        {!sh && dashboardStore.scoreHistoryLoaded && (
          <Card style={{ display: 'flex' }}>
            <NoData text="No score trend data to display." />
          </Card>
        )}
        {sh && (
          <Card>
            <div>
              <h2 className={classes.boxTitle}>
                Current score: <b>{score}</b>
              </h2>
              {checkFlag(appStore.getConfig().flags, Flags.SCORE_FACTORS) && (
                <p style={{ fontSize: fontSizes.paragraph2 }}>
                  View{' '}
                  <ButtonBase
                    className={classes.link}
                    onClick={() => {
                      setIsModalOpen(!isModalOpen);
                    }}
                  >
                    Score Factors
                  </ButtonBase>{' '}
                  to see what affects your Credit Score.
                </p>
              )}
            </div>
            <div className={classes.lineChart} ref={ref}>
              <LineChart
                width={
                  (ref.current &&
                    // @ts-ignore
                    ww > ref.current.offsetWidth &&
                    // @ts-ignore
                    ref.current.offsetWidth) ||
                  500
                }
                height={300}
                stroke={'#ffffff'}
                data={sh[provider].scores.slice().reverse()}
              />
            </div>
            <Modal closeModal={setIsModalOpen} isOpen={isModalOpen}>
              {sc && sc[provider].factors.positive.length > 0 && (
                <div>
                  <p>Positive Factors:</p>
                  <ul>
                    {sc[provider].factors.positive.map((f: any, i: number) => {
                      return <li key={i}>{f.description}</li>;
                    })}
                  </ul>
                  <hr />
                </div>
              )}
              {sc && sc[provider].factors.negative.length > 0 && (
                <div>
                  <p>Negative Factors:</p>
                  <ul>
                    {sc[provider].factors.negative.map((f: any, i: number) => {
                      return <li key={i}>{f.description}</li>;
                    })}
                  </ul>
                </div>
              )}
            </Modal>
          </Card>
        )}
      </Wrapper>
    </div>
  );
};

export default observer(ScoreHistory);
