import { observer } from 'mobx-react';
import colors from '@assets/styles/colors';
import { useStores } from '@utils/hooks/useStores';
import React, { useEffect, useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

interface IProps {}

const Theme: React.FC<IProps> = ({ children }) => {
  const { appStore } = useStores();
  const [theme, setTheme] = useState<any>(
    createMuiTheme({
      palette: {
        primary: {
          main: colors.white
        },
        secondary: {
          main: colors.lightPink,
          contrastText: colors.white
        },
        error: {
          main: colors.lightPink
        }
      },
      overrides: {
        MuiFormHelperText: {
          root: {
            marginTop: 5
          }
        },
        MuiInput: {
          underline: {
            '&::before': {
              borderColor: colors.gray,
              bottom: -5
            },
            '&::after': {
              bottom: -5
            }
          },
          input: {
            '&::placeholder': {
              color: colors.lightInputText
            }
          }
        }
      }
    })
  );

  useEffect(() => {
    setTheme(
      createMuiTheme({
        palette: {
          primary: {
            main: colors.white
          },
          secondary: {
            main: appStore.config.theme || colors.lightPink,
            contrastText: colors.white
          },
          action: {
            active: appStore.config.linkColor || appStore.config.theme
          }
        },
        overrides: {
          MuiFormHelperText: {
            root: {
              marginTop: 5
            }
          },
          MuiInput: {
            underline: {
              '&::before': {
                borderColor: colors.gray,
                bottom: -5
              },
              '&::after': {
                bottom: -5
              }
            },
            input: {
              '&::placeholder': {
                color: colors.lightInputText
              }
            }
          },
          MuiFormLabel: {
            root: {
              color: appStore.config.formLabelColor || 'inherit'
            },
            colorSecondary: {
              color: appStore.config.formLabelColor || 'inherit',
              '&$focused': {
                color: appStore.config.formLabelColor || 'inherit'
              }
            }
          }
        }
      })
    );
  }, [
    appStore.config.theme,
    appStore.config.formLabelColor,
    appStore.config.linkColor
  ]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default observer(Theme);
