import { action, observable, decorate } from 'mobx';
import * as api from '@api/';
import { NotifyStore } from '@stores/NotifyStore';
import { AppStore } from '@stores/AppStore';

import ICreditMonitoring from '@interfaces/creditMonitoring';
import ILatestCreditScore from '@interfaces/latestCreditScore';
import IScoreUp from '@interfaces/scoreUp';
import { checkFlag } from '@utils/helpers/checkPermission';
import Flags from '@utils/constants/flags';
// import ICreditScoreHistory from '@interfaces/creditScoreHistory';
// import ICreditReportSummary from '@interfaces/creditReportSummary';

export class DashboardStore {
  constructor(private notify: NotifyStore, private appStore: AppStore) {}

  public latestScore: any | null = null;
  public latestScoreLoaded: boolean = false;

  public scoreHistory: any | null = null;
  public scoreHistoryLoaded: boolean = false;

  public reportList: any | null = null;
  public reportListLoaded: boolean = false;

  public report: any | null = null;
  public reportLoaded: any | null = null;

  public creditMonitoring: any = null;
  public creditMonitoringLoaded: boolean = false;

  public scoreUp: any | null = null;
  public scoreUpLoaded: boolean = false;

  public reportId: any = null;

  public getLatestScore = async () => {
    try {
      if (!checkFlag(this.appStore.getConfig().flags, Flags.SCORE)) return null;
      const response = await api.getScoreLatest();
      if (response) {
        this.setLatestScore(response);
      }
    } catch (error) {
      this.notify.error('Cannot fetch latest score');
    }
  };

  setLatestScore = (latestScore: ILatestCreditScore) => {
    this.latestScore = latestScore;
    this.latestScoreLoaded = true;
  };

  public getScoreHistory = async () => {
    /*
    if (
      !(
        checkFlag(this.appStore.getConfig().flags, Flags.SCORE_HISTORY) ||
        checkFlag(this.appStore.getConfig().flags, Flags.SCORE_CHANGE)
      )
    )
      return null;
    */
    try {
      const response = await api.getScoreHistory();
      this.setScoreHistory(response);
    } catch (error) {
      this.notify.error('Cannot fetch score history');
    }
  };

  setScoreHistory = (historyScore: any) => {
    this.scoreHistory = historyScore;
    this.scoreHistoryLoaded = true;
  };

  public getReportList = async () => {
    if (!checkFlag(this.appStore.getConfig().flags, Flags.REPORT)) return null;
    try {
      const resp = await api.getReportSummary();
      this.processReportList(resp);
    } catch (error) {
      this.notify.error('Cannot fetch reports');
    }
  };

  processReportList = async (resp: any) => {
    try {
      this.setReportList(resp);
      this.setReportId(resp[0].id.replace(/-TU|-EFX|-EXP/, ''));
      let hasMissingCreditScoreData = false;
      resp.forEach((provider: any) => {
        if (!provider || !provider.creditScore) {
          hasMissingCreditScoreData = true;
        }
      });

      if (hasMissingCreditScoreData) {
        const latestScore = await api.getScoreLatest();
        if (latestScore) {
          this.setLatestScore(latestScore);
        }
      } else {
        let scoreData = {
          providerViews: resp.map(
            (rep: { creditScore: any }) => rep.creditScore
          ),
          id: '',
          scoreType: '',
          scoreModel: '',
          generatedDate: resp[0].reportGenerated
        };
        this.setLatestScore(scoreData);
      }
    } catch (error) {
      this.notify.error('Cannot fetch reports');
    }
  };

  setReportList = (report: any) => {
    this.reportList = report;
    this.reportListLoaded = true;
  };

  setReportId = (reportId: any) => {
    this.reportId = reportId;
  };

  public getReport = async () => {
    if (!checkFlag(this.appStore.getConfig().flags, Flags.REPORT)) return null;
    try {
      const resp = await api.getReport();
      this.setReport(resp);
      this.processReportList(resp?.providerViews.map((pv: any) => pv.summary));
    } catch (error) {
      this.notify.error('Cannot fetch reports');
    }
  };

  setReport = (report: any) => {
    this.report = report;
    this.reportLoaded = true;
  };

  public getCreditMonitoring = async () => {
    if (!checkFlag(this.appStore.getConfig().flags, Flags.ALERTS)) return null;
    try {
      const response = await api.getMonitoringAlerts();
      this.setCreditMonitoring(response);
    } catch (error) {
      this.notify.error('Cannot fetch credit monitoring');
    }
  };

  setCreditMonitoring = (creditMonitoring: ICreditMonitoring[]) => {
    this.creditMonitoring = creditMonitoring;
    this.creditMonitoringLoaded = true;
  };

  public getScoreUp = async (
    scoreInc: number = 10,
    timeHorizon: number = 6
  ) => {
    try {
      if (!checkFlag(this.appStore.getConfig().flags, Flags.SCORE_UP))
        return null;
      const response = await api.getScoreUp(scoreInc, timeHorizon);
      if (response) {
        this.setScoreUp(response);
      }
    } catch (error) {
      this.notify.error('Cannot fetch score up');
    }
  };

  setScoreUp = (scoreUp: IScoreUp) => {
    this.scoreUp = scoreUp;
    this.scoreUpLoaded = true;
  };

  public downloadReport = async (reportId: string | number) => {
    try {
      const response = await api.downloadReport(reportId);
      const file = new Blob([response.data], {
        type: 'application/pdf;charset=UTF-8'
      });
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      a.download = 'Report.pdf';
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      this.notify.error('Cannot download the report');
    }
  };
}

decorate(DashboardStore, {
  latestScore: observable,
  latestScoreLoaded: observable,
  scoreHistory: observable,
  scoreHistoryLoaded: observable,
  reportList: observable.ref,
  reportListLoaded: observable,
  report: observable,
  reportLoaded: observable,
  creditMonitoring: observable,
  creditMonitoringLoaded: observable,
  scoreUp: observable,
  scoreUpLoaded: observable,
  reportId: observable,
  getLatestScore: action,
  setLatestScore: action,
  getScoreHistory: action,
  setScoreHistory: action,
  getReportList: action,
  setReportList: action,
  getReport: action,
  setReport: action,
  getCreditMonitoring: action,
  setCreditMonitoring: action,
  getScoreUp: action,
  setScoreUp: action,
  downloadReport: action
});
