import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.2048 74.6708V65.4877C89.197 62.7839 88.1195 60.1932 86.2077 58.2813C84.2958 56.3695 81.7051 55.292 79.0013 55.2842V55.2842C76.2976 55.292 73.7068 56.3695 71.795 58.2813C69.8832 60.1932 68.8057 62.7839 68.7979 65.4877V74.6708"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M97.3678 74.6709H60.6353V100.18H97.3678V74.6709Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M79.0018 88.9557C81.2559 88.9557 83.0832 87.1284 83.0832 84.8744C83.0832 82.6203 81.2559 80.793 79.0018 80.793C76.7477 80.793 74.9204 82.6203 74.9204 84.8744C74.9204 87.1284 76.7477 88.9557 79.0018 88.9557Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M79.0005 88.9556V94.0573"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <circle
        cx="79"
        cy="80"
        r="47"
        className={classes.customerColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Icon;
