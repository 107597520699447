import React from 'react';
import styled from 'styled-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase, TextField } from '@material-ui/core';
import { useStores } from '@utils/hooks/useStores';
import { Header } from '@assets/styles/commonComponents';

import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import LogoSrc from '@assets/images/logo.svg';

interface IProps {
  verCode: string;
  handleSubmit: any;
  nextStep: Function;
  setVerCode: Function;
  resendCode: Function;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1,
      textAlign: 'left'
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 100,
      [theme.breakpoints.down('md')]: {
        fontSize: `${fontSizes.paragraph} !important`
      }
    },
    customerColor: {
      color: theme.palette.secondary.main
    },
    linkColor: {
      color: theme.palette.action.active
    },
    title: {
      fontWeight: 'bold',
      fontSize: fontSizes.h1,
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizes.h2
      }
    },
    disabled: {
      backgroundColor: `${theme.palette.secondary.main}30`
    }
  })
);

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Resend = styled.a`
  display: block;
  text-align: right;
  font-size: ${fontSizes.paragraph2};
`;

const Wrapper = styled.div`
  position: relative;
`;

const VerificationCode: React.FC<IProps> = ({
  verCode,
  setVerCode,
  nextStep,
  resendCode,
  handleSubmit
}) => {
  const classes = useStyles();
  const { appStore, userStore } = useStores();
  const { sendingNewCodeInProgress, verifyInProgress } = userStore;
  const config = appStore.getConfig();

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit}
        color="primary"
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Wrapper className={classes.root}>
          <Header>
            <h1 className={classes.title}>
              Forgot
              <br />
              Password
            </h1>
            {appStore.config.hasHeaderLogo && (
              <Logo src={config.logo || LogoSrc} />
            )}
          </Header>
          <p className={classes.description}>
            Please enter your verification code.
          </p>
          <TextField
            required
            type="number"
            id="verCode"
            value={verCode}
            color="secondary"
            label="Verification code"
            placeholder="Enter your verification code"
            onInput={e => {
              const target = e.target as HTMLInputElement;
              setVerCode(target.value);
            }}
          />

          {!sendingNewCodeInProgress && (
            <Resend
              className={classes.linkColor}
              href={'#'}
              onClick={() => {
                resendCode();
              }}
            >
              <span>Send a new code</span>
            </Resend>
          )}

          {sendingNewCodeInProgress && (
            <Resend className={classes.linkColor}>
              <span>Sending new code...</span>
            </Resend>
          )}
        </Wrapper>
        <Wrapper className={classes.root}>
          <ButtonBase
            disabled={!verCode || verifyInProgress}
            type="submit"
            classes={{ root: classes.button, disabled: classes.disabled }}
          >
            CONTINUE
          </ButtonBase>
        </Wrapper>
      </form>
    </React.Fragment>
  );
};

export default VerificationCode;
