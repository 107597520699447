import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';
import fontSizes from '@assets/styles/fontSizes';

import Quiz from '@components/IdentityQuiz/Quiz';
import ContentWrapper from '@components/ContentWrapper';
import QuizFailure from '@components/IdentityQuiz/QuizFailure';

import { IQuiz } from '@interfaces/quiz';

interface IProps {}

const Text = styled.p`
  margin: 48px 0 20px 0;
  font-size: ${fontSizes.paragraph1};
`;

const Wrapper = styled.div`
  position: relative;
`;

const IdQuiz: React.FC<IProps> = () => {
  const gs = globalStyles();
  const { userStore, appStore } = useStores();
  const history = useHistory();
  const [quiz, setQuiz] = useState<IQuiz>({ key: '', id: 0, questions: [] });
  const [quizLoaded, setQuizLoaded] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  const navigate = (route: string) => {
    history.push(route);
  };

  useEffect(() => {
    userStore.getQuiz(navigate).then(response => {
      setQuiz(response);
      setQuizLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyQuiz = async (answers: any) => {
    let { isSuccess, redirect, data } = await userStore.verifyQuiz(answers);
    if (isSuccess && redirect) {
      navigate(redirect);
    } else {
      if (!data && redirect) {
        navigate(redirect);
      } else if (data && data.questions) {
        setQuiz(data);
        failStep();
      } else {
        failStep();
      }
    }
  };

  const failStep = () => {
    setStep(2);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return <Quiz verifyQuiz={verifyQuiz} quiz={quiz} />;
      case 2:
        return <QuizFailure setStep={setStep} />;
    }
  };
  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!appStore.config.popupMode}
      hasBackgroundImg={appStore.config.hasBackgroundImg}
    >
      <Wrapper className={gs.wrapper}>
        {!quizLoaded && (
          <div>
            <Text>Please wait while we load your identity questions.</Text>
          </div>
        )}
        {quizLoaded && <div>{renderContent(step)}</div>}
      </Wrapper>
    </ContentWrapper>
  );
};

export default IdQuiz;
