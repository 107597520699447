import React, { useState } from 'react';
import styled from 'styled-components';
import * as api from '@api/';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';

import ContentWrapper from '@components/ContentWrapper';
import Success from '@components/ChangePassword/Success';
import NewPassword from '@components/ChangePassword/NewPassword';
import CurrentPassword from '@components/ChangePassword/CurrentPassword';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
`;

const ChangePassword: React.FC<IProps> = () => {
  const gs = globalStyles();
  const { userStore, notifyStore } = useStores();
  const [step, setStep] = useState<number>(0);
  const [currentPassword, setCurrentPassword] = useState<string>('');

  const checkPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.actionToken(userStore.user!.email, currentPassword);
      nextStep();
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  const changePassword = async (newPass: string) => {
    try {
      await api.changePassword(userStore.user!.email, currentPassword, newPass);
      nextStep();
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CurrentPassword
            password={currentPassword}
            handleSubmit={checkPassword}
            setPassword={setCurrentPassword}
          />
        );
      case 1:
        return <NewPassword handleSubmit={changePassword} />;
      case 2:
        return <Success />;
    }
  };

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <div>{renderContent(step)}</div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default ChangePassword;
