import React from 'react';
import { Card, ColorCard, Badge, cardContent } from './ScoreUpCardsStyles';
import colors from '@assets/styles/colors';

interface IProps {
  classes: {
    boxTitle: string;
  };
  scoreUp: any;
  statsLoaded: boolean;
  scoreUpStats: any;
}

const ScoreUpCards: React.FC<IProps> = ({
  classes,
  scoreUp,
  statsLoaded,
  scoreUpStats
}) => {
  const cards = [
    {
      type: 'payment',
      title: 'PAYMENT ACTIVITY',
      badge: 'HIGH IMPACT',
      badgeColor: 'red',
      borderColor: 'red',
      content: cardContent('payment', statsLoaded, scoreUpStats)
    },
    {
      type: 'utilization',
      title: 'CREDIT UTILIZATION',
      badge: 'MEDIUM IMPACT',
      badgeColor: '#d0d000',
      borderColor: '#d0d000',
      content: cardContent('utilization', statsLoaded, scoreUpStats)
    },
    {
      type: 'debt',
      title: 'DEBT AND BALANCES',
      badge: 'MEDIUM IMPACT',
      badgeColor: '#d0d000',
      borderColor: '#d0d000',
      content: cardContent('debt', statsLoaded, scoreUpStats)
    },
    {
      type: 'activity',
      title: 'CREDIT ACTIVITY',
      badge: 'LOW IMPACT',
      badgeColor: 'green',
      borderColor: colors.lightGreen,
      content: cardContent('activity', statsLoaded, scoreUpStats)
    }
  ];

  return (
    <>
      {cards.map((card, index) => (
        <Card key={index}>
          <div>
            <h2 className={classes.boxTitle}>
              {card.title}&nbsp;
              <Badge color={card.badgeColor}>{card.badge}</Badge>
            </h2>
          </div>
          <div>
            {scoreUp && scoreUp[`${card.type}Pathway`]}
            <ColorCard borderColor={card.borderColor}>{card.content}</ColorCard>
          </div>
        </Card>
      ))}
    </>
  );
};

export default ScoreUpCards;
