import styled from 'styled-components';
import colors from '@assets/styles/colors';

export const Card = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 15px;
  flex-direction: column;
  background-color: white;

  > div {
    display: flex;
    padding: 30px;
    position: relative;
    flex-direction: column;

    &:nth-child(even) {
      background-color: ${colors.lightContainer};
    }

    > div {
      position: relative;
    }
  }

  @media (max-width: 960px) {
    border-radius: 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
