import React from 'react';
import styled from 'styled-components';

import colors from '@assets/styles/colors';

interface IFooterProps {}

const Container = styled.div`
  border-top: 1px solid ${colors.gray};
  padding: 24px 80px;
  text-align: center;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const Footer: React.FC<IFooterProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Footer;
