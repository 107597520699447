import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect, useHistory } from 'react-router';
import { useStores } from '@utils/hooks/useStores';

import Login from '@routes/login';
import Register from '@routes/register';
import NotFound from '@routes/not_found';
import PublicLayout from '@components/PublicLayout';
import PasswordRecovery from '@routes/password_recovery';

import { Route } from '@utils/enums/routes';
import { isTokenValid } from '@utils/token';

interface IProps {}

const Home: React.FC<IProps> = () => {
  const { push } = useHistory();

  const {
    appStore: {
      setKey,
      setView,
      showPage,
      overrideConfigFlags,
      overrideConfigLogo,
      overrideConfigTheme,
      setIdentityDisclaimer
    },
    userStore: { user, getUser }
  } = useStores();
  const [page, setPage] = useState<string>(
    isTokenValid() ? 'Dashboard' : 'Login'
  );

  let token: string | null = null;
  let key: string | null = null;
  let ocf: number | null = null;
  let ocl: string | null = null;
  let oct: string | null = null;
  let view: string | null = null;

  //const token = document.location.search.split('&')[0].split('=')[1];

  if (document.location.search.length > 1) {
    const query = document.location.search.substring(1).split('&');
    if (query.length > 0)
      query.forEach(elem => {
        const prop = elem.split('=');
        if (prop[0] === 'token') token = prop[1];
        if (prop[0] === 'key') key = prop[1];
        if (prop[0] === 'ocf') ocf = +prop[1];
        if (prop[0] === 'ocl') ocl = prop[1];
        if (prop[0] === 'oct') oct = `#${prop[1].slice(prop[1].length - 6)}`; // prop[1];
        if (prop[0] === 'view') view = prop[1];
      });
  }

  useEffect(() => {
    if (key) setKey(key);
    if (ocf) overrideConfigFlags(ocf);
    if (ocl) overrideConfigLogo(ocl);
    if (oct) overrideConfigTheme(oct);

    if (view) setView(view);
    // @ts-ignore
    if (token) window.apiToken = token;
  }, [
    ocf,
    ocl,
    oct,
    key,
    token,
    view,
    setKey,
    setView,
    overrideConfigLogo,
    overrideConfigFlags,
    overrideConfigTheme,
    setIdentityDisclaimer
  ]);

  useEffect(() => {
    if (isTokenValid()) {
      if (!user.id) {
        getUser().then(res => {
          if (!res.idpass) push(Route.IDENTITY);
        });
      }
    }
    if (showPage !== '') {
      setPage(showPage);
    }
  }, [showPage, getUser, user, push, key]);

  const renderPage = () => {
    switch (page) {
      case 'Login':
        return <Login redirected={true} />;
      case 'Register':
        return <Register redirected={true} />;
      case 'PasswordRecovery':
        return <PasswordRecovery redirected={true} />;
      case 'Dashboard':
        return <Redirect to={Route.DASHBOARD_SCORE} />;

      default:
        return <NotFound />;
    }
  };

  return <PublicLayout>{renderPage()}</PublicLayout>;
};

export default observer(Home);
