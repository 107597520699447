import React from 'react';
import { useStores } from '@utils/hooks/useStores';
import { observer } from 'mobx-react';

interface IProps {}

const CheckPermission: React.FC<IProps> = ({ children }) => {
  const { appStore } = useStores();
  if (appStore.isDirectUser) return null;

  return <React.Fragment>{children}</React.Fragment>;
};

export const checkFlag = (confFlags: number, flag: number) => {
  return (confFlags & flag) === flag;
};

export const checkFlags = (confFlags: number, flags: number[]) => {
  return flags.reduce((res, flag) => res && (confFlags & flag) === flag, true);
};

export default observer(CheckPermission);
