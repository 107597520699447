import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@utils/hooks/useStores';
import SwipeableViews from 'react-swipeable-views';

import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@assets/styles/colors';

import Loading from '@components/Loading';
import Container from '@components/Container';
import Monitoring from '@components/Monitoring';
import PageSwipeHeader from '@components/PageSwipeHeader';
import NoMonitoringData from '@components/NoMonitoringData';

import ICreditMonitoring from '@interfaces/creditMonitoring';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    responsive: {
      [theme.breakpoints.down('sm')]: {
        '& > h1': {
          margin: '0 0 10px 0',
          padding: 10,
          backgroundColor: colors.white
        },
        '& > div': {
          margin: '0 15px'
        }
      }
    }
  })
);

const Monitor: React.FC<IProps> = () => {
  const classes = useStyles();
  const { dashboardStore } = useStores();
  const [pages, setPages] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('');
  const [data, setData] = useState<any[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [multipleSources, setMultipleSources] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    dashboardStore.getCreditMonitoring();
  }, [dashboardStore]);

  useEffect(() => {
    if (dashboardStore.creditMonitoring) {
      setPages(
        dashboardStore.creditMonitoring
          // @ts-ignore
          .map((d: ICreditMonitoring) => d.provider)
          .filter(
            (item: ICreditMonitoring, i: number, ar: any) =>
              ar.indexOf(item) === i
          )
      );

      setData(
        dashboardStore.creditMonitoring.filter(
          (item: ICreditMonitoring) => item.provider === pages[activeStep]
        )
      );

      const multi = pages.length > 1;
      if (multi) {
        setMultipleSources(true);
        setTitle('Monitoring');
      } else {
        setMultipleSources(false);
        setTitle('Monitoring - Equifax');
      }
    }
    // TODO: Change pages to useReducer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardStore.creditMonitoring, pages.length, setData, activeStep]);

  useEffect(() => {
    if (dashboardStore.creditMonitoringLoaded) {
      setIsDataLoaded(true);
    }
  }, [dashboardStore.creditMonitoringLoaded]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Container>
      {isDataLoaded && (
        <React.Fragment>
          <PageSwipeHeader
            back={handleBack}
            next={handleNext}
            activeIndex={activeStep}
            pages={pages.length}
            title={pages[activeStep]}
          />
          <SwipeableViews
            disabled={true}
            enableMouseEvents
            index={activeStep}
            onChangeIndex={handleStepChange}
          >
            {pages.map((page, index) => (
              <div key={index} className={classes.responsive}>
                <h1>{title}</h1>
                <div>
                  {data.map((m: ICreditMonitoring) => (
                    <Monitoring data={m} key={m.id} multi={multipleSources} />
                  ))}
                </div>
              </div>
            ))}
          </SwipeableViews>
        </React.Fragment>
      )}
      {!isDataLoaded && <Loading />}
      {data.length < 1 && isDataLoaded && <NoMonitoringData />}
    </Container>
  );
};

export default observer(Monitor);
