import { useFormik } from 'formik';
import { object, string } from 'yup';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useStores } from '@utils/hooks/useStores';
import { Link, useHistory } from 'react-router-dom';

import LogoSrc from '@assets/images/logo.svg';
import { globalStyles } from '@assets/styles/globalStyles';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import { Header } from '@assets/styles/commonComponents';

import Footer from '@components/Footer';
import ContentWrapper from '@components/ContentWrapper';

import { Route } from '@utils/enums/routes';
import { passwordRegex } from '@utils/constants/regex';
import { Events } from '@utils/enums/events';
import { IRegisterUserDTO } from '@interfaces/user';

interface IRegisterProps {
  redirected?: boolean;
}

const useStyles = makeStyles(theme => ({
  textField: {
    '& label': {
      fontSize: 12
    },
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black'
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: fontSizes.h1,
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizes.h2
    }
  },
  customerColor: {
    color: theme.palette.secondary.main
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: 'white',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      // paddingTop: 30
    }
  }
}));

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Container = styled.div`
  overflow-y: visible;
  overflow-x: hidden;
  max-height: 420px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const SignIn = styled.span`
  font-size: ${fontSizes.h5};
`;

const DescriptionContainer = styled.div`
  font-size: ${fontSizes.h5};
  color: ${colors.black};
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const CustomLink = styled(Link)`
  text-decoration: none;
`;

const CustomLink2 = styled(ButtonBase)`
  margin-bottom: 2px;
  text-decoration: none;
`;

const Wrapper = styled.div`
  position: relative;
`;

const signUpSchema = object({
  email: string()
    .max(150, 'Maximum character limit is 150')
    .required('Email is required.')
    .email('Email is invalid'),
  password: string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters longs.')
    .max(20, 'Maximum character limit is 20')
    .matches(
      passwordRegex,
      'Password must contain a capital letter, a special character and a digit.'
    ),
  recoveryQuestion: string().matches(
    /^(?!0$).*/g,
    'Password recovery question is required.'
  ),
  recoveryAnswer: string()
    .max(100, 'Maximum character limit is 100')
    .required('Password recovery answer is required.')
});

const Register: React.FC<IRegisterProps> = ({ redirected }) => {
  const gs = globalStyles();
  const classes = useStyles();
  const history = useHistory();
  const {
    userStore,
    userStore: { userExists },
    appStore,
    notifyStore
  } = useStores();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [userData, setUserData] = useState<IRegisterUserDTO>({
    email: '',
    password: '',
    recoveryQuestion: 0,
    recoveryAnswer: ''
  });

  const config = appStore.getConfig();

  useEffect(() => {
    window.parent.postMessage({ type: Events.REG_STARTED }, '*');
  }, []);

  useEffect(() => {
    setUserData(notifyStore.registrationData);
  }, [notifyStore.registrationData]);

  useEffect(() => {
    if (userExists) {
      setShowEmailError(true);
    }
  }, [userStore, userExists]);

  const formik = useFormik({
    initialValues: { ...userData },
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async ({ email, password, recoveryQuestion, recoveryAnswer }) => {
      setSubmitDisabled(true);
      try {
        await userStore.registerUser(
          {
            email,
            password,
            recoveryQuestion,
            recoveryAnswer
          },
          navigate
        );
        navigate(`/${Route.IDENTITY}`);
      } catch (error) {
        setSubmitDisabled(false);
      }
    }
  });

  const handleClickShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const navigate = (route: string) => {
    history.push(route);
  };

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!appStore.config.popupMode}
      hasBackgroundImg={appStore.config.hasBackgroundImg}
    >
      <React.Fragment>
        <form
          className={classes.form}
          onSubmit={formik.handleSubmit}
          color="primary"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflowY: 'auto'
          }}
        >
          <Wrapper className={gs.wrapper} style={{ paddingBottom: 0 }}>
            <Header>
              <h1 className={classes.title}>Sign Up</h1>
              {appStore.config.hasHeaderLogo && (
                <Logo src={config.logo || LogoSrc} />
              )}
            </Header>
            <Container>
              <TextField
                fullWidth
                type="text"
                name="email"
                label="Email"
                margin="normal"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Enter your email"
                onChange={formik.handleChange}
                classes={{ root: classes.textField }}
                helperText={formik.touched.email ? formik.errors.email : ''}
                error={formik.touched.email && Boolean(formik.errors.email)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                name="password"
                label="Create password"
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
                classes={{ root: classes.textField }}
                placeholder="Enter your desired password"
                type={passwordVisible ? 'text' : 'password'}
                helperText={
                  formik.touched.password ? formik.errors.password : ''
                }
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        disableRipple
                      >
                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                select
                fullWidth
                margin="normal"
                name="recoveryQuestion"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label="Password recovery question"
                classes={{ root: classes.textField }}
                value={formik.values.recoveryQuestion}
                helperText={
                  formik.touched.recoveryQuestion
                    ? formik.errors.recoveryQuestion
                    : ''
                }
                error={
                  formik.touched.recoveryQuestion &&
                  Boolean(formik.errors.recoveryQuestion)
                }
                InputLabelProps={{
                  shrink: true
                }}
              >
                <MenuItem value="0" selected disabled>
                  Select your recovery question
                </MenuItem>
                <MenuItem value="1">What Is your favorite book?</MenuItem>
                <MenuItem value="2">
                  What is the name of the road you grew up on?
                </MenuItem>
                <MenuItem value="3">
                  What is your mother’s maiden name?
                </MenuItem>
                <MenuItem value="4">
                  What was the name of your first/current/favorite pet?
                </MenuItem>
                <MenuItem value="5">
                  What was the first company that you worked for?
                </MenuItem>
                <MenuItem value="6">Where did you meet your spouse?</MenuItem>
                <MenuItem value="7">
                  Where did you go to high school/college?
                </MenuItem>
                <MenuItem value="8">What is your favorite food?</MenuItem>
                <MenuItem value="9">What city were you born in?</MenuItem>
                <MenuItem value="10">
                  Where is your favorite place to vacation?
                </MenuItem>
              </TextField>
              <TextField
                fullWidth
                margin="normal"
                name="recoveryAnswer"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label="Password recovery answer"
                value={formik.values.recoveryAnswer}
                classes={{ root: classes.textField }}
                placeholder="Enter your password recovery answer"
                helperText={
                  formik.touched.recoveryAnswer
                    ? formik.errors.recoveryAnswer
                    : ''
                }
                error={
                  formik.touched.recoveryAnswer &&
                  Boolean(formik.errors.recoveryAnswer)
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
              <DescriptionContainer>
                <span>
                  By clicking "Register” and creating an account you accept
                  StitchCredit’s{' '}
                  <CustomLink
                    className={classes.customerColor}
                    to={`/${Route.TERMS_OF_USE}`}
                  >
                    Terms of Use
                  </CustomLink>{' '}
                  and{' '}
                  <CustomLink
                    className={classes.customerColor}
                    to={`/${Route.PRIVACY_POLICY}`}
                  >
                    Privacy Policy
                  </CustomLink>
                  . StitchCredit does not maintain critical personal data, much
                  less sell or otherwise disclose your personal information to
                  anyone else. You may opt-out of email correspondence, except
                  confirmation Emails, which often contain important information
                  about your account.
                </span>
              </DescriptionContainer>
            </Container>
            <Footer>
              <ButtonBase
                disabled={submitDisabled}
                classes={{ root: gs.button, disabled: gs.disabled }}
                type="submit"
              >
                Register
              </ButtonBase>
              {showEmailError && (
                <p>
                  This email address is already registered, try to login
                  instead.
                </p>
              )}
              <SignIn>
                Already have an account?{' '}
                <CustomLink2
                  className={classes.customerColor}
                  onClick={() => {
                    redirected
                      ? appStore.setPage('Login')
                      : history.push(Route.LOGIN);
                  }}
                >
                  Sign in
                </CustomLink2>
              </SignIn>
            </Footer>
          </Wrapper>
        </form>
      </React.Fragment>
    </ContentWrapper>
  );
};

Register.defaultProps = {
  redirected: false
};

export default observer(Register);
