import { action, observable, decorate } from 'mobx';
import * as api from '@api/';
import { AppStore, NotifyStore } from '.';
import { Route } from '@utils/enums/routes';
import * as Sentry from '@sentry/react';

import {
  IRegisterUserDTO,
  IUserIdentityReqDTO,
  IIdentityInfo
} from '@interfaces/user';
import { IQuizVerifyReqDTO, IQuiz } from '@interfaces/quiz';
import { IVerifyCodeReqDTO, ISendNewCodeReqDTO } from '@interfaces/mobile';
import { Events } from '@utils/enums/events';
import { errorHandler } from '@utils/helpers/errorHandler';

export class UserStore {
  constructor(private appStore: AppStore, private notify: NotifyStore) {}

  public userExists: boolean = false;
  public justEnrolled: boolean = false;
  public user: any = {};
  public userIdentity: IIdentityInfo = {};
  public isIdentityCompleted: boolean = false;
  public verifyInProgress: boolean = false;
  public verifyLinkInProgress: boolean = false;
  public sendingNewCodeInProgress: boolean = false;
  public sendingNewLinkInProgress: boolean = false;
  public mobileVerifyTryCount: number = 0;

  public setUserIdentity = (userIdentity: IIdentityInfo) => {
    this.userIdentity = userIdentity;
  };

  public setIsVerifyInProgres = (verifyInProgress: boolean) => {
    this.verifyInProgress = verifyInProgress;
  };

  public setIsVerifyLinkInProgres = (verifyLinkInProgress: boolean) => {
    this.verifyLinkInProgress = verifyLinkInProgress;
  };

  public setIsSendingNewCodeInProgress = (
    sendingNewCodeInProgress: boolean
  ) => {
    this.sendingNewCodeInProgress = sendingNewCodeInProgress;
  };

  public setIsSendingNewLinkInProgress = (
    sendingNewLinkInProgress: boolean
  ) => {
    this.sendingNewLinkInProgress = sendingNewLinkInProgress;
  };

  public setIsIdentityCompleted = (isIdentityCompleted: boolean) => {
    this.isIdentityCompleted = isIdentityCompleted;
  };

  public setJustEnrolled = (justEnrolled: boolean) => {
    this.justEnrolled = justEnrolled;
  };

  public userLogin = async (username: string, password: string) => {
    try {
      const response = await api.userLogin(username, password);
      this.appStore.setAccessToken(response.data.token);
      this.appStore.setRefreshToken(response.data.refresh);

      if (response.data.idpass) {
        this.setIsIdentityCompleted(true);
      } else {
        this.setIsIdentityCompleted(false);
      }

      if (response.data.just_enrolled) {
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
        this.setJustEnrolled(true);
      }

      this.user = response.data;
      Sentry.setUser(this.user);
      window.parent.postMessage({ type: Events.LOGIN_SUCCESSFUL }, '*');
    } catch (error) {
      window.parent.postMessage({ type: Events.LOGIN_FAILED }, '*');
      this.notify.error(error.message);
    }
  };

  public preauthLogin = async (token: string) => {
    try {
      const response = await api.preauthLogin(token);
      this.appStore.setAccessToken(response.data.token);
      this.appStore.setRefreshToken(response.data.refresh);

      if (response.data.idpass) {
        this.setIsIdentityCompleted(true);
      } else {
        this.setIsIdentityCompleted(false);
      }

      this.user = response.data;
      Sentry.setUser(this.user);
      return response.data;
    } catch (error) {
      this.notify.error(error.message);
    }
  };

  public registerUser = async (data: IRegisterUserDTO, navigate: Function) => {
    try {
      const response = await api.registerUser(data);
      this.appStore.setAccessToken(response.data.token);
      this.appStore.setRefreshToken(response.data.refresh);
      // window.postMessage({ type: Events.IDENTITY_STARTED }, '*');
      // eslint-disable-next-line no-console
      //console.log('Register - navigating to Identity');
      //navigate(`/${Route.IDENTITY}`);
      this.userExists = false;
    } catch (error) {
      this.userExists = true;
      this.notify.error(
        'We were unable to create an account for you.  The email you used is most likely already registered.'
      );
      throw error;
    }
  };

  public identityUser = async (
    data: IUserIdentityReqDTO
  ): Promise<{ isSuccess: boolean; idpass: boolean; redirect?: string }> => {
    try {
      const response = await api.identityUser(data);
      this.setUserIdentity(response.data);

      if (response.data.just_enrolled) {
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
        this.setJustEnrolled(true);
        return {
          isSuccess: true,
          idpass: true,
          redirect: `/${Route.ID_SUCCESS}`
        };
      }

      return { isSuccess: true, idpass: response.data.idpass };
      // return { isSuccess: true };
    } catch (error) {
      //let code = error?.response?.data?.codes[0];
      let status = error?.response?.status;

      if (status > 499 && status < 600) {
        this.notify.error('Service Failure \n Please try again later.');
        return {
          isSuccess: false,
          idpass: false,
          redirect: Route.SERVICE_FAILURE
        };
      }
      window.parent.postMessage({ type: Events.IDENTITY_FAILED }, '*');
      this.notify.error(
        'Identity Failed.  User is most likely already registered.'
      );
      return { isSuccess: false, idpass: false };
    }
  };

  public getUser = async () => {
    if (this.user.id) return null;
    try {
      const response = await api.getUser();
      this.appStore.setUser(response.data);
      this.user = response.data;
      Sentry.setUser(this.user);
      return response.data;
    } catch (error) {
      // this.notify.error(error);
    }
  };

  public updateUserNotifications = async (notifications: {}) => {
    try {
      const response = await api.updateAlertNotifications(notifications);
      this.appStore.setUser(response.data);
      this.user = response.data;
    } catch (error) {
      // this.notify.error(error.message);
    }
  };

  public getQuiz = async (navigate: Function) => {
    try {
      const response = await api.getQuiz();
      return response.data;
    } catch (error) {
      errorHandler(error);

      const code =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.codes &&
        error.response.data.codes[0];

      if (code === 'SC301') {
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
        this.setJustEnrolled(true);
        navigate(Route.ID_SUCCESS);
        return;
      }

      let status;
      if (error.response && error.response.status) {
        status = error.response.status;
      }
      if (status === 428) {
        this.notify.error(
          "Identification Failure \n We need your personal registration information, let's try again."
        );
        navigate(`/${Route.IDENTITY}`);
      } else if (status === 409) {
        window.parent.postMessage({ type: Events.IDENTITY_FAILED }, '*');
        this.notify.error('Error Identifying User - hence no questions');
        return null;
      } else {
        window.parent.postMessage({ type: Events.IDENTITY_FAILED }, '*');
        navigate(`/${Route.EID_FAILED}`);
      }
    }
  };

  public verifyQuiz = async (
    data: IQuizVerifyReqDTO
  ): Promise<{ isSuccess: boolean; redirect?: string; data?: IQuiz }> => {
    try {
      this.setIsVerifyInProgres(true);
      const response = await api.verifyQuiz(data);
      if (response.data.idpass) {
        this.setIsIdentityCompleted(true);
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
      }
      return { isSuccess: true, redirect: `/${Route.ID_SUCCESS}` };
    } catch (error) {
      errorHandler(error);

      let code =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.codes &&
        error.response.data.codes[0];

      let status;
      if (error.response && error.response.status) {
        status = error.response.status;
      }

      if (code === 'SC313') {
        return {
          isSuccess: false,
          redirect: Route.ID_SUCCESS_EID_FAILED
        };
      }

      if (error.response && error.response.data) {
        if (status === 409) {
          this.notify.error(
            'We were unable to accurately verify enough of your responses to continue.  A new set of questions has been provided, please try again.'
          );
          return { isSuccess: false, data: error.response.data };
        } else {
          return {
            isSuccess: false,
            redirect: Route.EID_FAILED
          };
        }
      }

      return { isSuccess: false, redirect: Route.EID_FAILED };
    } finally {
      this.setIsVerifyInProgres(false);
    }
  };

  public verifyPhone = async (navigate: Function) => {
    this.notify.info('Verifying Phone...');
    try {
      const response = await api.verifyPhone();
      this.notify.success('Phone Verify Successful');
      return response.data;
    } catch (error) {
      errorHandler(error);

      const code =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.codes &&
        error.response.data.codes[0];

      if (code === 'SC301') {
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
        this.setJustEnrolled(true);
        navigate(Route.ID_SUCCESS);
        return;
      }

      this.notify.error(
        "We were unable to verify your mobile number details.  Let's try another form of identification."
      );
      navigate(`/${Route.EID}`);
    }
  };

  public verifyDITIdentity = async (navigate: Function) => {
    try {
      const response = await api.verifyDITIdentity();
      this.notify.success('DIT Verification Successful');
      return response.data;
    } catch (error) {
      errorHandler(error);

      if (error?.response?.status === 503) {
        navigate(Route.SERVICE_FAILURE);
        return;
      }

      if (error?.response?.data?.codes[0] === 'SC301') {
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
        this.setJustEnrolled(true);
        navigate(Route.ID_SUCCESS);
        return;
      }

      window.parent.postMessage({ type: Events.IDENTITY_FAILED }, '*');
      this.notify.error('Identity Failed.');
      navigate(Route.EID_FAILED);
      return;
    }
  };

  public sendCode = async (
    token: string,
    navigate: Function
  ): Promise<{ key: string; passcode?: string } | null> => {
    try {
      const response = await api.sendCode(token);
      this.notify.success('Mobile Code Sent');
      return response.data;
    } catch (error) {
      errorHandler(error);
      this.notify.error(
        "SMS Code Failure \n We were unable to send you a code.  Let's try another form of identification."
      );
      navigate(`/${Route.EID}`);
      return null;
    }
  };

  public sendSMFALink = async (
    token: string,
    navigate: Function
  ): Promise<{
    linkUrl: string;
    smsMessage: string;
    token: string;
    expires: string;
  } | null> => {
    try {
      this.setIsSendingNewLinkInProgress(true);
      const response = await api.sendSMFALink(token);
      this.setIsSendingNewLinkInProgress(false);
      this.notify.success('Mobile SMFA Link Sent');
      return response.data;
    } catch (error) {
      this.setIsSendingNewLinkInProgress(false);
      errorHandler(error);
      //let code = error?.response?.data?.codes[0];
      let status = error?.response?.status;
      if (status === 503) {
        this.notify.error(
          'SMFA Service is currently unavailable.  Please wait a few hours and try again.'
        );
        navigate(Route.SERVICE_FAILURE);
      } else if (status === 425) {
        this.notify.error(
          'Attempt to send Secure Mobile Link too early.  Wait at least 30-seconds and try again.'
        );
      } else if (error?.response?.data?.codes[0] === 'SC323') {
        this.notify.error(
          'SMFA Service may be unavailable.  Please wait a few hours and try again.'
        );
        navigate(Route.SERVICE_FAILURE);
      } else {
        this.notify.error(
          'Send SMFA Link Failure\n We were unable to send you a secure link.'
        );
        navigate(`/${Route.EID_FAILED}`);
      }
      return null;
    }
  };

  public verifySMFA = async (
    token: string
  ): Promise<{
    isSuccess: boolean;
    redirect?: string;
    allowRetry?: boolean;
  }> => {
    try {
      this.setIsVerifyInProgres(true);
      const response = await api.verifySMFA(token);

      this.notify.success('Mobile Secure Link Verified!');
      if (response.data.idpass) {
        this.setIsIdentityCompleted(true);
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
      }
      return { isSuccess: true, redirect: `/${Route.ID_SUCCESS}` };
    } catch (error) {
      errorHandler(error);

      let code = error?.response?.data?.codes[0];
      let status = error?.response?.status;

      if (status === 428) {
        this.notify.error('Secure Mobile Verification Incomplete.');
        return { isSuccess: false, allowRetry: true };
      } else if (status === 409) {
        this.notify.error('Secure Mobile Link Verification Failure');
        return { isSuccess: false, allowRetry: true };
      } else if (status === 417) {
        this.notify.error(
          'Enrollment Failure \n We were unable to enroll your account for all features.  We will try again, but until then some features may not fully function.'
        );
        return {
          isSuccess: false,
          redirect: `/${this.appStore.getRedirectView()}`
        };
      } else if (code === 'SC313') {
        return {
          isSuccess: false,
          redirect: Route.ID_SUCCESS_EID_FAILED
        };
      } else if (code === 'SC306') {
        this.notify.error('Enrollment Failure: Thin File');
        return {
          isSuccess: false,
          redirect: Route.THIN_FILE
        };
      } else if (code === 'SC324') {
        this.notify.error('Identity Failure');
        return {
          isSuccess: false,
          redirect: Route.EID_FAILED
        };
      } else {
        this.notify.error(
          'Mobile Secure Link Verification Failure \n We were unable to verify your status.'
        );
        return {
          isSuccess: false,
          redirect: Route.SERVICE_FAILURE
        };
      }
    } finally {
      this.setIsVerifyInProgres(false);
    }
  };

  public sendNewCode = async (data: ISendNewCodeReqDTO) => {
    try {
      this.setIsSendingNewCodeInProgress(true);
      const response = await api.sendNewCode(data);
      this.notify.success('New Mobile Code Sent');
      this.setIsSendingNewCodeInProgress(false);
      return response.data;
    } catch (error) {
      this.setIsSendingNewCodeInProgress(false);
      this.notify.error(
        "SMS Code Failure \n We were unable to send you a new code.  Let's try another form of identification."
      );
      return null;
    }
  };

  public verifyCode = async (
    data: IVerifyCodeReqDTO
  ): Promise<{
    isSuccess: boolean;
    redirect?: string;
    allowRetry?: boolean;
  }> => {
    try {
      this.setIsVerifyInProgres(true);
      this.mobileVerifyTryCount++;
      const response = await api.verifyCode(data);

      this.notify.success('Mobile Code Verified/Enrollment Successful');
      if (response.data.idpass) {
        this.setIsIdentityCompleted(true);
        window.parent.postMessage({ type: Events.USER_ENROLLED }, '*');
      }
      return { isSuccess: true, redirect: `/${Route.ID_SUCCESS}` };
    } catch (error) {
      errorHandler(error);

      let code =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.codes &&
        error.response.data.codes[0];

      if (code === 'SC313') {
        return {
          isSuccess: false,
          redirect: Route.ID_SUCCESS_EID_FAILED
        };
      }

      let status;
      if (error.response && error.response.status) {
        status = error.response.status;
      }
      if (status === 428) {
        this.notify.error(
          "Identification Failure \n We need your personal registration information, let's try again."
        );
        return { isSuccess: false, redirect: `/${Route.IDENTITY}` };
      } else if (status === 409) {
        this.notify.error(
          'SMS Code Failure \n We were unable to verify your code, please try again.'
        );

        if (this.mobileVerifyTryCount < 3) {
          return { isSuccess: false, allowRetry: true };
        } else {
          return { isSuccess: false };
        }
      } else if (status === 417) {
        this.notify.error(
          'Enrollment Failure \n We were unable to enroll your account for all features.  We will try again, but until then some features may not fully function.'
        );

        return {
          isSuccess: false,
          redirect: `/${this.appStore.getRedirectView()}`
        };
      } else {
        this.notify.error(
          "SMS Code Failure \n We were unable to verify your code.  Let's try another form of identification."
        );
        return { isSuccess: false };
      }
    } finally {
      this.setIsVerifyInProgres(false);
    }
  };

  public verifyEnrollment = async () => {
    try {
      const response = await api.verifyEnrollment();
      return response.data;
    } catch (error) {
      this.notify.error(error);
    }
  };

  public recoverPassword = async (email: string) => {
    try {
      const response = await api.recoverPassword(email);
      this.notify.success('Phone verified!');
      return response;
    } catch (error) {
      this.notify.error(error);
    }
  };

  public validateResetToken = async (token: string) => {
    try {
      const response = await api.validateResetToken(token);
      this.notify.success('Phone verified!');
      return response;
    } catch (error) {
      this.notify.error(error);
    }
  };
}

decorate(UserStore, {
  user: observable,
  userIdentity: observable,
  isIdentityCompleted: observable,
  setUserIdentity: action,
  setIsIdentityCompleted: action,
  userLogin: action,
  preauthLogin: action,
  registerUser: action,
  identityUser: action,
  getUser: action,
  getQuiz: action,
  verifyQuiz: action,
  verifyPhone: action,
  sendCode: action,
  sendNewCode: action,
  verifyCode: action,
  verifyEnrollment: action,
  recoverPassword: action,
  validateResetToken: action,
  userExists: observable,
  sendingNewLinkInProgress: observable,
  sendingNewCodeInProgress: observable,
  verifyInProgress: observable,
  verifyLinkInProgress: observable
});
