import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { globalStyles } from '@assets/styles/globalStyles';
import Button from '@material-ui/core/Button';

import ContentWrapper from '@components/ContentWrapper';
import PublicLayout from '@components/PublicLayout';

import Question from '@assets/icons/question';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.div`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const NotFound: React.FC<IProps> = () => {
  const gs = globalStyles();
  const { goBack } = useHistory();

  return (
    <PublicLayout>
      <ContentWrapper>
        <Wrapper className={gs.wrapper}>
          <Logo>
            <Question />
          </Logo>
          <h2>Not Found</h2>
          <p>Requested content not found.</p>
          <Button
            color="secondary"
            variant="contained"
            className={gs.button2}
            onClick={() => {
              goBack();
            }}
          >
            BACK
          </Button>
        </Wrapper>
      </ContentWrapper>
    </PublicLayout>
  );
};

export default NotFound;
