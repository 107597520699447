import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@utils/hooks/useStores';
import SwipeableViews from 'react-swipeable-views';
import { useIntl } from 'react-intl';

import Loading from '@components/Loading';
import ScorePage from '@components/Score';
import ScoreUpPage from '@components/ScoreUp';
import ReportPage from '@components/Report';
import Container from '@components/Container';
import Monitoring from '@components/Monitoring';
import PageSwipeHeader from '@components/PageSwipeHeader';

import { Card } from '@assets/styles/commonComponents';

import ICreditMonitoring from '@interfaces/creditMonitoring';
import ILatestCreditScore from '@interfaces/latestCreditScore';
import ICreditReportSummary from '@interfaces/creditReportSummary';

import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';
import { checkFlag } from '@utils/helpers/checkPermission';
import Flags from '@utils/constants/flags';
import NoMonitoringData from '@components/NoMonitoringData';
import PoweredBy from '@assets/images/powered-by.svg';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    poweredBy: {
      width: '100%',
      padding: '11.5px 0',
      textAlign: 'center',
      background: 'white'
    },
    responsive: {
      [theme.breakpoints.down('sm')]: {
        '& > h1': {
          margin: '0 0 10px 0',
          padding: 10,
          backgroundColor: colors.white
        }
      }
    },
    wrapper: {
      padding: '30px 50px',
      [theme.breakpoints.down('md')]: {
        padding: '15px 25px 15px 15px'
      }
    },
    list: {
      marginTop: 35,
      marginBottom: 35,
      padding: '30px 15px 15px 15px',
      borderTop: `1px solid ${colors.lightInputText}`,
      borderBottom: `1px solid ${colors.lightInputText}`,
      color: colors.violet,
      '& > li': {
        marginBottom: 20
      },
      '& > li span': {
        color: colors.lightBlack,
        fontWeight: 300
      }
    },
    bold: {
      fontWeight: 'bold'
    },
    section: {
      margin: '7.5px 0'
    },
    loading: {
      top: '50vh',
      position: 'absolute',
      left: 'calc(50% - 62px)'
    },
    monitoring: {
      fontSize: fontSizes.h1,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        padding: 15
      }
    }
  })
);

const AllInOne: React.FC<IProps> = () => {
  const classes = useStyles();

  const {
    dashboardStore,
    appStore,
    userStore: { getUser }
  } = useStores();
  const [pages, setPages] = useState<string[]>([]);
  const [scoreData, setScoreData] = useState<any[]>([]);
  const [reportData, setReportData] = useState<any[]>([]);
  const [monitoringData, setMonitoringData] = useState<any[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [multipleSources, setMultipleSources] = useState<boolean>(false);
  const { formatMessage: t } = useIntl();

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsDataLoaded(false);

        let dArr = [];
        if (checkFlag(appStore.getConfig().flags, Flags.SCORE))
          dArr.push(dashboardStore.getLatestScore());
        if (checkFlag(appStore.getConfig().flags, Flags.SCORE_HISTORY))
          dArr.push(dashboardStore.getScoreHistory());
        if (checkFlag(appStore.getConfig().flags, Flags.REPORT))
          dArr.push(dashboardStore.getReport());
        if (checkFlag(appStore.getConfig().flags, Flags.ALERTS))
          dArr.push(dashboardStore.getCreditMonitoring());
        if (checkFlag(appStore.getConfig().flags, Flags.SCORE_UP))
          dArr.push(dashboardStore.getScoreUp());

        await Promise.all(dArr);
      } catch (error) {
        // todo handle error if any
      } finally {
        setIsDataLoaded(true);
      }
    };
    fetchData();
  }, [dashboardStore, appStore]);

  useEffect(() => {
    if (
      dashboardStore.latestScore &&
      dashboardStore.latestScore.providerViews
    ) {
      setPages(
        dashboardStore.latestScore.providerViews
          // @ts-ignore
          .map((d: ILatestCreditScore) => d.provider)
          .filter(
            (item: ILatestCreditScore, i: number, ar: any) =>
              ar.indexOf(item) === i
          )
      );

      setScoreData(
        dashboardStore.latestScore.providerViews.filter(
          (item: ILatestCreditScore) =>
            // @ts-ignore
            item.provider === pages[activeStep]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardStore.latestScore, setScoreData, activeStep]);

  useEffect(() => {
    if (dashboardStore.reportList) {
      setPages(
        dashboardStore.reportList
          .map((d: ICreditReportSummary) => d.provider)
          .filter(
            (item: ICreditReportSummary, i: number, ar: any) =>
              ar.indexOf(item) === i
          )
      );

      setReportData(
        dashboardStore.reportList.filter(
          (item: ICreditReportSummary) => item.provider === pages[activeStep]
        )
      );

      const multi = pages.length > 1;
      if (multi) {
        setMultipleSources(true);
      } else {
        setMultipleSources(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardStore.reportList, activeStep]);

  useEffect(() => {
    if (dashboardStore.creditMonitoring) {
      // setPages(
      //   dashboardStore.creditMonitoring
      //     // @ts-ignore
      //     .map((d: ICreditMonitoring) => d.provider)
      //     .filter(
      //       (item: ICreditMonitoring, i: number, ar: any) =>
      //         ar.indexOf(item) === i
      //     )
      // );

      setMonitoringData(
        dashboardStore.creditMonitoring.filter(
          (item: ICreditMonitoring) => item.provider === pages[activeStep]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardStore.creditMonitoring, setMonitoringData, activeStep]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Container>
      {isDataLoaded && (
        <React.Fragment>
          {checkFlag(appStore.getConfig().flags, Flags.SCORE) && (
            <div className={classes.section}>
              <PageSwipeHeader
                back={handleBack}
                next={handleNext}
                activeIndex={activeStep}
                pages={pages.length}
                title={pages[activeStep]}
              />
              <SwipeableViews
                disabled={true}
                enableMouseEvents
                index={activeStep}
                onChangeIndex={handleStepChange}
              >
                {pages.map((page, index) => (
                  <React.Fragment key={index}>
                    <h1 className={classes.monitoring}>
                      {t({ id: `providers.${pages[activeStep]}` })}
                    </h1>
                    {scoreData.map((d: any, i: number) => (
                      <ScorePage
                        key={i}
                        data={d}
                        isAIO={true}
                        showReports={false}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </SwipeableViews>
            </div>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.SCORE_UP) && (
            <div className={classes.section}>
              <PageSwipeHeader
                back={handleBack}
                next={handleNext}
                activeIndex={activeStep}
                pages={pages.length}
                title={pages[activeStep]}
              />
              <SwipeableViews
                disabled={true}
                enableMouseEvents
                index={activeStep}
                onChangeIndex={handleStepChange}
              >
                {pages.map((page, index) => (
                  <React.Fragment key={index}>
                    <h1 className={classes.monitoring}>
                      {t({ id: 'common.score_up' })}
                    </h1>
                    {scoreData.map((d: any, i: number) => (
                      <ScoreUpPage key={i} isAIO={true} />
                    ))}
                  </React.Fragment>
                ))}
              </SwipeableViews>
            </div>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.REPORT) && (
            <div className={classes.section}>
              <SwipeableViews
                disabled={true}
                enableMouseEvents
                index={activeStep}
                onChangeIndex={handleStepChange}
              >
                {pages.map((page, index) => (
                  <React.Fragment key={index}>
                    <h1 className={classes.monitoring}>
                      {t({
                        id: `providers.${pages[activeStep]}`
                      })}
                      : Report Summary
                    </h1>
                    {reportData.map((d: any, i: number) => (
                      <ReportPage
                        key={i}
                        data={d}
                        hideTitle={true}
                        multi={multipleSources}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </SwipeableViews>
            </div>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.ALERTS) && (
            <div className={classes.section}>
              <SwipeableViews
                disabled={true}
                enableMouseEvents
                index={activeStep}
                onChangeIndex={handleStepChange}
              >
                {pages.map((page, index) => (
                  <div key={index}>
                    <h1 className={classes.monitoring}>Monitoring</h1>
                    <div className={classes.responsive}>
                      {!monitoringData.length && (
                        <Card>
                          <NoMonitoringData />
                        </Card>
                      )}
                      {monitoringData.map((m: ICreditMonitoring) => (
                        <Monitoring
                          data={m}
                          key={m.id}
                          multi={multipleSources}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </SwipeableViews>
            </div>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.RESTORE) && (
            <div className={classes.section}>
              <h1 className={classes.monitoring}>Equifax® ID Restoration</h1>
              <Card>
                <div>
                  <div className={classes.wrapper}>
                    <div>
                      <h3 style={{ fontSize: fontSizes.h3, fontWeight: 300 }}>
                        Has your identity been compromised?
                      </h3>
                      <ul
                        className={classes.list}
                        style={{ fontSize: fontSizes.paragraph1 }}
                      >
                        <li>
                          <span>
                            A specialist will help you work through the process.
                          </span>
                        </li>
                        <li>
                          <span>
                            Once your issues are resolved, we continue to
                            monitor the situation for three months.
                          </span>
                        </li>
                        <li>
                          <span>
                            Call today so that we may help you verify and begin
                            resolving any discrepancies.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p
                        style={{ fontSize: fontSizes.paragraph1 }}
                        className={classes.bold}
                      >
                        Call 1-877-368-4940 today to get started <br />
                        {/*or <Link href="#">click here</Link> to learn more.*/}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
          <div className={classes.poweredBy}>
            <img src={PoweredBy} alt="" />
          </div>
        </React.Fragment>
      )}
      {!isDataLoaded && (
        <div className={classes.loading}>
          <Loading />
        </div>
      )}
    </Container>
  );
};

export default observer(AllInOne);
