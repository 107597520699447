import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';

interface IStyles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface IProps extends SwitchProps {
  classes: IStyles;
}

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 18,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      '& + $track': {
        opacity: 1
      },
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.main}`
        }
      },
      '&$focusVisible $thumb': {
        color: theme.palette.secondary.main,
        border: '6px solid #fff'
      }
    },
    thumb: {
      width: 16,
      height: 16
    },
    track: {
      height: 16,
      opacity: 1,
      marginBottom: -10,
      borderRadius: 18 / 2,
      backgroundColor: '#C7CCD4',
      border: `1px solid #C7CCD4`,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {}
  })
)(({ classes, ...props }: IProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export default CustomSwitch;
