import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';

import { NavLink } from './NavLink';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CheckPermission from '@utils/helpers/checkPermission';

import { Route } from '@utils/enums/routes';
import { Button } from '@material-ui/core';

interface IDrawerNavigationProps {
  toggleDrawer: Function;
  drawerOpen: boolean;
  signOut: Function;
}

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100%',
    height: '100%',
    flexShrink: 0,
    backgroundColor: colors.lightBackground
  },
  appbarBlank: {
    height: 90
  },
  drawerItem: {
    paddingTop: 16,
    paddingBottom: 16,
    fontWeight: 'bold',
    fontSize: fontSizes.h3,
    justifyContent: 'center'
  },
  bottomLink: {
    textTransform: 'none',
    color: `${theme.palette.secondary.main} !important`,
    marginRight: '0 !important'
  }
}));

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px;
`;

const DrawerLinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Bottom = styled.div`
  display: flex;
  margin: 0px -8px;
  padding-top: 12px;
  align-items: center;
  justify-content: space-between;
`;

const DrawerNavigation: React.FC<IDrawerNavigationProps> = ({
  toggleDrawer,
  drawerOpen,
  signOut
}) => {
  const classes = useStyles();
  const { formatMessage: t } = useIntl();

  const logout = () => {
    signOut();
  };

  return (
    <Drawer
      anchor={'top'}
      className={classes.drawer}
      classes={{
        paper: classes.drawer
      }}
      open={drawerOpen}
      onClose={toggleDrawer(false)}
    >
      <Toolbar className={classes.appbarBlank} />
      <DrawerContainer
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <DrawerLinksContainer>
          <List>
            <CheckPermission>
              <ListItem
                button
                component={NavLink}
                className={classes.drawerItem}
                to={`/${Route.SETTINGS}`}
              >
                {t({ id: 'common.settings' })}
              </ListItem>
            </CheckPermission>
            <ListItem
              button
              className={classes.drawerItem}
              component={NavLink}
              to={`/${Route.ABOUT}`}
            >
              {t({ id: 'common.about' })}
            </ListItem>
            <CheckPermission>
              <ListItem button className={classes.drawerItem} onClick={logout}>
                {t({ id: 'common.sign_out' })}
              </ListItem>
            </CheckPermission>
          </List>
        </DrawerLinksContainer>
        <Divider />
        <Bottom>
          <Button
            className={classes.bottomLink}
            component={NavLink}
            to={`/${Route.TERMS_OF_USE}`}
          >
            Terms of Use
          </Button>
          <Button
            className={classes.bottomLink}
            component={NavLink}
            to={`/${Route.PRIVACY_POLICY}`}
          >
            Privacy Policy
          </Button>
        </Bottom>
      </DrawerContainer>
    </Drawer>
  );
};

export default DrawerNavigation;
