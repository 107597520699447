import React from 'react';
import * as Sentry from '@sentry/browser';
import SystemError from '@routes/sys_error';

interface IErrorBoundaryProps {}
interface IErrorBoundaryState {
  hasError: boolean;
  eventId: string;
  errorInfo: any;
}

export default class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, eventId: '', errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    const { eventId } = this.state;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <SystemError eventId={eventId} />
        </div>
      );
    }

    return this.props.children;
  }
}
