import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M81.2128 14.7871L14.7872 81.2127"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48 95C73.9574 95 95 73.9574 95 48C95 22.0426 73.9574 1 48 1C22.0426 1 1 22.0426 1 48C1 73.9574 22.0426 95 48 95Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
