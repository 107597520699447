import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import RadialChart from '@components/Scores/RadialChart';

import Utils from '@utils/efxutil';

import colors from '@assets/styles/colors';
import { Popover } from '@material-ui/core';
import fontSizes from '@assets/styles/fontSizes';
import InfoIcon from '@material-ui/icons/Info';
import { checkFlag } from '@utils/helpers/checkPermission';
import Flags from '@utils/constants/flags';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flex: 1,
      height: '100%',
      display: 'flex'
    },
    titles: {
      fontWeight: 600,
      fontSize: fontSizes.paragraph2
    },
    dates: {
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    description: {
      paddingRight: 5,
      fontSize: fontSizes.paragraph2
    },
    score: {
      paddingLeft: 5,
      fontWeight: 600,
      textAlign: 'right',
      fontSize: fontSizes.paragraph2
    },
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > div': {
          margin: 0,
          marginBottom: 10
        }
      }
    },
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.lightInputText
    },
    popover: {
      pointerEvents: 'none'
    },
    paper: {
      padding: '10px 15px'
    }
  })
);

interface IProps {
  activeStep: number;
}

const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
`;

const Card = styled.div`
  // flex: 1;
  background: white;
  border-radius: 4px;
`;

const ScoreWrapper = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;

    > div:first-of-type {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
  
    > div.gauge {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 600px) {
        -moz-transform: scale(0.85); /* Firefox */
        -o-transform: scale(0.85); /* Opera */
        -webkit-transform: scale(0.85); /* Safari And Chrome */
        transform: scale(0.85); /* Standard Property */
      }
    }
  
    > div.bottom,
    > div.bottom-responsive {
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
  
        &:first-of-type {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid ${colors.gray};
        }
      }
    }
      
    .bottom-responsive {
      display: none;
      div {
        font-size: ${fontSizes.paragraph2} !important;
        &:not(:last-of-type) {
          margin-bottom: 10px !important;
          padding-bottom: 10px !important;
          border-bottom: 1px solid ${colors.gray} !important;
        }
      }
    }
    @media (max-width: 600px) {
      .bottom-responsive {
        display: block;
      }
      .bottom {
        display: none;
      }
    }
  }
`;

const Score: React.FC<IProps> = ({ activeStep }) => {
  const classes = useStyles();
  const { appStore, dashboardStore } = useStores();
  const [provider, setProvider] = useState<any>(null);
  const [sc, setSc] = useState<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (
      !dashboardStore.latestScore ||
      !dashboardStore.latestScore.providerViews
    ) {
      dashboardStore.getLatestScore();
      dashboardStore.getReportList();
    }
  }, [dashboardStore]);

  useEffect(() => {
    if (
      dashboardStore.latestScore &&
      dashboardStore.latestScore.providerViews
    ) {
      setProvider(
        dashboardStore.latestScore.providerViews[activeStep].provider
      );
      setSc(Utils._processScores(dashboardStore.latestScore));
    }
  }, [dashboardStore.latestScore, dashboardStore.scoreHistory, activeStep]);

  return (
    <div className={classes.root}>
      <Wrapper className={classes.wrapper}>
        {!sc && (
          <div className={classes.loading}>
            <p>Loading your data</p>
          </div>
        )}
        {sc && (
          <ScoreWrapper>
            <div className="gauge">
              <RadialChart data={sc[provider]} />
              <p
                style={{
                  position: 'absolute',
                  marginLeft: '100px',
                  marginTop: '-40px'
                }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <InfoIcon color={'disabled'} />
              </p>
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    margin: 0
                  }}
                >
                  Credit scores used by vendors may vary.
                </p>
                <p
                  style={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    marginTop: '5px',
                    marginBottom: 0,
                    fontWeight: 'bold'
                  }}
                >
                  {'VantageScore\u00AE '}
                  <span style={{ color: colors.lightGreen }}>3.0</span>
                  {' credit score using Equifax data.'}
                </p>
              </Popover>
            </div>
            <div className="bottom">
              <div className={classes.titles}>
                <span>Updated</span>
                <span>Next update</span>
              </div>
              <div className={classes.dates}>
                <span>{moment(sc[provider].date).format('MMM DD, Y')}</span>
                {checkFlag(appStore.getConfig().flags, Flags.WEEKLY_UPDATE) ? (
                  <span>
                    {moment(sc[provider].date)
                      .add(7, 'd')
                      .format('MMM DD, Y')}
                  </span>
                ) : (
                  <span>
                    {moment(sc[provider].date)
                      .add(1, 'M')
                      .format('MMM DD, Y')}
                  </span>
                )}
              </div>
            </div>
            <div className="bottom-responsive">
              <div className={classes.titles}>
                <span>Updated</span>
                <span>{moment(sc[provider].date).format('MMM DD, Y')}</span>
              </div>
              <div className={classes.titles}>
                <span>Next update</span>
                {checkFlag(appStore.getConfig().flags, Flags.WEEKLY_UPDATE) ? (
                  <span>
                    {moment(sc[provider].date)
                      .add(7, 'd')
                      .format('MMM DD, Y')}
                  </span>
                ) : (
                  <span>
                    {moment(sc[provider].date)
                      .add(1, 'M')
                      .format('MMM DD, Y')}
                  </span>
                )}
              </div>
            </div>
          </ScoreWrapper>
        )}
      </Wrapper>
    </div>
  );
};

export default observer(Score);
