import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import ContentWrapper from '@components/ContentWrapper';

import Button from '@material-ui/core/Button';
import { globalStyles } from '@assets/styles/globalStyles';

import Lock from '@assets/icons/lock_circle';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.div`
  display: flex;
  margin: auto;
  margin-top: -20px;
  justify-content: center;
`;

const AccountLock: React.FC<IProps> = () => {
  const gs = globalStyles();
  const { pathname } = useLocation();

  const renderPermanentlyLockedContent = () => {
    return (
      <React.Fragment>
        <p>Your account has been permanently locked out.</p>
        <p>
          To reset your account you will need <br />
          to contact customer support.
        </p>
      </React.Fragment>
    );
  };

  const renderTemporaryLockedContent = () => {
    return (
      <React.Fragment>
        <p>
          Your account has been temporarily locked out <br />
          as a result of excessive bad login attempts.
        </p>
        <p>Please wait at least 30min before trying again.</p>
      </React.Fragment>
    );
  };

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <Logo>
          <Lock />
        </Logo>
        <h2>Account Lockout</h2>
        {pathname.indexOf('account-lock-perm') > -1 &&
          renderPermanentlyLockedContent()}
        {pathname.indexOf('account-lock-temp') > -1 &&
          renderTemporaryLockedContent()}
        <Button
          to="/login"
          component={Link}
          color="secondary"
          variant="contained"
          className={gs.button2}
        >
          Ok
        </Button>
      </Wrapper>
    </ContentWrapper>
  );
};

export default AccountLock;
