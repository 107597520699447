import React, { useEffect } from 'react';
import moment from 'moment';
import { eAxios } from '@utils/api';
import * as api from '@api/';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';
import ButtonBase from '@material-ui/core/ButtonBase';

import Utils from '@utils/efxutil';
import Flags from '@utils/constants/flags';
import { checkFlag } from '@utils/helpers/checkPermission';
import ICreditMonitoring from '@interfaces/creditMonitoring';

import Download from '@assets/icons/download';

const useStyles = makeStyles(theme =>
  createStyles({
    tableContainer: {
      display: 'flex',
      margin: '0 -44px',
      marginLeft: 100,
      overflowX: 'scroll'
    },
    table: {
      flex: 1,
      textAlign: 'left',
      border: 0,
      width: '100%',
      borderCollapse: 'collapse',
      '& tr': {
        height: 48
      },
      '& th, td': {
        whiteSpace: 'nowrap',
        paddingLeft: 20,
        textAlign: 'center'
      },
      '& tr:nth-child(even)': {
        backgroundColor: colors.lightContainer,
        '& th:first-child': {
          backgroundColor: 'inherit'
        }
      },
      '& tr th:first-child': {
        paddingLeft: 44,
        position: 'absolute',
        left: 0,
        height: 46,
        width: 100,
        lineHeight: '45px',
        [theme.breakpoints.down('sm')]: {
          borderRight: `1px solid ${colors.lightViolet}`
        }
      },
      '& tr td:last-child, tr th:last-child': {
        paddingRight: 44
      }
    },
    tableHead: {
      fontWeight: 600,
      fontSize: fontSizes.paragraph2,
      letterSpacing: 0.02
    },
    reportsRow: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'start',
      '&.spaced': {
        justifyContent: 'space-between'
      },
      '& > div:last-of-type': {
        marginLeft: 30
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > div:last-of-type': {
          marginLeft: 0
        }
      }
    },
    totalRow: {
      color: colors.lightViolet,
      fontSize: fontSizes.paragraph1
    },
    row: {
      fontSize: fontSizes.paragraph1
    },
    otherColumn: {
      display: 'flex',
      paddingRight: 15,
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
        position: 'relative'
      }
    },
    responsive: {
      [theme.breakpoints.down('sm')]: {
        // paddingBottom: 70
      }
    },
    customerColor: {
      color: theme.palette.action.active
    }
  })
);

interface IProps {
  data: ICreditMonitoring;
  multi: boolean;
  hideHeader?: boolean;
  hideTitle?: boolean;
}

interface IBlockStyleProps {
  background?: boolean;
}

interface IBlockBadgeProps {
  color: string;
}

interface IReportResultProps {
  color?: string;
}

const Main = styled.div`
  margin: 10px 0;
  background: ${colors.white};
`;

const HeaderWrapper = styled.div`
  padding: 32px 44px 0px 44px;
  .downloadResponsive {
    display: none;
  }
  @media (max-width: 600px) {
    .header > .download {
      display: none;
    }
    .downloadResponsive {
      display: block;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  justify-content: space-between;
`;

const Title = styled.h1`
  color: ${colors.black}
  font-weight: bold;
  margin: 0;
`;

const DownloadText = styled.span`
  letter-spacing: 0.02em;
  font-size: ${fontSizes.h4};
  line-height: 155%;
`;

const DownloadIcon = styled.span`
  margin-right: 8px;
`;

const Divider = styled.div`
  background-color: ${colors.gray};
  box-shadow: none;
  border: none;
  height: 1px;
  margin: 0 -12px;
`;

const ReportDivider = styled(Divider)`
  position: absolute;
  right: 0;
  left: 0;
  top: 28px;
`;

const ResultTitle = styled.span`
  font-weight: 600;
  font-size: ${fontSizes.h5};
  line-height: 20px;
  color: ${colors.black};
  white-space: nowrap;
`;

const ReportResult = styled.span<IReportResultProps>`
  font-weight: ${({ color }) => (color ? 'bold' : 'normal')};
  font-size: ${fontSizes.h4};
  line-height: 1.3;
  letter-spacing: 0.03em;
  margin-top: 16px;
  color: ${({ color }) => (color ? color : colors.black)};
`;

const Description = styled.p`
  line-height: 1.3;
  letter-spacing: 0.03em;
  font-size: ${fontSizes.h3};
  max-width: 950px;
`;

const Block = styled.div<IBlockStyleProps>`
  position: relative;
  padding: 42px 44px 37px 44px;
  background-color: ${({ background }) =>
    background ? colors.lightContainer : colors.white};
`;

const ReportBlock = styled.div`
  padding: 32px 44px 50px 44px;
`;

const BlockTitle = styled.h3`
  font-weight: bold;
  font-size: ${fontSizes.h3};
  line-height: 140%;
`;

const BlockBadge = styled.div<IBlockBadgeProps>`
  position: absolute;
  left: 0;
  top: 62px;
  width: 6px;
  height: 70px;
  background-color: ${({ color }) => color};
`;

const Report: React.FC<IProps> = ({ data, multi, hideHeader, hideTitle }) => {
  const { hash } = useLocation();
  const classes = useStyles();
  const { appStore, dashboardStore } = useStores();
  const { formatMessage: t, formatNumber } = useIntl();
  const provider = data.provider;
  const prefix = multi ? t({ id: `providers.${provider}` }) : 'Equifax';

  const report = Utils._processSummary([data]);
  const sc = Utils._processScores(dashboardStore.latestScore);

  const downloadReport = async () => {
    api.getDeliveryToken().then(() => {
      const baseUrl = eAxios.defaults.baseURL;
      const token = eAxios.defaults.headers.common['Authorization'].split(
        ' '
      )[1];
      const a = document.createElement('a');
      a.href = `${baseUrl}/v1/creditReport/${dashboardStore.reportId}/print?access_token=${token}`;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
    });
  };

  useEffect(() => {
    if (hash) {
      if (document.getElementById(hash.split('#')[1])) {
        //@ts-ignore
        document.getElementById(hash.split('#')[1]).scrollIntoView();
      }
    }
  }, [hash]);

  return (
    <Main className={classes.responsive}>
      <HeaderWrapper>
        {!hideHeader && (
          <>
            <Header className="header">
              {!hideTitle && <Title>{prefix}: Report Summary</Title>}
              {hideTitle && <div />}
              <ButtonBase onClick={downloadReport} className="download">
                <DownloadIcon>
                  <Download />
                </DownloadIcon>
                <DownloadText className={classes.customerColor}>
                  Download Full Report
                </DownloadText>
              </ButtonBase>
            </Header>
            <Divider />
          </>
        )}
        <Description>
          Review this summary for a quick view of key information maintained in
          your {prefix} credit report, as well as your resulting {prefix} Credit
          Score and rating.
        </Description>
        <ButtonBase onClick={downloadReport} className="downloadResponsive">
          <DownloadIcon>
            <Download />
          </DownloadIcon>
          <DownloadText className={classes.customerColor}>
            Download Full Report
          </DownloadText>
        </ButtonBase>
      </HeaderWrapper>
      <ReportBlock id="s1">
        <div className={classes.reportsRow}>
          <div className={classes.otherColumn}>
            <ResultTitle>Report Date</ResultTitle>
            <ReportDivider />
            <ReportResult>
              {moment(report[provider].date).format('MM/DD/YYYY')}
            </ReportResult>
          </div>
          <div className={classes.otherColumn}>
            <ResultTitle>Average Account Age</ResultTitle>
            <ReportDivider />
            <ReportResult>
              {Math.trunc(report[provider].additional.avgAccountAge / 12)}{' '}
              Years, {report[provider].additional.avgAccountAge % 12} Months
            </ReportResult>
          </div>
          <div className={classes.otherColumn}>
            <ResultTitle>Oldest Account</ResultTitle>
            <ReportDivider />
            <ReportResult>
              {report[provider].additional.oldestAccount} |{' '}
              {moment(report[provider].additional.oldestAccountDate).format(
                'MM/DD/YYYY'
              )}
            </ReportResult>
          </div>
        </div>
      </ReportBlock>
      {checkFlag(appStore.getConfig().flags, Flags.REPORT_SCORE) && (
        <Block background id="s2">
          <BlockTitle>Credit Score and Rating</BlockTitle>
          <BlockBadge color={colors.lightGreen} />
          <Description>
            Your credit report includes information about activity on your
            credit accounts that affects your credit score and rating. The table
            below is an overview of your current account and status.
          </Description>
          <br />
          <br />
          <div className={classes.reportsRow}>
            <div className={classes.otherColumn}>
              <ResultTitle>Credit Score</ResultTitle>
              <ReportDivider />
              <ReportResult color={colors.lightGreen}>
                {report[provider].score}
              </ReportResult>
            </div>
            <div className={classes.otherColumn}>
              <ResultTitle>Score Rating</ResultTitle>
              <ReportDivider />
              <ReportResult color={colors.lightGreen}>
                {report[provider].rating}
              </ReportResult>
            </div>
          </div>
          {checkFlag(appStore.getConfig().flags, Flags.SCORE_FACTORS) && (
            <div id="factors">
              {sc && sc[provider].factors.positive.length > 0 && (
                <div
                  style={{
                    marginTop: 5,
                    borderTop: `1px solid ${colors.gray}`
                  }}
                >
                  <p>Positive Factors:</p>
                  <ul>
                    {sc[provider].factors.positive.map((f: any, i: number) => {
                      return <li key={i}>{f.description}</li>;
                    })}
                  </ul>
                  <hr />
                </div>
              )}
              {sc && sc[provider].factors.negative.length > 0 && (
                <div
                  style={{
                    marginTop: 5,
                    borderTop: `1px solid ${colors.gray}`
                  }}
                >
                  <p>Negative Factors:</p>
                  <ul>
                    {sc[provider].factors.negative.map((f: any, i: number) => {
                      return <li key={i}>{f.description}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
        </Block>
      )}
      {checkFlag(appStore.getConfig().flags, Flags.REPORT_ACCOUNTS) && (
        <Block id="s3">
          <BlockBadge color={colors.lightViolet} />
          <BlockTitle>Credit Accounts</BlockTitle>
          <Description>
            Your credit report includes information about activity on your
            credit accounts that affect your credit score and rating. The table
            below is an overview of your current accounts and status.
          </Description>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <tbody>
                <tr className={classes.tableHead}>
                  <th>Account&nbsp;Type</th>
                  <th>Open</th>
                  <th>With&nbsp;Balance</th>
                  <th>Total&nbsp;Balance</th>
                  <th>Available</th>
                  <th>Credit&nbsp;Limit</th>
                  <th>
                    <div>Debt-to-Credit</div>
                  </th>
                  <th>Payment</th>
                </tr>
                {Object.keys(report[provider].accountsDetail).map(
                  (t: any, i: number) => {
                    return (
                      <tr
                        key={i}
                        className={
                          report[provider].accountsDetail[t].title === 'Total'
                            ? classes.totalRow
                            : classes.row
                        }
                      >
                        <th>{report[provider].accountsDetail[t].title}</th>
                        <td>{report[provider].accountsDetail[t].open}</td>
                        <td>
                          {report[provider].accountsDetail[t].openWithBalance}
                        </td>
                        <td>
                          {formatNumber(
                            report[provider].accountsDetail[t].balance || 0,
                            { style: 'currency', currency: 'USD' }
                          )}
                        </td>
                        <td>
                          {formatNumber(
                            report[provider].accountsDetail[t].avail || 0,
                            { style: 'currency', currency: 'USD' }
                          )}
                        </td>
                        <td>
                          {formatNumber(
                            report[provider].accountsDetail[t].limit || 0,
                            { style: 'currency', currency: 'USD' }
                          )}
                        </td>
                        <td>{report[provider].accountsDetail[t].debtRatio}%</td>
                        <td>
                          {formatNumber(
                            report[provider].accountsDetail[t].paymentAmt || 0,
                            { style: 'currency', currency: 'USD' }
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </Block>
      )}
      {checkFlag(appStore.getConfig().flags, Flags.REPORT_OTHER) && (
        <Block background id="s4">
          <BlockBadge color={colors.lightCyan} />
          <BlockTitle>Other Credit Items</BlockTitle>
          <Description>
            Your credit report includes information about instances of
            non-account items that may affect your credit score and rating. The
            table below is a summary of non-account related items on your
            report.
          </Description>
          <br />
          <br />
          <div className={`${classes.reportsRow} spaced`}>
            <div className={classes.otherColumn}>
              <ResultTitle>Consumer Statements</ResultTitle>
              <ReportDivider />
              <ReportResult>
                <ReportResult color={colors.lightCyan}>
                  {report[provider].additional.consumer}
                </ReportResult>{' '}
                Statements found
              </ReportResult>
            </div>
            <div className={classes.otherColumn}>
              <ResultTitle>Personal Information</ResultTitle>
              <ReportDivider />
              <ReportResult>
                <ReportResult color={colors.lightCyan}>
                  {report[provider].additional.personal}
                </ReportResult>{' '}
                Items found
              </ReportResult>
            </div>
            <div className={classes.otherColumn}>
              <ResultTitle>Inquiries</ResultTitle>
              <ReportDivider />
              <ReportResult>
                <ReportResult color={colors.lightCyan}>
                  {report[provider].additional.inquiries}
                </ReportResult>{' '}
                Inquiries found
              </ReportResult>
            </div>
            <div className={classes.otherColumn}>
              <ResultTitle>Public Records</ResultTitle>
              <ReportDivider />
              <ReportResult>
                <ReportResult color={colors.lightCyan}>
                  {report[provider].additional.public}
                </ReportResult>{' '}
                Records found
              </ReportResult>
            </div>
            <div className={classes.otherColumn}>
              <ResultTitle>Collections</ResultTitle>
              <ReportDivider />
              <ReportResult>
                <ReportResult color={colors.lightCyan}>
                  {report[provider].additional.collections}
                </ReportResult>{' '}
                Collections found
              </ReportResult>
            </div>
          </div>
        </Block>
      )}
    </Main>
  );
};

Report.defaultProps = {
  hideHeader: false,
  hideTitle: false
};

export default Report;
