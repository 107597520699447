import { Events } from '@utils/enums/events';

interface IError {
  timestamp: number;
  codes: string[];
  messages: string[];
  details: string[];
}

export const errorHandler = (error: IError) => {
  if (error && error.codes && error.codes.length > 0) {
    error.codes.forEach((error: string) => {
      if (error === 'SC303')
        window.parent.postMessage({ type: Events.IDENTITY_FAILED }, '*');
      if (error === 'SC311')
        window.parent.postMessage({ type: Events.SERVICE_FAILURE }, '*');
      if (error === 'SC101')
        window.parent.postMessage({ type: Events.UNKNOWN_USER }, '*');
      if (error === 'SC102')
        window.parent.postMessage(
          { type: Events.USER_ALREADY_REGISTERED },
          '*'
        );
      if (error === 'SC106')
        window.parent.postMessage({ type: Events.HOST_TOKEN_EXPIRED }, '*');
      if (error === 'SC110')
        window.parent.postMessage({ type: Events.REFRESH_TOKEN_EXPIRED }, '*');
      if (error === 'SC113')
        window.parent.postMessage({ type: Events.BAD_CREDENTIALS }, '*');
      if (error === 'SC114')
        window.parent.postMessage({ type: Events.ACCOUNT_LOCKED_TEMP }, '*');
      if (error === 'SC115')
        window.parent.postMessage({ type: Events.IP_LOCKED_TEMP }, '*');
      if (error === 'SC116')
        window.parent.postMessage({ type: Events.ACCOUNT_LOCKED }, '*');
      if (error === 'SC117')
        window.parent.postMessage({ type: Events.PREAUTH_TOKEN_EXPIRED }, '*');
      if (error === 'SC301')
        window.parent.postMessage(
          { type: Events.USER_ALREADY_IDENTIFIED },
          '*'
        );
      if (error === 'SC302')
        window.parent.postMessage({ type: Events.USER_IDENTITY_REQUIRED }, '*');
      if (error === 'SC304')
        window.parent.postMessage({ type: Events.PHONE_NUMBER_REQUIRED }, '*');
      if (error === 'SC305')
        window.parent.postMessage({ type: Events.OTP_FAILED }, '*');
      if (error === 'SC306')
        window.parent.postMessage({ type: Events.THIN_FILE_ERROR }, '*');
      if (error === 'SC313')
        window.parent.postMessage({ type: Events.ENROLLMENT_FAILED }, '*');
      if (error === 'SC318')
        window.parent.postMessage(
          { type: Events.USER_ENROLLMENT_LIMIT_EXCEEDED },
          '*'
        );
      if (error === 'SC320')
        window.parent.postMessage({ type: Events.VS3_UNAVAILABLE }, '*');
      if (error === 'SC321')
        window.parent.postMessage(
          { type: Events.IDFS_USER_LOCKED_WAIT_72_HOURS },
          '*'
        );
      if (error === 'SC322')
        window.parent.postMessage(
          { type: Events.IDFS_REQUEST_ALREADY_COMPLETED },
          '*'
        );
      if (error === 'SC322')
        window.parent.postMessage({ type: Events.USER_EXCEPTION }, '*');
      if (error === 'SC220')
        window.parent.postMessage(
          { type: Events.CUSTOMER_HOST_EXCEPTION },
          '*'
        );
    });
  } else {
    return null;
  }
};
