import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';
import { Link, useHistory } from 'react-router-dom';

import { globalStyles } from '@assets/styles/globalStyles';
import { ButtonBase, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import fontSizes from '@assets/styles/fontSizes';
import { Autocomplete } from '@material-ui/lab';
import colors from '@assets/styles/colors';

import Footer from '@components/Footer';
import ContentWrapper from '@components/ContentWrapper';
import { Header } from '@assets/styles/commonComponents';

import { Route } from '@utils/enums/routes';
import { Events } from '@utils/enums/events';
import { statesArr } from '@utils/constants/states';
import { dateRegex } from '@utils/constants/regex';
import { IUserIdentityReqDTO } from '@interfaces/user';
import LogoSrc from '@assets/images/logo.svg';
import {
  DateMask,
  DateSanitizedMask,
  MobileMask,
  SsnMask,
  SsnSanitizedMask,
  getSsnUnmasked,
  getUnmaskedPhoneNumber
} from '@components/InputMasks';

interface IProps {}

const useStyles = makeStyles(theme => ({
  cityInput: {
    marginRight: 42
  },
  textField: {
    '& label': {
      fontSize: 12
    },
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiInput-underline:after': {
      boder: 'none',
      borderBottomColor: 'black'
    },
    '& input': {
      width: '100%',
      border: 'none',
      '&:focus-visible': {
        outline: 'none'
      }
    }
  },
  customerColor: {
    color: theme.palette.secondary.main
  },
  title: {
    fontWeight: 'bold',
    fontSize: fontSizes.h1,
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizes.h2
    }
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      // paddingTop: 30
    }
  }
}));

const Container = styled.div`
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SignIn = styled.span`
  font-size: ${fontSizes.h5};
`;

const DescriptionContainer = styled.div`
  font-size: ${fontSizes.h5};
  color: ${colors.black};
  line-height: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const CustomLink = styled(Link)`
  text-decoration: none;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const identitySchema = object({
  fname: string()
    .max(40, 'Maximum character limit is 40')
    .required('First name is required.'),
  lname: string()
    .max(40, 'Maximum character limit is 40')
    .required('Last name is required.'),
  ssn: string()
    .required('Social security number is required.')
    .test(
      'ssn-custom-validation',
      'Social security number must contain 9 digits.',
      (value: string) => {
        if (!value) {
          return true;
        }

        const unmaskedValue = getSsnUnmasked(value);
        return unmaskedValue.length === 9;
      }
    ),
  dob: string().matches(dateRegex, 'The date must be formatted as MM-DD-YYYY.'),
  mobile: string()
    .required('Mobile phone is required.')
    .test(
      'mobile-custom-validation',
      'Mobile phone number must have 10 digits.',
      (value: string) => {
        if (!value) {
          return true;
        }
        const unmaskedValue = getUnmaskedPhoneNumber(value);
        return unmaskedValue.length === 10;
      }
    ),
  street1: string()
    .max(75, 'Maximum character limit is 75')
    .required('Street address is required.'),
  street2: string().max(75, 'Maximum character limit is 75'),
  state: string().matches(/^(?!0$).*/g, 'State is required.'),
  city: string()
    .max(50, 'Maximum character limit is 50')
    .required('City is required.'),
  zip: string()
    .required('Zip-code is required.')
    .length(5, 'Must be exactly 5 characters')
});

const Identity: React.FC<IProps> = () => {
  const gs = globalStyles();
  const classes = useStyles();
  const history = useHistory();
  const { appStore, userStore, notifyStore } = useStores();
  const stateRef = useRef(null);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserIdentityReqDTO>({
    fname: '',
    lname: '',
    ssn: '',
    dob: '',
    mobile: '',
    street1: '',
    street2: '',
    state: '',
    city: '',
    zip: '',
    immutable: false
  });
  const config = appStore.getConfig();

  const redirectView = appStore.getRedirectView();

  useEffect(() => {
    if (history.action !== 'POP') {
      window.parent.postMessage({ type: Events.IDENTITY_STARTED }, '*');
    }
  }, [history.action]);

  useEffect(() => {
    setUserData(notifyStore.identityData);
  }, [notifyStore.identityData]);

  const formik = useFormik({
    initialValues: { ...userData },
    validationSchema: identitySchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async ({
      fname,
      lname,
      ssn,
      dob,
      city,
      mobile,
      state,
      street1,
      street2,
      zip
    }) => {
      setSubmitDisabled(true);
      const { isSuccess, idpass, redirect } = await userStore.identityUser({
        fname,
        lname,
        ssn: getSsnUnmasked(ssn),
        dob: dob ? moment(dob, 'MM-DD-YYYY').format('YYYY-MM-DD') : '',
        mobile: getUnmaskedPhoneNumber(mobile),
        street1,
        street2,
        // @ts-ignore
        state:
          state && state.length === 2
            ? statesArr.find(s => s.shortName === state)?.shortName
            : statesArr.find(s => s.name === state)?.shortName,
        city,
        zip
      });
      if (isSuccess && idpass) {
        const postIdentityRedirect = redirect || redirectView;
        history.push(postIdentityRedirect);
      } else if (isSuccess && !idpass) {
        history.push(config.useIDFS ? Route.MOBILE : Route.DIT);
      } else {
        setSubmitDisabled(false);
      }
    }
  });

  const handleTermsOfUseClick = () => {
    notifyStore.setIdentityData(formik.values);
    history.push(Route.TERMS_OF_USE);
  };

  const handlePrivacyPolicyClick = () => {
    notifyStore.setIdentityData(formik.values);
    history.push(Route.PRIVACY_POLICY);
  };

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!config.popupMode}
      hasBackgroundImg={config.hasBackgroundImg}
    >
      <form
        className={classes.form}
        onSubmit={e => {
          const err = Object.keys(formik.errors);

          //Scroll to first error
          if (err.length) {
            const input: any = document.querySelector(`input[name=${err[0]}]`);

            input.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'start'
            });
          }

          formik.handleSubmit(e);
        }}
        color="primary"
      >
        <Wrapper className={gs.wrapper} style={{ paddingBottom: 0 }}>
          <Header>
            <h1 className={classes.title}>{config.identityScreenTitleText}</h1>
            {config.hasHeaderLogo && <Logo src={config.logo || LogoSrc} />}
          </Header>
          <Container>
            <TextField
              fullWidth
              id="first_name"
              margin="normal"
              name="fname"
              label="First name*"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.fname}
              placeholder="Enter your first name"
              classes={{ root: classes.textField }}
              helperText={formik.touched.fname ? formik.errors.fname : ''}
              error={formik.touched.fname && Boolean(formik.errors.fname)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.fname)
              }}
            />
            <TextField
              fullWidth
              id="last_name"
              margin="normal"
              name="lname"
              label="Last name*"
              onBlur={formik.handleBlur}
              value={formik.values.lname}
              onChange={formik.handleChange}
              placeholder="Enter your last name"
              classes={{ root: classes.textField }}
              helperText={formik.touched.lname ? formik.errors.lname : ''}
              error={formik.touched.lname && Boolean(formik.errors.lname)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.lname)
              }}
            />
            <TextField
              name="ssn"
              classes={{ root: classes.textField }}
              label="Social security number*"
              placeholder="Enter your social security number"
              fullWidth
              type="tel"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.ssn}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.ssn ? formik.errors.ssn : ''}
              error={formik.touched.ssn && Boolean(formik.errors.ssn)}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.ssn),
                inputComponent: Boolean(userData.immutable && userData.ssn)
                  ? SsnSanitizedMask
                  : SsnMask
              }}
            />
            <TextField
              name="dob"
              classes={{ root: classes.textField }}
              label="Date of birth"
              placeholder="MM-DD-YYYY"
              fullWidth
              margin="normal"
              type="tel"
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.dob ? formik.errors.dob : ''}
              error={formik.touched.dob && Boolean(formik.errors.dob)}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.dob),
                inputComponent: Boolean(userData.immutable && userData.dob)
                  ? DateSanitizedMask
                  : DateMask
              }}
            />
            <TextField
              name="mobile"
              id="mobile"
              classes={{ root: classes.textField }}
              label="Mobile phone*"
              fullWidth
              type="tel"
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.mobile}
              helperText={formik.touched.mobile ? formik.errors.mobile : ''}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              placeholder="Enter your mobile phone number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.mobile),
                inputComponent: MobileMask
              }}
            />
            <TextField
              classes={{ root: classes.textField }}
              name="street1"
              label="Street address*"
              placeholder="Enter your street address"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.street1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.street1 ? formik.errors.street1 : ''}
              error={formik.touched.street1 && Boolean(formik.errors.street1)}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.street1)
              }}
            />
            <TextField
              classes={{ root: classes.textField }}
              name="street2"
              label="Street address 2"
              placeholder="Enter your street address 2"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.street2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.street2 ? formik.errors.street2 : ''}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.street2)
              }}
            />
            <TextField
              className={classes.cityInput}
              classes={{ root: classes.textField }}
              name="city"
              label="City*"
              placeholder="Enter your city"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.city ? formik.errors.city : ''}
              error={formik.touched.city && Boolean(formik.errors.city)}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.city)
              }}
            />
            <Autocomplete
              id="state"
              onChange={(e: any) => {
                if (e.target.innerText) {
                  formik.setFieldValue('state', e.target.innerText);
                } else {
                  formik.setFieldValue('state', '0');
                }
              }}
              options={statesArr}
              value={
                formik.values.state
                  ? statesArr.find(s => s.shortName === formik.values.state) ||
                    statesArr.find(s => s.name === formik.values.state)
                  : null
              }
              getOptionLabel={(option: any) => option.name}
              renderOption={option => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={params => {
                const inputProps = params.inputProps;
                //@ts-ignore
                inputProps.autoComplete = 'new-password';
                return (
                  <TextField
                    ref={stateRef}
                    {...params}
                    fullWidth
                    inputProps={inputProps}
                    margin="normal"
                    classes={{ root: classes.textField }}
                    label="State*"
                    placeholder={'Select State'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={formik.values.state}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.state ? formik.errors.state : ''}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: Boolean(userData.immutable && userData.state)
                    }}
                  />
                );
              }}
            />
            <TextField
              classes={{ root: classes.textField }}
              name="zip"
              type="string"
              label="Zipcode*"
              placeholder="Enter your zipcode"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              value={formik.values.zip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.zip ? formik.errors.zip : ''}
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              InputProps={{
                readOnly: Boolean(userData.immutable && userData.zip)
              }}
            />
            <DescriptionContainer>
              {config.fullDiscOnIdentity && (
                <>
                  <span>
                    By clicking "Continue" and creating an account you accept
                    StitchCredit’s{' '}
                    <ButtonBase
                      className={classes.customerColor}
                      onClick={handleTermsOfUseClick}
                    >
                      Terms of Use
                    </ButtonBase>{' '}
                    and{' '}
                    <ButtonBase
                      className={classes.customerColor}
                      onClick={handlePrivacyPolicyClick}
                    >
                      Privacy Policy
                    </ButtonBase>
                    . StitchCredit does not maintain critical personal data,
                    much less sell or otherwise disclose your personal
                    information to anyone else. You may opt-out of email
                    correspondence, except confirmation Emails, which often
                    contain important information about your account.
                  </span>
                  <br />
                  <br />
                </>
              )}
              <span>
                You understand that by clicking “Continue”, you are explicitly
                agreeing to and providing “written instructions” to StitchCredit
                under the Fair Credit Reporting Act to obtain my credit
                information from one or more of the three nationwide credit
                reporting agencies. Third-party sources, including your mobile
                carrier may be used to verify your identity. You authorize
                StitchCredit to obtain such information for you to confirm your
                identity, and, for as long as you are a member of StitchCredit,
                to provide you with your credit information. You may elect to
                terminate your account and this authorization at any time.
              </span>
            </DescriptionContainer>
          </Container>
          <Footer>
            <ButtonBase
              disabled={submitDisabled}
              classes={{ root: gs.button, disabled: gs.disabled }}
              type="submit"
            >
              Continue
            </ButtonBase>
            {!appStore.isDirectUser && (
              <SignIn>
                Already have an account?{' '}
                <CustomLink
                  className={classes.customerColor}
                  to={`/${Route.LOGIN}`}
                >
                  Sign in
                </CustomLink>
              </SignIn>
            )}
          </Footer>
        </Wrapper>
      </form>
    </ContentWrapper>
  );
};

export default observer(Identity);
