import React from 'react';
import styled from 'styled-components';

import { globalStyles } from '@assets/styles/globalStyles';

import ContentWrapper from '@components/ContentWrapper';

import Unavailable from '@assets/icons/unavailable';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.div`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const RegisterFail: React.FC<IProps> = () => {
  const gs = globalStyles();

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <Logo>
          <Unavailable />
        </Logo>
        <h2>Registration Unavailable</h2>
        <p className={gs.description}>
          Unfortunately, we cannot continue registration <br />
          at this time.
        </p>
        <p className={gs.description}>
          Please check with your site admin <br />
          or try again later.
        </p>
      </Wrapper>
    </ContentWrapper>
  );
};

export default RegisterFail;
