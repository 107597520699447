import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';
import Flags from '@utils/constants/flags';

import Icons from './Icons';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { Route } from '@utils/enums/routes';
import { checkFlag } from '@utils/helpers/checkPermission';
import { useStores } from '@utils/hooks/useStores';

interface IButtonNavigationProps {}

const useStyles = makeStyles(theme => ({
  bottomNavigation: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    height: 75,
    zIndex: 1302,
    backgroundColor: colors.lightBackground,
    padding: '0 10px',
    display: 'flex',
    flexDirection: 'column',
    '-webkit-backface-visibility': 'hidden',
    overflowX: 'auto'
  },

  bottomItem: {
    textDecoration: 'none',
    fontSize: fontSizes.h4,
    color: colors.lightBlack,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  activeItem: {
    color: theme.palette.secondary.main
  },
  navigationItems: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  customerColor: {
    '& path': {
      fill: theme.palette.secondary.main
    }
  }
}));

const ButtonNavigation: React.FC<IButtonNavigationProps> = () => {
  const classes = useStyles();
  const { formatMessage: t } = useIntl();
  let url = useLocation();
  const { appStore } = useStores();

  return (
    <BottomNavigation showLabels className={classes.bottomNavigation}>
      <div className={classes.navigationItems}>
        {checkFlag(appStore.getConfig().flags, Flags.SCORE) && (
          <BottomNavigationAction
            showLabel
            classes={{ root: classes.bottomItem }}
            label={t({ id: 'common.score' })}
            component={Link}
            to={`/${Route.DASHBOARD_SCORE}`}
            icon={
              <Icons
                isActive={url.pathname === `/${Route.DASHBOARD_SCORE}`}
                color={'#000'}
                icon={'score'}
              />
            }
            className={
              url.pathname === `/${Route.DASHBOARD_SCORE}`
                ? `${classes.activeItem} ${classes.customerColor}`
                : ''
            }
          />
        )}
        {checkFlag(appStore.getConfig().flags, Flags.SCORE_UP) && (
          <BottomNavigationAction
            showLabel
            classes={{ root: classes.bottomItem }}
            label={t({ id: 'common.score_up' })}
            component={Link}
            to={`/${Route.DASHBOARD_SCORE_UP}`}
            icon={
              <Icons
                isActive={url.pathname === `/${Route.DASHBOARD_SCORE_UP}`}
                color={'#000'}
                icon={'score'}
              />
            }
            className={
              url.pathname === `/${Route.DASHBOARD_SCORE_UP}`
                ? `${classes.activeItem} ${classes.customerColor}`
                : ''
            }
          />
        )}
        {checkFlag(appStore.getConfig().flags, Flags.REPORT) && (
          <BottomNavigationAction
            showLabel
            classes={{ root: classes.bottomItem }}
            label={t({ id: 'common.reports' })}
            component={Link}
            to={`/${Route.DASHBOARD_REPORT}`}
            icon={
              <Icons
                isActive={url.pathname === `/${Route.DASHBOARD_REPORT}`}
                color={'#000'}
                icon={'report'}
              />
            }
            className={
              url.pathname.indexOf(Route.DASHBOARD_REPORT) > -1
                ? `${classes.activeItem} ${classes.customerColor}`
                : ''
            }
          />
        )}
        {checkFlag(appStore.getConfig().flags, Flags.ALERTS) && (
          <BottomNavigationAction
            showLabel
            classes={{ root: classes.bottomItem }}
            label={t({ id: 'common.monitoring' })}
            component={Link}
            to={`/${Route.DASHBOARD_MONITOR}`}
            icon={
              <Icons
                isActive={url.pathname === `/${Route.DASHBOARD_MONITOR}`}
                color={'#000'}
                icon={'monitor'}
              />
            }
            className={
              url.pathname.indexOf(Route.DASHBOARD_MONITOR) > -1
                ? `${classes.activeItem} ${classes.customerColor}`
                : ''
            }
          />
        )}
        {checkFlag(appStore.getConfig().flags, Flags.RESTORE) && (
          <BottomNavigationAction
            showLabel
            classes={{ root: classes.bottomItem }}
            label={t({ id: 'common.id_restore' })}
            component={Link}
            to={`/${Route.DASHBOARD_IDRESTORE}`}
            icon={
              <Icons
                isActive={url.pathname === `/${Route.DASHBOARD_IDRESTORE}`}
                color={'#000'}
                icon={'restore'}
              />
            }
            className={
              url.pathname.indexOf(Route.DASHBOARD_IDRESTORE) > -1
                ? `${classes.activeItem} ${classes.customerColor}`
                : ''
            }
          />
        )}
      </div>
    </BottomNavigation>
  );
};

export default ButtonNavigation;
