import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.customerColor}
        d="M48 95C73.9574 95 95 73.9574 95 48C95 22.0426 73.9574 1 48 1C22.0426 1 1 22.0426 1 48C1 73.9574 22.0426 95 48 95Z"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        className={classes.customerColor}
        d="M48.0006 73.9308C48.8957 73.9308 49.6213 73.2052 49.6213 72.3101C49.6213 71.4151 48.8957 70.6895 48.0006 70.6895C47.1055 70.6895 46.3799 71.4151 46.3799 72.3101C46.3799 73.2052 47.1055 73.9308 48.0006 73.9308Z"
        fill="#EF4D7E"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        className={classes.customerColor}
        d="M36.6562 22.9622C45.5393 19.032 57.0364 19.5004 60.9666 25.8584C64.8968 32.2163 62.1789 39.6067 55.4562 45.3066C48.7336 51.0066 48.0011 54.859 48.0011 59.345"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
