import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import * as api from '@api/';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';

import ContentWrapper from '@components/ContentWrapper';
import Success from '@components/PasswordRecovery/Success';
import NewPassword from '@components/PasswordRecovery/NewPassword';
import SendResetLink from '@components/PasswordRecovery/SendResetLink';
import CheckYourEmail from '@components/PasswordRecovery/CheckYourEmail';
//import SecretQuestion from '@components/PasswordRecovery/SecretQuestion';
import VerificationCode from '@components/PasswordRecovery/VerificationCode';

interface IProps {
  redirected?: boolean;
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const PasswordRecovery: React.FC<IProps> = ({ redirected }) => {
  const gs = globalStyles();
  const { search } = useLocation();
  const { notifyStore, appStore } = useStores();
  const [step, setStep] = useState<number>(0);
  const [verCode, setVerCode] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [recQuestion, setRecQuestion] = useState<number>(0);
  const [recQuestionAnswer, setRecQuestionAnswer] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');
  const [wrongAnswer, setWrongAnswer] = useState<boolean>(false);

  const sendResetLink = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.recoverPassword(email);
      nextStep();
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  useEffect(() => {
    if (search.indexOf('email=true') !== -1) {
      setStep(2);
    }
  }, [search]);

  const resendCode = async () => {
    try {
      await api.recoverPassword(email);
      notifyStore.info('Code resent!');
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  const sendToken = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await api.validateResetToken(verCode);
      setToken(response.data.token);
      setRecQuestion(response.data.recoveryQuestion);
      nextStep();
    } catch (error) {
      notifyStore.error('Unable to verify the token');
    }
  };
  /*
  const secretAnswer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextStep();
  };
*/
  const changePassword = async (
    recQuestionAnswer: string,
    newPassword: string
  ) => {
    try {
      setWrongAnswer(false);
      setRecQuestionAnswer(recQuestionAnswer);
      setNewPassword(newPassword);
      await api.resetPassword(token, recQuestionAnswer, newPassword);
      nextStep();
    } catch (error) {
      setWrongAnswer(true);
      notifyStore.error("We're unable to reset your password");
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SendResetLink
            email={email}
            setEmail={setEmail}
            redirected={redirected}
            handleSubmit={sendResetLink}
          />
        );
      case 1:
        return <CheckYourEmail email={email} nextStep={nextStep} />;
      case 2:
        return (
          <VerificationCode
            verCode={verCode}
            nextStep={nextStep}
            setVerCode={setVerCode}
            resendCode={resendCode}
            handleSubmit={sendToken}
          />
        );
      /*      case 3:
        return (
          <SecretQuestion
            recQuestion={recQuestion}
            recQuestionAnswer={recQuestionAnswer}
            setRecQuestionAnswer={setRecQuestionAnswer}
            handleSubmit={secretAnswer}
          />
        );*/
      case 3:
        return (
          <NewPassword
            wrongAnswer={wrongAnswer}
            recQuestion={recQuestion}
            recQuestionAnswer={recQuestionAnswer}
            setRecQuestionAnswer={setRecQuestionAnswer}
            newPassword={newPassword}
            repeatNewPassword={repeatNewPassword}
            setNewPassword={setNewPassword}
            setRepeatNewPassword={setRepeatNewPassword}
            handleSubmit={changePassword}
          />
        );
      case 4:
        return <Success />;
    }
  };

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!appStore.config.popupMode}
      hasBackgroundImg={appStore.config.hasBackgroundImg}
    >
      <Wrapper className={gs.wrapper}>{renderContent(step)}</Wrapper>
    </ContentWrapper>
  );
};

PasswordRecovery.defaultProps = {
  redirected: false
};

export default PasswordRecovery;
