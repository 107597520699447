import React from 'react';
import styled from 'styled-components';

import { createStyles, makeStyles } from '@material-ui/core';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import ContentWrapper from '@components/AboutWrapper';

import WebTechnology from '@assets/icons/web.technology';
import WebFinance from '@assets/icons/web.finance';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: '30px 70px',
      [theme.breakpoints.down('md')]: {
        padding: '15px'
      }
    },
    icon: {
      marginRight: 15
    },
    description: {
      marginTop: 40,
      marginBottom: 30,
      padding: '40px 0',
      borderTop: `1px solid ${colors.gray}`,
      borderBottom: `1px solid ${colors.gray}`,
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column'
        }
      }
    }
  })
);

const Wrapper = styled.div`
  position: relative;
  p {
    line-height: 175%;
    font-weight: normal;
    letter-spacing: 0.03em;
  }
  img {
    margin: 25px;
  }
  > div:last-of-type {
    text-align: center;
    span {
      font-size: ${fontSizes.paragraph2};
    }
  }
`;

const About: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <ContentWrapper stretch={true}>
      <Wrapper className={classes.wrapper}>
        <h1>About StitchCredit</h1>
        <p>
          Everyone deserves to feel confident about their finances. At
          StitchCredit we work with our partners to give you the tools, the
          education and the opportunities you need to make real, meaningful
          progress.
        </p>
        <div className={classes.description}>
          <div>
            <div className={classes.icon}>
              <WebTechnology />
            </div>
            <p>
              StitchCredit is focused on making access to Credit Data
              democratizing simple <b>through technology</b>. We build beautiful{' '}
              <b> consumer experiences</b> and <b>intelligent tools</b> that
              make managing your finances simpler and easier to understand.
            </p>
          </div>
          <div>
            <div className={classes.icon}>
              <WebFinance />
            </div>
            <p>
              We want you to feel good about your options and make{' '}
              <b>informed financial decisions</b>. That means giving you the{' '}
              <b>right information</b> at the <b>right time</b> so you can zero
              in on what's important.
            </p>
          </div>
        </div>
        <div>
          <span>Best regards, StitchCredit Team</span>
        </div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default About;
