import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@assets/styles/colors';

import Utils from '@utils/efxutil';
import fontSizes from '@assets/styles/fontSizes';
import { eAxios } from '@utils/api';

interface IProps {
  activeStep: number;
}

const useStyles = makeStyles(theme =>
  createStyles({
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.lightInputText
    },
    tableContainer: {
      display: 'flex',
      margin: '0 -15px',
      // marginLeft: 100,
      overflowX: 'visible'
    },
    table: {
      flex: 1,
      textAlign: 'left',
      border: 0,
      width: '100%',
      borderCollapse: 'collapse',
      '& tr': {
        height: 48
      },
      '& th, td': {
        // whiteSpace: 'nowrap',
        // paddingLeft: 20
      },
      '& tr:nth-child(even)': {
        backgroundColor: colors.lightContainer,
        '& th:first-child': {
          backgroundColor: 'inherit'
        }
      },
      '& tr th:first-child': {
        paddingLeft: 15,
        paddingRight: 15,
        // position: 'absolute',
        // left: 0,
        height: 46,
        width: 100,
        lineHeight: '45px',
        [theme.breakpoints.down('sm')]: {
          borderRight: `1px solid ${colors.lightViolet}`
        }
      },
      '& tr td:last-child, tr th:last-child': {
        // paddingRight: 44
      },
      '& tr th:not(:first-child)': {
        paddingLeft: 15
      },
      '& tr td:not(:first-child)': {
        paddingLeft: 15
      }
    },
    tableHead: {
      fontWeight: 600,
      fontSize: fontSizes.paragraph2,
      letterSpacing: 0.02
    },
    reportsRow: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      }
    },
    totalRow: {
      color: colors.lightViolet
    },
    row: {
      fontSize: fontSizes.paragraph1
    },
    reportWrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    }
  })
);

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  justify-content: center;
  margin: 0 -30px -30px -30px;
`;

const Reports: React.FC<IProps> = ({ activeStep }) => {
  const classes = useStyles();
  const { dashboardStore } = useStores();
  const [data, setData] = useState<any[]>([]);
  const [report, setReport] = useState<any>([]);
  const [provider, setProvider] = useState<any>(null);
  const [reportLoaded, setReportLoaded] = useState<boolean>(false);

  const downloadReport = (e: any) => {
    e.preventDefault();
    // dashboardStore.downloadReport(data.id);
    const baseUrl = eAxios.defaults.baseURL;
    const token = eAxios.defaults.headers.common['Authorization'].split(' ')[1];
    const a = document.createElement('a');
    a.href = `${baseUrl}/v1/creditReport/${dashboardStore.reportId}/print?access_token=${token}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  };

  useEffect(() => {
    dashboardStore.getLatestScore();
    if (!dashboardStore.reportList) dashboardStore.getReportList();
  }, [dashboardStore]);

  useEffect(() => {
    if (dashboardStore.reportList) {
      setData(dashboardStore.reportList);
      setProvider(dashboardStore.reportList[activeStep].provider);
      //setProvider(dashboardStore.latestScore.providerViews[0].provider);
    }
  }, [dashboardStore.reportList, dashboardStore.latestScore, activeStep]);

  useEffect(() => {
    if (data.length > 0) {
      setReport(Utils._processSummary(data));
      setReportLoaded(true);
    }
  }, [data]);

  return (
    <>
      {!reportLoaded && (
        <div className={classes.loading}>
          <p>Loading your data</p>
        </div>
      )}
      {reportLoaded && (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              margin: '0 -30px',
              alignItems: 'center',
              padding: '0 30px 15px',
              justifyContent: 'flex-end',
              borderBottom: `1px solid ${colors.gray}`
            }}
          >
            <a
              href="#download"
              style={{ cursor: 'pointer' }}
              onClick={downloadReport}
            >
              Download Full Report
            </a>
          </div>
          <Wrapper>
            <div className={classes.tableContainer}>
              <table className={classes.table}>
                <tbody>
                  <tr className={classes.tableHead}>
                    <th>Account&nbsp;Type</th>
                    <th>Total&nbsp;Balance</th>
                    <th>Available</th>
                    <th>Credit&nbsp;Limit</th>
                  </tr>
                  {Object.keys(report[provider].accountsDetail).map(
                    (t: any, i: number) => {
                      return (
                        <tr
                          key={i}
                          className={
                            report[provider].accountsDetail[t].title === 'Total'
                              ? classes.totalRow
                              : classes.row
                          }
                        >
                          <th>{report[provider].accountsDetail[t].title}</th>
                          <td>${report[provider].accountsDetail[t].balance}</td>
                          <td>${report[provider].accountsDetail[t].avail}</td>
                          <td>${report[provider].accountsDetail[t].limit}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default observer(Reports);
