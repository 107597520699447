import api, { sAxios, eAxios } from '@utils/api';
import { removeAccessToken, removeRefreshToken } from '@utils/token';
import endpoints from '@utils/constants/endpoints';
import { IUserIdentityReqDTO, IRegisterUserDTO } from '@interfaces/user';
import { IVerifyCodeReqDTO, ISendNewCodeReqDTO } from '@interfaces/mobile';
import { IQuizVerifyReqDTO } from '@interfaces/quiz';

const RETRY_COUNT = 3;

export const refreshToken = (token: string) =>
  api({
    method: 'GET',
    url: endpoints.refreshToken,
    params: { token }
  });

export const userLogin = (username: string, password: string) =>
  api({
    method: 'POST',
    url: endpoints.login,
    data: { username, password }
  });

export const preauthLogin = (token: string) =>
  api({
    method: 'GET',
    url: endpoints.preauthLogin(token)
  });

export const registerUser = (data: IRegisterUserDTO) =>
  api({
    url: endpoints.register,
    method: 'POST',
    data: data
  });

export const identityUser = (data: IUserIdentityReqDTO) =>
  sAxios({
    url: endpoints.identity,
    method: 'POST',
    data: data
  });

export const getUser = () => sAxios({ url: endpoints.users });

export const getQuiz = () => sAxios({ url: endpoints.quiz });

export const verifyQuiz = (data: IQuizVerifyReqDTO) =>
  sAxios({
    url: endpoints.verifyQuiz,
    method: 'POST',
    data: data
  });

export const verifyPhone = () => sAxios({ url: endpoints.verifyPhone });

export const verifyDITIdentity = () =>
  sAxios({
    method: 'GET',
    url: endpoints.verifyDITIdentity
  });

export const sendSMFALink = (token: string) =>
  sAxios({
    method: 'POST',
    url: endpoints.sendSMFALink(token)
  });

export const verifySMFA = (token: string) =>
  sAxios({
    method: 'POST',
    url: endpoints.verifySMFA(token)
  });

export const sendCode = (token: string) =>
  sAxios({ method: 'POST', url: `${endpoints.sendCode}/${token}` });

export const sendNewCode = (data: ISendNewCodeReqDTO) =>
  sAxios({
    url: endpoints.sendNewCode,
    method: 'POST',
    data: data
  });

export const verifyCode = (data: IVerifyCodeReqDTO) =>
  sAxios({
    url: endpoints.verifyCode,
    method: 'POST',
    data: data
  });

export const verifyEnrollment = () =>
  sAxios({
    url: endpoints.verifyEnrollment,
    method: 'POST'
  });

export const actionToken = (username: string, password: string) =>
  sAxios({
    method: 'POST',
    url: endpoints.actionToken,
    data: { username, password }
  });

export const changePassword = (
  username: string,
  oldPassword: string,
  newPassword: string
) =>
  sAxios({
    method: 'POST',
    url: endpoints.changePassword,
    data: { username, oldPassword, newPassword }
  });

export const changeEmail = (
  email: string,
  recoveryAnswer: string,
  token: string
) =>
  sAxios({
    method: 'POST',
    url: endpoints.changeEmail,
    data: { email, recoveryAnswer, token }
  });

export const changeRecoveryQuestion = (
  recoveryQuestion: number,
  recoveryAnswer: string,
  oldRecoveryAnswer: string,
  token: string
) =>
  sAxios({
    method: 'POST',
    url: endpoints.changeRecoveryQuestion,
    data: { recoveryQuestion, recoveryAnswer, oldRecoveryAnswer, token }
  });

export const closeAccount = (recoveryAnswer: string, token: string) =>
  sAxios({
    method: 'POST',
    url: endpoints.closeAccount,
    data: { recoveryAnswer, token }
  });

export const recoverPassword = (email: string) =>
  api({
    method: 'POST',
    url: endpoints.passwordRecovery,
    data: { email }
  });

export const validateResetToken = (token: string) =>
  api({
    method: 'POST',
    url: endpoints.recoveryToken,
    data: { token }
  });

export const resetPassword = (
  token: string,
  recoveryAnswer: string,
  password: string
) =>
  api({
    method: 'POST',
    url: endpoints.passwordReset,
    data: { token, recoveryAnswer, password }
  });

export const updateAlertNotifications = (notifications: {}) =>
  sAxios({
    method: 'POST',
    url: endpoints.updateAlertNotifications,
    data: notifications
  });

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

const entries = <T extends {}>(obj: T): Entries<T> => {
  return Object.entries(obj) as any;
};

const transformRequest = (jsonData = {}) =>
  entries(jsonData)
    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join('&');

export const getDeliveryToken = (count: number = 1) => {
  if (
    eAxios.defaults.headers.common['Authorization'] &&
    eAxios.defaults.baseURL
  )
    return Promise.resolve();

  return sAxios({ url: endpoints.efxConfig }).then(resp => {
    eAxios.defaults.baseURL = resp.data.url;
    return api({
      method: 'POST',
      url: resp.data.url + '/oauth/token',
      data: transformRequest({
        scope: 'delivery',
        grant_type: 'jwt-bearer',
        api_key: resp.data.id,
        client_assertion: resp.data.secret
      })
    })
      .then(response => {
        eAxios.defaults.headers.common['Authorization'] =
          'Bearer ' + response.data.access_token;
      })
      .catch(error => {
        if (!error || error.response.status === 401) {
          removeAccessToken();
          removeRefreshToken();
          // window.location.pathname = '/';
          throw error;
        }
      });
  });
};

export const getScoreLatest = (count: number = 1): any =>
  sAxios({
    method: 'GET',
    url: endpoints.scoreLatest
  }).then(response => response.data);

export const getScoreUp = (
  scoreInc: number = 10,
  timeHorizon: number = 6,
  count: number = 1
): any =>
  sAxios({
    method: 'GET',
    url: endpoints.scoreUp,
    params: {
      scoreInc,
      timeHorizon
    }
  }).then(response => response.data);

export const getScoreHistory = (count: number = 1): any =>
  sAxios({
    method: 'GET',
    url: endpoints.scoreHistory
  }).then(response => response.data);

export const downloadReport = (
  reportId: string | number,
  count: number = 1
): any => {
  if (
    !(
      eAxios.defaults.headers.common['Authorization'] && eAxios.defaults.baseURL
    )
  ) {
    return getDeliveryToken().then(() => {
      return downloadReport(reportId);
    });
  }

  const token = eAxios.defaults.headers.common['Authorization'];

  return eAxios({
    url: endpoints.reportDownload(reportId, token.split(' ')[1]),
    method: 'get'
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return handleEFXError(error, count++, downloadReport);
    });
};

export const getReportSummary = (count: number = 1): any =>
  sAxios({
    method: 'GET',
    url: endpoints.reportSummary
  }).then(response => response.data);

export const getReport = (count: number = 1): any =>
  sAxios({
    method: 'GET',
    url: endpoints.reportLatest
  }).then(response => response.data);

export const getMonitoringAlerts = (count: number = 1): any =>
  sAxios({
    method: 'GET',
    url: endpoints.creditMonitoring
  }).then(response => response.data);

const handleEFXError = (
  error: any,
  count: number,
  fn: Function,
  data?: any
) => {
  if (!error.response || error.response.status === 401) {
    delete eAxios.defaults.headers.common['Authorization'];
    eAxios.defaults.baseURL = undefined;
    if (count++ > RETRY_COUNT) {
      removeAccessToken();
      removeRefreshToken();
      // window.location.pathname = '/';
    }
    return getDeliveryToken().then(() => {
      return data ? fn(count, data) : fn(count);
    });
  }
};
