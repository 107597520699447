import React from 'react';
import styled from 'styled-components';

import colors from '@assets/styles/colors';

import Figure1 from '@assets/images/figure_1.svg';
import Figure2 from '@assets/images/figure_2.svg';
import Figure3 from '@assets/images/figure_3.svg';
import Figure4 from '@assets/images/figure_4.svg';

interface IProps {
  stretch?: boolean;
  hidePrint?: boolean;
  fullHeight?: boolean;
}

const Container = styled.div<{ fullHeight: boolean }>`
  width: 100%;
  ${({ fullHeight }) => (fullHeight ? 'height: 100%' : '')}
  display: flex;
  position: relative;
  background: transparent;
  // background: ${colors.background};
  align-items: center;
`;

const Fig1 = styled.img`
  top: 15%;
  left: 25%;
  position: absolute;

  @media (max-width: 600px) {
    top: 10%;
    left: 5%;
  }
`;

const Fig2 = styled.img`
  top: 15%;
  right: 15%;
  position: absolute;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Fig3 = styled.img`
  left: 25%;
  bottom: 15%;
  position: absolute;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Fig4 = styled.img`
  right: 25%;
  bottom: 15%
  position: absolute;
  
  @media (max-width: 600px) {
    right: 5%;
    bottom: 5%;
  }
`;

const Wrapper = styled.div<{ stretch?: boolean }>`
  margin: 0 auto;
  max-width: ${({ stretch }) => (stretch ? '1000px' : '500px')};
  position: relative;
  background-color: ${colors.white};
  border: 1px solid ${colors.black};

  @media (max-width: 600px) {
    width: 100%;
    border: none;
    height: 100%;
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const ContentWrapper: React.FC<IProps> = ({
  children,
  stretch,
  hidePrint,
  fullHeight
}) => (
  <Container className={hidePrint ? 'hidePrint' : ''} fullHeight={!!fullHeight}>
    <Fig1 src={Figure1} />
    <Fig2 src={Figure2} />
    <Fig3 src={Figure3} />
    <Fig4 src={Figure4} />
    <Wrapper stretch={stretch}>
      <div>{children}</div>
    </Wrapper>
  </Container>
);

export default ContentWrapper;
