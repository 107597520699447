import React, { useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import { Header } from '@assets/styles/commonComponents';

import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';

import LogoSrc from '@assets/images/logo.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: 80,
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    submit: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginBottom: 20,
      marginTop: 100
    },
    disabled: {
      backgroundColor: `${theme.palette.secondary.main}30`
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1,
      textAlign: 'left'
    },
    customerColor: {
      color: theme.palette.secondary.main
    },
    linkColor: {
      color: theme.palette.action.active
    }
  })
);

interface IProps {
  SMSMessage: string;
  isDemo: boolean;
  endingNumber: string;
  verifySMFA: any;
  resendLink: Function;
}

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Resend = styled.a`
  display: block;
  text-align: right;
  font-size: ${fontSizes.paragraph2};
`;

const Title = styled.span`
  font-size: ${fontSizes.h1};
`;

const Msg = styled.div`
  display: block;
  border-width: 1px;
  border-color: red !important;
  border-style: solid;
  margin-top: 10px;
  padding: 2px;
  &:hover {
    background-color: #ccc;
  }
`;

const followSmfaLink = async (smsMessage: string) => {
  let idx = smsMessage.indexOf('https');
  let url = '';
  if (idx > -1) {
    url = smsMessage.substring(idx);
    let url_stop = url.indexOf(' ');
    url = url.substring(0, url_stop > 0 ? url_stop : url.length);
    window.open(url);
  }
};

const VerifyLink: React.FC<IProps> = ({
  verifySMFA,
  SMSMessage,
  isDemo,
  endingNumber,
  resendLink
}) => {
  const [isIncomplete, setIncomplete] = useState<boolean>(false);
  const classes = useStyles();
  const { userStore, appStore } = useStores();
  const { verifyLinkInProgress, sendingNewLinkInProgress } = userStore;

  const config = appStore.getConfig();

  return (
    <React.Fragment>
      <Header>
        <Title>{config.mobileIdentityScreenTitleText}</Title>
        <br />
        {appStore.config.hasHeaderLogo && <Logo src={config.logo || LogoSrc} />}
      </Header>
      {/* <Container> */}
      <div className={classes.root}>
        <p className={classes.description}>
          Please open the link sent to your mobile phone number ending in{' '}
          {endingNumber}. Return to this page once verification has completed to
          continue.
        </p>

        {!sendingNewLinkInProgress && (
          <Resend
            className={classes.linkColor}
            href={'#'}
            onClick={() => {
              resendLink();
            }}
          >
            <span>Resend mobile link</span>
          </Resend>
        )}
        {sendingNewLinkInProgress && (
          <Resend className={classes.linkColor}>
            <span>Sending link...</span>
          </Resend>
        )}

        {isDemo && SMSMessage && (
          <Msg onClick={() => followSmfaLink(SMSMessage)}>
            <b>The following message has been sent to your mobile phone:</b>
            <p>{SMSMessage}</p>
          </Msg>
        )}
        <ButtonBase
          disabled={verifyLinkInProgress}
          type="submit"
          classes={{ root: classes.submit, disabled: classes.disabled }}
          onClick={() => {
            const func = async () => {
              setIncomplete(false);
              const rv = await verifySMFA();
              setIncomplete(rv);
            };
            func();
          }}
        >
          CONTINUE
        </ButtonBase>
        <span
          style={{ display: isIncomplete ? 'block' : 'none', color: 'red' }}
        >
          Link not yet validated, please open the secure link on your mobile
          &nbsp;device before clicking continue.
        </span>
      </div>
      {/* </Container> */}
    </React.Fragment>
  );
};

export default observer(VerifyLink);
