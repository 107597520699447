import React from 'react';
import styled from 'styled-components';
import colors from '@assets/styles/colors';

import Alert from '@assets/images/alert.svg';

interface IProps {
  text: string;
}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${colors.supportive};
`;

const NoData: React.FC<IProps> = ({ text }) => {
  return (
    <Container>
      <img src={Alert} alt="" />
      <br />
      <br />
      <div>{text}</div>
    </Container>
  );
};

export default NoData;
