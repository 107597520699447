import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';

import ICreditMonitoring from '@interfaces/creditMonitoring';

interface IProps {
  showMargin?: boolean;
  data: ICreditMonitoring;
  multi: boolean;
}

const Wrapper = styled.div`
  display: flex;
  background: white;
  padding: 15px 25px;
  border-radius: 4px;
  margin-bottom: 10px;
  flex-direction: column;
  font-size: ${fontSizes.paragraph1};
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-items: space-between;

  > div {
    &:first-of-type {
      flex-grow: 1;
    }

    &:last-of-type {
      text-align: right;
    }
  }
`;

const DescriptionRow = styled.div`
  margin-top: 10px;
  border-top: 1px solid ${colors.gray};
`;

const Description = styled.p`
  margin-bottom: 0;
  color: ${colors.supportive};
`;

const Date = styled.span`
  font-weight: 300;
  color: ${colors.footer};
`;

const Spacing = styled.div`
  width: 30px;
  height: 1px;
  display: inline-block;
`;

const Monitoring: React.FC<IProps> = ({ data, multi, showMargin }) => {
  const { formatMessage: t } = useIntl();
  const prefix = !multi
    ? `${t({
        id: `providers.${data.provider}`
      })}: `
    : '';

  return (
    <Wrapper>
      <TitleRow>
        <div style={{ fontWeight: showMargin ? 'bold' : 'normal' }}>
          {prefix}
          {showMargin && <Spacing />}
          {data.name}
        </div>
        <div>
          {data.dateReported && (
            <Date>{moment(data.dateReported).format('MM/DD/YYYY')}</Date>
          )}
          {!data.dateReported && <Date>N/A</Date>}
        </div>
      </TitleRow>
      {data.description && (
        <DescriptionRow>
          <Description>{data.description}</Description>
        </DescriptionRow>
      )}
    </Wrapper>
  );
};

Monitoring.defaultProps = {
  showMargin: false
};

export default Monitoring;
