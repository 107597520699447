import React from 'react';
import styled from 'styled-components';

import { globalStyles } from '@assets/styles/globalStyles';

import ContentWrapper from '@components/ContentWrapper';

import Exclamation from '@assets/icons/exclamation';

interface IProps {
  eventId?: string | null;
}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.div`
  display: flex;
  margin: auto;
  margin-top: -20px;
  justify-content: center;
`;

const SystemError: React.FC<IProps> = ({ eventId }) => {
  const gs = globalStyles();

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <Logo>
          <Exclamation />
        </Logo>
        <h2>System Unavailable</h2>
        <p>
          There appears to be some trouble <br />
          connecting to our network.
        </p>
        {eventId ? (
          <p>
            Please try again later or contact our support team with event ID:{' '}
            {eventId}
          </p>
        ) : (
          <p>Please try again later.</p>
        )}
      </Wrapper>
    </ContentWrapper>
  );
};

export default SystemError;
