import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link as L } from 'react-router-dom';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import ContentWrapper from '@components/ContentWrapper';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      padding: '60px 80px',
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 250px)',
        padding: '15px 25px 15px 15px'
      }
    },
    items: {
      [theme.breakpoints.down('md')]: {
        height: 'calc(100% - 150px)'
      }
    },
    customerColor: {
      color: theme.palette.secondary.main
    }
  })
);

const Wrapper = styled.div`
  display: flex;
  text-align: left;
  position: relative;
  flex-direction: column;
`;

const SettingsItems = styled.div`
  display: block;
  margin-top: 50px;
  min-height: 200px;
`;

const Link = styled(L)`
  width: 100%;
  display: block;
  margin-top: 15px;
  font-size: ${fontSizes.paragraph2};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 5px;
  justify-content: space-between;
  border-top: 1px solid ${colors.gray};

  > :last-of-type {
    text-align: right;
  }
`;

const Settings: React.FC<IProps> = () => {
  const classes = useStyles();
  const {
    userStore: { getUser }
  } = useStores();

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <ContentWrapper>
      <Wrapper className={classes.wrapper}>
        <h1>Settings</h1>
        <SettingsItems className={classes.items}>
          <Link className={classes.customerColor} to={'/alert-notifications'}>
            Alert notifications
          </Link>
          <Link className={classes.customerColor} to={'/change-password'}>
            Change your password
          </Link>
          <Link
            className={classes.customerColor}
            to={'/change-recovery-question'}
          >
            Change your recovery question
          </Link>
          <Link className={classes.customerColor} to={'/change-email'}>
            Change your email
          </Link>
          <Link className={classes.customerColor} to={'/close-account'}>
            Close account
          </Link>
        </SettingsItems>
      </Wrapper>
      <Wrapper
        className={classes.wrapper}
        style={{ paddingTop: 10, flexGrow: 1 }}
      >
        <Footer>
          <Link className={classes.customerColor} to={'/terms-of-use'}>
            Terms of Use
          </Link>
          <Link className={classes.customerColor} to={'/privacy-policy'}>
            Privacy Policy
          </Link>
        </Footer>
      </Wrapper>
    </ContentWrapper>
  );
};

export default Settings;
