import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.action.active
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.customerColor}
        d="M9 0V11.2667"
        strokeMiterlimit="10"
      />
      <path
        className={classes.customerColor}
        d="M4.73438 7L9.00104 11.2667L13.2677 7"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        className={classes.customerColor}
        d="M1 15H17"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
