import styled from 'styled-components';
import fontSizes from '@assets/styles/fontSizes';
import moment from 'moment';
import React from 'react';

export const Card = styled.div`
  height: 100%;
  padding: 42px 44px 37px 44px;
  background: white;
  border-radius: 4px;
  border: 1px solid #dadfe3;
  margin-bottom: 15px;
`;

export const ColorCard = styled.div<{ borderColor: string }>`
  height: 100%;
  padding-left: 10px;
  margin-top: 15px;
  font-weight: 600;
  font-size: ${fontSizes.paragraph2};
  border-left: 5px solid ${props => props.borderColor};
`;

export const CardSubtitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
`;

export const Badge = styled.span<{ color: string }>`
  font-size: 0.5em;
  color: ${props => props.color};
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 4px 6px;
  background-color: #f9f9f9;
  display: inline-block;
  margin-left: 8px;
`;

export const cardContent = (
  type: string,
  statsLoaded: boolean,
  scoreUpStats: any
) => {
  switch (type) {
    case 'payment':
      return (
        <>
          <CardSubtitle>Your Payment Activity:</CardSubtitle>
          {!statsLoaded ? (
            <p>Loading</p>
          ) : (
            <div>
              {!!scoreUpStats?.latePaymentsCount ? (
                <div>
                  {scoreUpStats?.latePaymentsCount} Late Payment
                  {scoreUpStats?.latePaymentsCount > 1 ? 's' : ''}. Most recent:
                  {moment(scoreUpStats?.mostRecentLatePayment?.date).format(
                    ' MMMM Y'
                  )}
                </div>
              ) : (
                <div>
                  No Late Payments found on your Credit Report. Great Job!
                </div>
              )}
              {!!scoreUpStats?.collectionsCount ? (
                <div>
                  {scoreUpStats?.collectionsCount} Collections found on your
                  report.
                </div>
              ) : (
                <div>
                  No collections found on your Credit Report. Great Job!
                </div>
              )}
            </div>
          )}
        </>
      );
    case 'utilization':
      return (
        <>
          <CardSubtitle>Your Percentage of Credit Used:</CardSubtitle>
          {!statsLoaded ? (
            <p>Loading</p>
          ) : (
            <div>
              {!!scoreUpStats?.creditUtilization ? (
                <div>
                  Total Credit Utilization: {scoreUpStats?.creditUtilization}%
                </div>
              ) : (
                <div>
                  Looks like you're not using any available Credit at this time.
                </div>
              )}
              {!!scoreUpStats?.revolvingUtilization ? (
                <div>
                  Revolving Account Utilization:{' '}
                  {scoreUpStats?.revolvingUtilization}%
                </div>
              ) : (
                <div>
                  Looks like you're not using any Revolving Credit at this time.
                </div>
              )}
            </div>
          )}
        </>
      );
    case 'debt':
      return (
        <>
          <CardSubtitle>Your Debt and Balances:</CardSubtitle>
          {!statsLoaded ? (
            <p>Loading</p>
          ) : (
            <div>
              <div>
                Current Balances across all Open Accounts: $
                {scoreUpStats?.totalOpenBalance?.toLocaleString()}
              </div>
              <div>
                Revolving Account Balances: $
                {scoreUpStats?.revolvingBalance?.toLocaleString()}
              </div>
            </div>
          )}
        </>
      );
    case 'activity':
      return (
        <>
          <CardSubtitle>Your Recent Credit Activity:</CardSubtitle>
          {!statsLoaded ? (
            <p>Loading</p>
          ) : (
            <div>
              <div>Open Accounts: {scoreUpStats?.openAccounts}</div>
              <div>
                New Accounts Opened in Past 2 Years: {scoreUpStats?.newAccounts}
              </div>
            </div>
          )}
        </>
      );
    default:
      return null;
  }
};
