import React, { useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import fontSizes from '@assets/styles/fontSizes';

import ContentWrapper from '@components/ContentWrapper';
import { Header } from '@assets/styles/commonComponents';

import { Route } from '@utils/enums/routes';

import LogoSrc from '@assets/images/logo.svg';

interface IProps {
  redirected?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    submit: {
      marginTop: 100
    },
    signUp: {
      marginTop: 10,
      textAlign: 'center',
      fontSize: fontSizes.h5
    },
    customerColor: {
      color: theme.palette.secondary.main
    },
    title: {
      fontWeight: 'bold',
      fontSize: fontSizes.h1,
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizes.h2
      }
    },
    form: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflowY: 'auto'
    }
  })
);

const Wrapper = styled.div`
  position: relative;
`;

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const ForgotPass = styled(ButtonBase)`
  display: block;
  text-align: right;
  margin-bottom: 2px;
  font-size: ${fontSizes.paragraph};
`;

const SignUp = styled(ButtonBase)`
  margin-bottom: 2px;
  font-size: ${fontSizes.paragraph};
`;

const Error = styled.p`
  color: red;
  text-align: center;
  font-size: ${fontSizes.paragraph2};
`;

const loginSchema = object({
  email: string()
    .required('Email is required.')
    .email('Email is invalid'),
  password: string().required('Password is required.')
});

const Login: React.FC<IProps> = ({ redirected }) => {
  const gs = globalStyles();
  const classes = useStyles();
  const { push } = useHistory();
  const { appStore, userStore, notifyStore } = useStores();
  const [showError, setShowError] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const redirectView = appStore.getRedirectView();
  const config = appStore.getConfig();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ email, password }) => {
      userStore
        .userLogin(email, password)
        .then(res => {
          if (userStore.justEnrolled) {
            push(Route.ID_SUCCESS);
          } else if (!userStore.isIdentityCompleted) {
            push(Route.IDENTITY);
          } else if (userStore.isIdentityCompleted) {
            push(redirectView);
          }
        })
        .catch(error => {
          setShowError(true);
          notifyStore.error('Wrong username/password');
        });
    }
  });

  const handleClickShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  function renderForm() {
    return (
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Wrapper className={gs.wrapper} style={{ paddingBottom: 0 }}>
          <Header>
            <h1 className={classes.title}>Login</h1>
            {appStore.config.hasHeaderLogo && (
              <Logo src={config.logo || LogoSrc} />
            )}
          </Header>
          <div className={classes.root}>
            <TextField
              required
              id="email"
              label="Email"
              color="secondary"
              placeholder="Enter your email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
            <TextField
              required
              id="password"
              label="Password"
              color="secondary"
              placeholder="Enter your password"
              type={passwordVisible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disableRipple
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={!!formik.errors.password}
              helperText={formik.errors.password}
            />
            <ForgotPass
              className={classes.customerColor}
              onClick={() => {
                redirected
                  ? appStore.setPage('PasswordRecovery')
                  : push(Route.PASSWORD_RECOVERY);
              }}
            >
              Forgot password
            </ForgotPass>
          </div>
        </Wrapper>
        <Wrapper className={gs.wrapper} style={{ paddingTop: 0 }}>
          {showError && <Error>Wrong username/password</Error>}
          <ButtonBase classes={{ root: gs.button }} type="submit">
            Login
          </ButtonBase>
          <p className={classes.signUp}>
            Don’t have an account?{' '}
            <SignUp
              className={classes.customerColor}
              onClick={() => {
                redirected ? appStore.setPage('Register') : push(Route.SIGNUP);
              }}
            >
              Sign Up
            </SignUp>
          </p>
        </Wrapper>
      </form>
    );
  }

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!appStore.config.popupMode}
      hasBackgroundImg={appStore.config.hasBackgroundImg}
    >
      {renderForm()}
    </ContentWrapper>
  );
};

Login.defaultProps = {
  redirected: false
};

export default Login;
