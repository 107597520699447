export default {
  black: '#000000',
  white: '#ffffff',
  background: '#E2ECF3',
  linearGradient: '#F6F9FF',
  lightBackground: '#EEF1F8',
  lightGreen: '#55D4A9',
  lightViolet: '#C873A9',
  lightPink: '#EF4D7E',
  lightBlack: '#211F1F',
  lightVioletDisabled: '#FAC2D3',
  lightContainer: '#F7F9FC',
  lightCyan: '#53AAC7',
  gray: '#ECEDF0',
  violet: '#6B2F73',
  supportive: '#90929C',
  footer: '#6B6F75',
  lightInputText: '#C7CCD4'
};
