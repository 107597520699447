import { IRegisterUserDTO, IUserIdentityReqDTO } from '@interfaces/user';
import { action, observable, decorate } from 'mobx';
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';

interface INotificationObject {
  message: SnackbarMessage;
  options?: OptionsObject;
}

interface INotificationSnack extends INotificationObject {
  key: SnackbarKey;
}

export class NotifyStore {
  public notifications: INotificationSnack[] = [];
  public identityData: IUserIdentityReqDTO = {
    fname: '',
    lname: '',
    ssn: '',
    dob: '',
    mobile: '',
    street1: '',
    street2: '',
    state: '',
    city: '',
    zip: '',
    immutable: false
  };
  public registrationData: IRegisterUserDTO = {
    email: '',
    password: '',
    recoveryQuestion: 0,
    recoveryAnswer: ''
  };

  public setIdentityData = (idData: IUserIdentityReqDTO) => {
    this.identityData = {
      ...idData,
      ssn: idData.ssn || '',
      dob: idData.dob || '',
      fname: idData.fname || '',
      lname: idData.lname || '',
      mobile: idData.mobile || '',
      street1: idData.street1 || '',
      street2: idData.street2 || '',
      state: idData.state || '',
      city: idData.city || '',
      zip: idData.zip || ''
    };
  };

  public setRegistrationData = (regData: IRegisterUserDTO) => {
    this.registrationData = {
      ...regData,
      password: '',
      recoveryQuestion: 0,
      recoveryAnswer: ''
    };
  };

  public snack = (notificationObject: INotificationObject) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      ...notificationObject
    });
  };

  public error = (message: string) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: 'error'
      }
    });
  };

  public info = (message: string) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: 'info'
      }
    });
  };

  public success = (message: string) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random(),
      message: message,
      options: {
        variant: 'success'
      }
    });
  };

  public removeSnackbar = (notificationKey: SnackbarKey) => {
    this.notifications = this.notifications.filter(
      (snack: INotificationSnack) => snack.key !== notificationKey
    );
  };
}

decorate(NotifyStore, {
  notifications: observable,
  identityData: observable,
  registrationData: observable,
  setRegistrationData: action,
  setIdentityData: action,
  snack: action,
  error: action,
  info: action,
  success: action,
  removeSnackbar: action
});
