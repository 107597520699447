import React from 'react';
import styled from 'styled-components';
import Animation from '@utils/helpers/Animation';
import { easeQuadInOut } from 'd3-ease';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

interface IProps {
  data: any | any[];
}

const Wrapper = styled.div`
  width: 256px;
  height: 256px;
  margin: 10px 37px;
  position: relative;
`;

const Score = styled.div<{ score: number }>`
  top: 50%;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  color: ${({ score }) =>
    score > 300 ? colors.lightGreen : colors.lightInputText};

  > p {
    margin: 0;
    font-size: 22px;
  }
  > span {
    margin: 0;
    font-size: 42px;
  }
`;

const ScoreBoard = styled.div<{ score: number }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  .v {
    font-weight: bold;
    color: ${colors.lightInputText};
    font-size: ${fontSizes.paragraph1};
  }

  .v_280 {
    left: -5px;
    bottom: 19px;
    position: absolute;
    color: ${({ score }) =>
      score >= 280 ? colors.lightGreen : colors.lightInputText};
  }
  .v_370 {
    left: -36px;
    bottom: 91px;
    position: absolute;
    color: ${({ score }) =>
      score >= 370 ? colors.lightGreen : colors.lightInputText};
  }
  .v_460 {
    left: -21px;
    bottom: 191px;
    position: absolute;
    color: ${({ score }) =>
      score >= 460 ? colors.lightGreen : colors.lightInputText};
  }
  .v_550 {
    left: calc(50% - 15px);
    top: -25px;
    position: absolute;
    color: ${({ score }) =>
      score >= 550 ? colors.lightGreen : colors.lightInputText};
  }
  .v_640 {
    right: -21px;
    bottom: 191px;
    position: absolute;
    color: ${({ score }) =>
      score >= 640 ? colors.lightGreen : colors.lightInputText};
  }
  .v_730 {
    right: -36px;
    bottom: 91px;
    position: absolute;
    color: ${({ score }) =>
      score >= 730 ? colors.lightGreen : colors.lightInputText};
  }
  .v_850 {
    right: -5px;
    bottom: 19px;
    position: absolute;
    color: ${({ score }) =>
      score >= 850 ? colors.lightGreen : colors.lightInputText};
  }
`;

const RadialChart: React.FC<IProps> = ({ data }: any) => {
  const score = data.score;
  const text = data.rating;

  return (
    <Wrapper>
      <Animation
        valueStart={0}
        valueEnd={score}
        duration={1}
        repeat={false}
        easingFunction={easeQuadInOut}
      >
        {(data: any) => {
          return (
            <React.Fragment>
              <CircularProgressbar
                value={data.value}
                minValue={280}
                maxValue={850}
                strokeWidth={6}
                circleRatio={0.81}
                styles={buildStyles({
                  strokeLinecap: 'round',
                  pathTransition: 'true',
                  rotation: 1 / 2 + 1 / 10.5,
                  pathTransitionDuration: 3000,
                  trailColor: colors.lightInputText,
                  pathColor: colors.lightGreen
                })}
              />
              <Score score={data.value}>
                <span>{Math.round(data.value)}</span>
                <p
                  style={{
                    opacity: score === data.value ? 1 : 0
                  }}
                >
                  {text}
                </p>
              </Score>
              <ScoreBoard score={data.value}>
                <span className="v v_280">280</span>
                <span className="v v_370">370</span>
                <span className="v v_460">460</span>
                <span className="v v_550">550</span>
                <span className="v v_640">640</span>
                <span className="v v_730">730</span>
                <span className="v v_850">850</span>
              </ScoreBoard>
            </React.Fragment>
          );
        }}
      </Animation>
    </Wrapper>
  );
};

export default RadialChart;
