import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.customerColor}
        d="M28 126.758L80 33.5171L132 126.758H28Z"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        className={classes.customerColor}
        d="M80 63.9995V98.0685"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        className={classes.customerColor}
        d="M80.0001 112.413C80.9904 112.413 81.7932 111.611 81.7932 110.62C81.7932 109.63 80.9904 108.827 80.0001 108.827C79.0098 108.827 78.207 109.63 78.207 110.62C78.207 111.611 79.0098 112.413 80.0001 112.413Z"
        fill="#EF4D7E"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
