import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';

import Loading from '@components/Loading';
import Listener from '@utils/helpers/msgListener';

import { Route } from '@utils/enums/routes';
import { Events } from '@utils/enums/events';

const Wrapper = styled.div`
  position: relative;
`;

const Login: React.FC = () => {
  const gs = globalStyles();
  const { push } = useHistory();
  const { userStore, appStore } = useStores();

  let token: string | null = null;
  let key: string | null = null;
  let ocf: number | null = null;
  let ocl: string | null = null;
  let oct: string | null = null;
  //const token = document.location.search.split('&')[0].split('=')[1];

  if (document.location.search.length > 1) {
    const query = document.location.search.substring(1).split('&');
    if (query.length > 0)
      query.forEach(elem => {
        const prop = elem.split('=');
        if (prop[0] === 'token') token = prop[1];
        if (prop[0] === 'key') key = prop[1];
        if (prop[0] === 'ocf') ocf = +prop[1];
        if (prop[0] === 'ocl') ocl = prop[1];
        if (prop[0] === 'oct') oct = `#${prop[1].slice(prop[1].length - 6)}`; // prop[1];
        //if (prop[0] === 'oba') oba = true;
      });
  }

  useEffect(() => {
    if (key) appStore.setKey(key);
    appStore.setIsDirectUser(true);

    if (ocf) appStore.overrideConfigFlags(ocf);
    if (ocl) appStore.overrideConfigLogo(ocl);
    if (oct) appStore.overrideConfigTheme(oct);
    appStore.setView(Route.DASHBOARD_SCORE);
    // @ts-ignore
    if (token) window.apiToken = token;

    if (token) {
      userStore
        .preauthLogin(token)
        .then((res: any) => {
          if (res.idpass) {
            push(Route.TILE_VIEW);
          } else {
            push(Route.IDENTITY);
          }
        })
        .catch(() => {
          window.location.href = Route.SYS_ERROR;
        });
    } else {
      window.parent.postMessage({ type: Events.AUTH_REQUIRED }, '*');
    }
  }, [appStore, userStore, token, key, ocf, ocl, oct, push]);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Wrapper className={gs.wrapper} style={{ height: '100%' }}>
        {!token && <Listener redirect={Route.TILE_VIEW} />}
        <Loading />
      </Wrapper>
    </div>
  );
};

export default Login;
