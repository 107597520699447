export enum Events {
  INIT_CONFIG = 'INIT_CONFIG',
  WIDGET_CONFIGS = 'WIDGET_CONFIGS',
  REG_STARTED = 'REG_STARTED',
  IDENTITY_STARTED = 'IDENTITY_STARTED',
  LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
  LOGIN_FAILED = 'LOGIN_FAILED',
  IP_LOCKOUT = 'IP_LOCKOUT',
  USER_LOCKOUT = 'USER_LOCKOUT',
  USER_ENROLLED = 'USER_ENROLLED',
  IDENTITY_FAILED = 'IDENTITY_FAILED',
  AUTH_REQUIRED = 'AUTH_REQUIRED',
  AUTH = 'AUTH',
  PREAUTH = 'PREAUTH',
  REG = 'REG',
  IDENTITY = 'IDENTITY',
  SERVICE_FAILURE = 'SERVICE_FAILURE',
  UNKNOWN_USER = 'UNKNOWN_USER',
  USER_ALREADY_REGISTERED = 'USER_ALREADY_REGISTERED',
  HOST_TOKEN_EXPIRED = 'HOST_TOKEN_EXPIRED',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
  ACCOUNT_LOCKED_TEMP = 'ACCOUNT_LOCKED_TEMP',
  IP_LOCKED_TEMP = 'IP_LOCKED_TEMP',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  USER_ALREADY_IDENTIFIED = 'USER_ALREADY_IDENTIFIED',
  USER_IDENTITY_REQUIRED = 'USER_IDENTITY_REQUIRED',
  OTP_FAILED = 'OTP_FAILED',
  ENROLLMENT_FAILED = 'ENROLLMENT_FAILED',
  USER_ENROLLMENT_LIMIT_EXCEEDED = 'USER_ENROLLMENT_LIMIT_EXCEEDED',
  VS3_UNAVAILABLE = 'VS3_UNAVAILABLE',
  IDFS_USER_LOCKED_WAIT_72_HOURS = 'IDFS_USER_LOCKED_WAIT_72_HOURS',
  IDFS_REQUEST_ALREADY_COMPLETED = 'IDFS_REQUEST_ALREADY_COMPLETED',
  USER_EXCEPTION = 'USER_EXCEPTION',
  CUSTOMER_HOST_EXCEPTION = 'CUSTOMER_HOST_EXCEPTION',
  PREAUTH_TOKEN_EXPIRED = 'PREAUTH_TOKEN_EXPIRED',
  PHONE_NUMBER_REQUIRED = 'PHONE_NUMBER_REQUIRED',
  SCORE_FACTORS_LINK_TRIGGERED = 'SCORE_FACTORS_LINK_TRIGGERED',
  CLOSE = 'CLOSE',
  IDENTITY_COMPLETED_REDIRECT = 'IDENTITY_COMPLETED_REDIRECT',
  IDENTITY_FAILED_CLOSE_BTN_CLICK = 'IDENTITY_FAILED_CLOSE_BTN_CLICK',
  DOWNLOAD_PDF_CREDIT_REPORT_LINK_TRIGGERED = 'DOWNLOAD_PDF_CREDIT_REPORT_LINK_TRIGGERED',
  IDENTITY_COMPLETED = 'IDENTITY_COMPLETED',
  THIN_FILE_ERROR = 'THIN_FILE_ERROR',
  ACCREGFAIL_ACK = 'ACCREGFAIL_ACK'
}
