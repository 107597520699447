import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@utils/hooks/useStores';
import SwipeableViews from 'react-swipeable-views';

import Loading from '@components/Loading';
import ScoreUpPage from '@components/ScoreUp';
import Container from '@components/Container';
import PageSwipeHeader from '@components/PageSwipeHeader';

import ILatestCreditScore from '@interfaces/latestCreditScore';

const ScoreUp: React.FC = () => {
  const {
    dashboardStore,
    userStore: { getUser }
  } = useStores();
  const [pages, setPages] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    dashboardStore.getLatestScore();
    dashboardStore.getScoreUp();
    if (!dashboardStore.report) dashboardStore.getReport();
  }, [dashboardStore]);

  useEffect(() => {
    if (
      dashboardStore.latestScore &&
      dashboardStore.latestScore.providerViews
    ) {
      setPages(
        dashboardStore.latestScore.providerViews
          // @ts-ignore
          .map((d: ILatestCreditScore) => d.provider)
          .filter(
            (item: ILatestCreditScore, i: number, ar: any) =>
              ar.indexOf(item) === i
          )
      );

      setData(
        dashboardStore.latestScore.providerViews.filter(
          (item: ILatestCreditScore) =>
            // @ts-ignore
            item.provider === pages[activeStep]
        )
      );
    }
    // TODO: Change pages to useReducer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardStore.latestScore, pages.length, setData, activeStep]);

  useEffect(() => {
    if (dashboardStore.latestScoreLoaded && dashboardStore.scoreUpLoaded) {
      setIsDataLoaded(true);
    }
  }, [dashboardStore.latestScoreLoaded, dashboardStore.scoreUpLoaded]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Container>
      {isDataLoaded && (
        <React.Fragment>
          <PageSwipeHeader
            back={handleBack}
            next={handleNext}
            activeIndex={activeStep}
            pages={pages.length}
            title={pages[activeStep]}
          />
          <SwipeableViews
            disabled={true}
            enableMouseEvents
            index={activeStep}
            onChangeIndex={handleStepChange}
          >
            {pages.map((page, index) => (
              <React.Fragment key={index}>
                {data.map((d: any, i: number) => (
                  <ScoreUpPage key={i} />
                ))}
              </React.Fragment>
            ))}
          </SwipeableViews>
        </React.Fragment>
      )}
      {!isDataLoaded && <Loading />}
    </Container>
  );
};

export default observer(ScoreUp);
