import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { createStyles, makeStyles } from '@material-ui/core';
import { useStores } from '@utils/hooks/useStores';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import ContentWrapper from '@components/ContentWrapper';

import LogoSrc from '@assets/images/logo.svg';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      width: 500,
      [theme.breakpoints.down('md')]: {
        width: 300
      }
    },
    contentWrapper: {
      padding: '25px 40px',
      [theme.breakpoints.down('md')]: {
        padding: '15px'
      }
    },
    customerColor: {
      color: theme.palette.secondary.main
    }
  })
);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Link = styled.a`
  width: 100%;
  text-align: center;
  font-size: ${fontSizes.paragraph1};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray};

  > :last-of-type {
    text-align: right;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0;
  justify-items: center;
  border-top: 1px solid ${colors.gray};
`;

const Iframe = styled.iframe`
  width: 100%;
  border: none;
  height: 400px;
  overflow: hidden;
`;

const PrintIframe = styled.iframe`
  width: 100%;
  border: none;
  height: 6000px;
`;

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const PrivacyPolicy: React.FC<IProps> = () => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { appStore } = useStores();
  const config = appStore.getConfig();

  const handleGoBack = (e: any) => {
    e.preventDefault();
    goBack();
  };

  return (
    <React.Fragment>
      <ContentWrapper
        hidePrint={true}
        hasWrapper={!appStore.config.popupMode}
        hasBackgroundImg={appStore.config.hasBackgroundImg}
      >
        <Wrapper className={`${classes.wrapper} ${'hidePrint'}`}>
          <Header className={classes.contentWrapper}>
            {appStore.config.hasHeaderLogo && (
              <Logo src={config.logo || LogoSrc} />
            )}
            <Link
              className={classes.customerColor}
              href="#"
              onClick={handleGoBack}
            >
              Back
            </Link>
          </Header>
          <Iframe
            id="pp-iframe"
            name="pp-iframe"
            src="https://app.termly.io/document/privacy-policy/95a109ce-0064-415c-92e2-7d9967e723a2"
          />
          <Footer>
            <Link
              className={classes.customerColor}
              href="#"
              onClick={() => {
                window.print();
              }}
            >
              Download
            </Link>
          </Footer>
        </Wrapper>
      </ContentWrapper>
      <PrintIframe
        id="pp-iframe"
        name="pp-iframe"
        className={'showPrint'}
        src="https://app.termly.io/document/privacy-policy/95a109ce-0064-415c-92e2-7d9967e723a2"
      />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
