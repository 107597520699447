import React from 'react';
import styled from 'styled-components';
import colors from '@assets/styles/colors';

interface IProps {}

const Container = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.supportive};
`;

const Loading: React.FC<IProps> = () => {
  return <Container>Loading your data</Container>;
};

export default Loading;
