import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 18V26L24 34V60"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10 40V48L16 54V60"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M54 40V48L48 54V60"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M48 18V26L40 34V60"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M32 12V60"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16 18C18.2091 18 20 16.2091 20 14C20 11.7909 18.2091 10 16 10C13.7909 10 12 11.7909 12 14C12 16.2091 13.7909 18 16 18Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10 40C12.2091 40 14 38.2091 14 36C14 33.7909 12.2091 32 10 32C7.79086 32 6 33.7909 6 36C6 38.2091 7.79086 40 10 40Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M54 40C56.2091 40 58 38.2091 58 36C58 33.7909 56.2091 32 54 32C51.7909 32 50 33.7909 50 36C50 38.2091 51.7909 40 54 40Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M48 18C50.2091 18 52 16.2091 52 14C52 11.7909 50.2091 10 48 10C45.7909 10 44 11.7909 44 14C44 16.2091 45.7909 18 48 18Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M32 12C34.2091 12 36 10.2091 36 8C36 5.79086 34.2091 4 32 4C29.7909 4 28 5.79086 28 8C28 10.2091 29.7909 12 32 12Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
