import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

interface IModalProps {
  isOpen: boolean;
  closeModal: Function;
}

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: 500
  }
};

const Modal: React.FC<IModalProps> = ({ isOpen, closeModal, children }) => {
  const close = () => {
    closeModal(false);
  };

  useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);

  return (
    <ReactModal isOpen={isOpen} onRequestClose={close} style={customStyles}>
      {children}
    </ReactModal>
  );
};

export default Modal;
