import React from 'react';
import styled from 'styled-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import Failure from '@assets/images/failure.svg';
import { Link as L } from 'react-router-dom';

interface IProps {
  nextStep: Function;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: 'center'
    },
    description: {
      marginTop: '15px',
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 50
    },
    customerColor: {
      color: theme.palette.secondary.main
    }
  })
);

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const CancelButton = styled(L)`
  display: block;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
  font-size: ${fontSizes.paragraph1};
`;

const Information: React.FC<IProps> = ({ nextStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo src={Failure} />
      <h2>Close Account</h2>
      <p className={classes.description}>
        Closing your account is a final action. <br />
        Data from your account will be lost, including <br />
        your credit score history, should you proceed.
      </p>
      <p className={classes.description}>
        You may register again at a later date <br />
        and restart your membership, <br />
        should you choose to do so.
      </p>
      <ButtonBase
        className={classes.button}
        onClick={() => {
          nextStep();
        }}
      >
        Continue
      </ButtonBase>
      <CancelButton className={classes.customerColor} to={'/settings'}>
        Cancel
      </CancelButton>
    </div>
  );
};

export default Information;
