import React from 'react';

interface IProps {
  icon: string;
  color: string;
  isActive: boolean;
}

const Icon: React.FC<IProps> = ({ isActive, color, icon }) => {
  return renderIcon(isActive, color, icon);
};

const renderIcon = (isActive: boolean, color: string, icon: string) => {
  switch (icon) {
    case 'score':
      return renderScoreIcon(isActive, color);
    case 'report':
      return renderReportIcon(isActive, color);
    case 'monitor':
      return renderMonitorIcon(isActive, color);
    case 'restore':
      return renderRestoreIcon(isActive, color);

    default:
      return null;
  }
};

const renderScoreIcon = (isActive: boolean, color: string) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.39209 10.8323L3.04346 9.71633L3.60142 8.54202C5.31655 4.93211 9.0058 2.59998 12.9998 2.59998C16.9899 2.59998 20.6772 4.92893 22.3936 8.53376L22.9522 9.70744L20.6049 10.8253L20.0463 9.65095C18.759 6.94749 15.9927 5.19998 12.9998 5.19998C10.0037 5.19998 7.23671 6.95002 5.95005 9.65794L5.39209 10.8323Z"
        fill={isActive ? color : '#211F1F'}
      />
      <path
        d="M9.26781 20.6319C8.64257 18.5707 9.98102 9.54761 9.98102 9.54761C9.98102 9.54761 16.1067 16.3066 16.732 18.3677C17.3572 20.4289 16.1931 22.6067 14.132 23.2319C12.0708 23.8571 9.89304 22.6931 9.26781 20.6319Z"
        fill={isActive ? color : '#211F1F'}
      />
    </svg>
  );
};

const renderReportIcon = (isActive: boolean, color: string) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1 2.60121H3.89998C3.51422 2.60121 3.35104 2.60091 3.20601 2.60064C3.05778 2.60037 2.92851 2.60013 2.59998 2.60028V23.4C3.24998 23.4 3.24998 23.4003 3.24998 23.4006C3.24998 23.4009 3.24998 23.4012 3.89998 23.4012H22.1C22.88 23.4012 23.4 23.4 23.4 23.4V2.59998C23.0898 2.59998 22.9572 2.60026 22.8186 2.60055C22.6668 2.60087 22.5077 2.60121 22.1 2.60121ZM18.2 9.09997H7.79998V11.7H18.2V9.09997ZM7.79998 14.3H18.2V16.9H7.79998V14.3Z"
        fill={isActive ? color : '#211F1F'}
      />
    </svg>
  );
};

const renderMonitorIcon = (isActive: boolean, color: string) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1951 18.2C22.1951 18.2 19.7765 16.1442 19.7765 13.2418V9.37207C19.7765 7.576 19.063 5.85349 17.793 4.58348C16.523 3.31346 14.8005 2.59998 13.0044 2.59998C11.2083 2.59998 9.48584 3.31346 8.21583 4.58348C6.94581 5.85349 6.23233 7.576 6.23233 9.37207V13.2418C6.23233 16.1442 3.81372 18.2 3.81372 18.2H22.1951Z"
        fill={isActive ? color : '#211F1F'}
      />
      <path
        d="M15.4236 20.7983C15.4236 21.665 15.1683 22.2378 14.7147 22.6914C14.2611 23.1449 13.6459 23.3998 13.0045 23.3998C12.363 23.3998 11.7478 23.1449 11.2943 22.6914C10.8407 22.2378 10.5864 21.665 10.5864 20.7983"
        fill={isActive ? color : '#211F1F'}
      />
    </svg>
  );
};

const renderRestoreIcon = (isActive: boolean, color: string) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.764 4.35288C17.0581 3.21006 15.0524 2.60004 13.0007 2.59998L13.0006 5.0961C14.5599 5.09615 16.0842 5.55976 17.3807 6.4283C18.6772 7.29685 19.6877 8.53133 20.2844 9.97564C20.8812 11.4199 21.0373 13.0092 20.7331 14.5425C20.5106 15.6641 20.049 16.7189 19.3856 17.6372L17.1408 15.3867L16.0975 22.7084L23.4006 21.6625L21.163 19.4192C22.1645 18.1391 22.8562 16.6364 23.175 15.0294C23.5753 13.012 23.3698 10.9208 22.5847 9.02042C21.7995 7.12002 20.4699 5.49571 18.764 4.35288Z"
        fill={isActive ? color : '#211F1F'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85975 10.6157L9.90304 3.29395L2.59998 4.33991L4.83803 6.58369C3.83709 7.86349 3.14572 9.36579 2.827 10.9723C2.42675 12.9897 2.63219 15.0808 3.41734 16.9813C4.2025 18.8817 5.5321 20.506 7.23803 21.6488C8.94397 22.7916 10.9496 23.4016 13.0013 23.4017L13.0014 20.9056C11.4421 20.9055 9.91781 20.4419 8.6213 19.5734C7.3248 18.7048 6.3143 17.4703 5.71758 16.026C5.12086 14.5817 4.96473 12.9925 5.26892 11.4592C5.49135 10.3381 5.95259 9.28371 6.61552 8.36572L8.85975 10.6157Z"
        fill={isActive ? color : '#211F1F'}
      />
    </svg>
  );
};

export default Icon;
