import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3333 56.0001C14.6053 56.0001 2.66663 44.0614 2.66663 29.3334C2.66663 14.6054 14.6053 2.66678 29.3333 2.66678C40.2693 2.66678 49.6666 9.24945 53.7813 18.6681"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M29.3333 16V29.3333H16"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M49.3332 37.3334C55.9607 37.3334 61.3332 34.9456 61.3332 32.0001C61.3332 29.0546 55.9607 26.6668 49.3332 26.6668C42.7058 26.6668 37.3333 29.0546 37.3333 32.0001C37.3333 34.9456 42.7058 37.3334 49.3332 37.3334Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M37.3333 32V40C37.3333 42.9453 42.7052 45.3333 49.3332 45.3333C55.9612 45.3333 61.3332 42.9453 61.3332 40V32"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M37.3333 40V48C37.3333 50.9453 42.7052 53.3333 49.3332 53.3333C55.9612 53.3333 61.3332 50.9453 61.3332 48V40"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M37.3333 48V56C37.3333 58.9453 42.7052 61.3333 49.3332 61.3333C55.9612 61.3333 61.3332 58.9453 61.3332 56V48"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Icon;
