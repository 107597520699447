import React from 'react';
import styled from 'styled-components';

import { createStyles, makeStyles } from '@material-ui/core';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    responsive: {
      margin: '0 auto',
      padding: '0 15px',
      [theme.breakpoints.up('lg')]: {
        width: 1170
      },
      [theme.breakpoints.up('md')]: {
        width: 975
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 30px)'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  })
);

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const Container: React.FC<IProps> = ({ children }) => {
  const classes = useStyles();

  return <Wrapper className={classes.responsive}>{children}</Wrapper>;
};

export default Container;
