import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { AppBar, Toolbar, IconButton, Hidden, Button } from '@material-ui/core';

import { NavLink } from './Navigation/NavLink';
import { ApiLoader } from '@utils/helpers/apiLoader';
import ButtonNavigation from './Navigation/ButtonNavigation';
import DrawerNavigation from './Navigation/DrawerNavigation';

import CheckPermission, { checkFlag } from '@utils/helpers/checkPermission';

import { Route } from '@utils/enums/routes';
import Flags from '@utils/constants/flags';

import Logo from '@assets/images/logo.svg';
import PoweredBy from '@assets/images/powered-by.svg';

interface IPrivateLayoutProps {}

const StitchLogo = styled.img`
  height: 32px;
`;

const LogoContainer = styled.div`
  flex-grow: 1;
`;

const useStyles = makeStyles(theme => ({
  children: {
    paddingTop: 90,
    display: 'flex',
    minHeight: 'calc(100% - 90px - 50px)',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      minHeight: 'calc(100% - 90px - 75px)'
    }
  },
  appbar: {
    height: 90,
    padding: '0 120px',
    [theme.breakpoints.down('md')]: {
      padding: '0 32px'
    }
  },
  zIndex: {
    zIndex: 1301
  },
  title: {
    flexGrow: 1
  },
  activeItem: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 'bold'
  },
  unsupportedCookies: {
    height: 40,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    background: 'orange'
  },
  poweredBy: {
    height: 50,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 75
    }
  }
}));

const PrivateLayout: React.FC<IPrivateLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { appStore } = useStores();
  const { formatMessage: t } = useIntl();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  let url = useLocation();

  const signOut = () => {
    appStore.logout();
    push(Route.LOGIN);
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const LogoSrc = appStore.getConfig().logo || Logo;
  let hasPermissionToAccessLocalStorage;

  try {
    localStorage.getItem('accessToken');
    hasPermissionToAccessLocalStorage = true;
  } catch (error) {
    hasPermissionToAccessLocalStorage = false;
  }

  return (
    <>
      <AppBar elevation={0} className={`${classes.zIndex} hidePrint`}>
        {!hasPermissionToAccessLocalStorage && (
          <div className={classes.unsupportedCookies}>
            {' '}
            For the better experience please allow third-party cookies and site
            data in your browser settings or don't use incognito mode.{' '}
          </div>
        )}
        <Toolbar className={classes.appbar}>
          <LogoContainer>
            {appStore.config.hasHeaderLogo && <StitchLogo src={LogoSrc} />}
          </LogoContainer>

          <Hidden smDown>
            {checkFlag(appStore.getConfig().flags, Flags.SCORE) && (
              <NavLink
                className={
                  url.pathname === `/${Route.DASHBOARD_SCORE}`
                    ? classes.activeItem
                    : ''
                }
                to={`/${Route.DASHBOARD_SCORE}`}
              >
                {t({ id: 'common.score' })}
              </NavLink>
            )}
            {checkFlag(appStore.getConfig().flags, Flags.SCORE_UP) && (
              <NavLink
                className={
                  url.pathname === `/${Route.DASHBOARD_SCORE_UP}`
                    ? classes.activeItem
                    : ''
                }
                to={`/${Route.DASHBOARD_SCORE_UP}`}
              >
                {t({ id: 'common.score_up' })}
              </NavLink>
            )}
            {checkFlag(appStore.getConfig().flags, Flags.REPORT) && (
              <NavLink
                className={
                  url.pathname === `/${Route.DASHBOARD_REPORT}`
                    ? classes.activeItem
                    : ''
                }
                to={`/${Route.DASHBOARD_REPORT}`}
              >
                {t({ id: 'common.reports' })}
              </NavLink>
            )}
            {checkFlag(appStore.getConfig().flags, Flags.ALERTS) && (
              <NavLink
                className={
                  url.pathname === `/${Route.DASHBOARD_MONITOR}`
                    ? classes.activeItem
                    : ''
                }
                to={`/${Route.DASHBOARD_MONITOR}`}
              >
                {t({ id: 'common.monitoring' })}
              </NavLink>
            )}
            {checkFlag(appStore.getConfig().flags, Flags.RESTORE) && (
              <NavLink
                className={
                  url.pathname === `/${Route.DASHBOARD_IDRESTORE}`
                    ? classes.activeItem
                    : ''
                }
                to={`/${Route.DASHBOARD_IDRESTORE}`}
              >
                {t({ id: 'common.id_restore' })}
              </NavLink>
            )}
            <CheckPermission>
              <NavLink
                className={`${
                  url.pathname === `/${Route.SETTINGS}`
                    ? classes.activeItem
                    : ''
                }
                  ${
                    url.pathname === `/${Route.ALERT_NOTIFICATIONS}`
                      ? classes.activeItem
                      : ''
                  }`}
                to={`/${Route.SETTINGS}`}
              >
                {t({ id: 'common.settings' })}
              </NavLink>
            </CheckPermission>
            {/*<NavLink*/}
            {/*className={*/}
            {/*url.pathname === `/${Route.ABOUT}` ? classes.activeItem : ''*/}
            {/*}*/}
            {/*to={`/${Route.ABOUT}`}*/}
            {/*>*/}
            {/*{t({ id: 'common.about' })}*/}
            {/*</NavLink>*/}
            {/*{process.env.NODE_ENV !== 'production' && (*/}
            {/*<React.Fragment>*/}
            {/*<NavLink to={`/all-in-one`}>{t({ id: 'All-In-One' })}</NavLink>*/}
            {/*<NavLink to={`/tile-view`}>{t({ id: 'Tile View' })}</NavLink>*/}
            {/*</React.Fragment>*/}
            {/*)}*/}
            <CheckPermission>
              <Button
                style={{ textTransform: 'none' }}
                onClick={signOut}
                variant="outlined"
              >
                {t({ id: 'common.sign_out' })}
              </Button>
            </CheckPermission>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={toggleDrawer(!drawerOpen)}
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>
        </Toolbar>
        <ApiLoader />
      </AppBar>
      <Hidden mdUp>
        <DrawerNavigation
          signOut={signOut}
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
        />
      </Hidden>
      <Hidden mdUp>
        <ButtonNavigation />
      </Hidden>
      <div className={classes.children}>{children}</div>
      <div className={`${classes.poweredBy} hidePrint`}>
        <img
          style={{ cursor: 'pointer' }}
          onClick={() => {
            push(`/${Route.ABOUT}`);
          }}
          src={PoweredBy}
          alt=""
        />
      </div>
    </>
  );
};

export default PrivateLayout;
