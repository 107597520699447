import moment from 'moment';
import * as api from '@api/';
import jwtDecode from 'jwt-decode';

interface IToken {
  user_id: number;
  user_name: string;
  is_admin: boolean;
  action: string;
  jti: string;
  iat: number;
  exp: number;
}

const TOKEN = 'accessToken';
const REFRESH = 'refreshToken';

export const getAccessToken = () => {
  try {
    return JSON.parse(localStorage.getItem(TOKEN) as string);
  } catch (error) {
    // @ts-ignore
    return window.accessToken;
  }
};

export const setAccessToken = (token: string) => {
  try {
    localStorage.setItem(TOKEN, JSON.stringify(token));
  } catch (error) {
    // @ts-ignore
    window.accessToken = token;
  }
};

export const getRefreshToken = () => {
  try {
    return JSON.parse(localStorage.getItem(REFRESH) as string);
  } catch (error) {
    // @ts-ignore
    return window.refreshToken;
  }
};

export const setRefreshToken = (token: string) => {
  try {
    localStorage.setItem(REFRESH, JSON.stringify(token));
  } catch (error) {
    // @ts-ignore
    window.refreshToken = token;
  }
};

export const removeAccessToken = () => {
  try {
    localStorage.removeItem(TOKEN);
  } catch (error) {
    // @ts-ignore
    window.accessToken = '';
  }
};

export const removeRefreshToken = () => {
  try {
    localStorage.removeItem(REFRESH);
  } catch (error) {
    // @ts-ignore
    window.refreshToken = '';
  }
};

export const isTokenValid = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    try {
      const decodedToken = jwtDecode(accessToken) as IToken;
      if (moment().unix() < decodedToken.exp) {
        return true;
      } else {
        refreshToken();
      }
    } catch (err) {
      removeAccessToken();
    }
  } else {
    return false;
  }
};

export const isRefreshTokenValid = () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const decodedToken = jwtDecode(refreshToken) as IToken;
      if (moment().unix() < decodedToken.exp) {
        return true;
      } else {
        refreshToken();
      }
    } catch (err) {
      removeAccessToken();
      removeRefreshToken();
      return false;
    }
  } else {
    removeAccessToken();
    removeRefreshToken();
    return false;
  }
};

export const refreshToken = () => {
  if (isRefreshTokenValid()) {
    api
      .refreshToken(getRefreshToken())
      .then(res => {
        setAccessToken(res.data.token);
        setRefreshToken(res.data.refresh);
        return true;
      })
      .catch(err => {
        removeAccessToken();
        removeRefreshToken();
        // window.location.pathname = '/';
        return null;
      });
  } else {
    return null;
  }
};
