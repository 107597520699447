import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useStores } from '@utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase, TextField } from '@material-ui/core';
import { Header } from '@assets/styles/commonComponents';

import { Route } from '@utils/enums/routes';

import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import LogoSrc from '@assets/images/logo.svg';

interface IProps {
  email: string;
  handleSubmit: any;
  setEmail: Function;
  redirected?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 100,
      [theme.breakpoints.down('md')]: {
        fontSize: `${fontSizes.paragraph} !important`
      }
    },
    customerColor: {
      color: theme.palette.secondary.main
    },
    title: {
      fontWeight: 'bold',
      fontSize: fontSizes.h1,
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizes.h2
      }
    },
    cancel: {
      [theme.breakpoints.down('md')]: {
        fontSize: `${fontSizes.paragraph} !important`
      }
    }
  })
);

const CancelButton = styled(ButtonBase)`
  display: block;
  margin-bottom: 2px;
  text-align: center;
  text-decoration: none;
  font-size: ${fontSizes.paragraph1};
`;

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const SendResetLink: React.FC<IProps> = ({
  email,
  setEmail,
  handleSubmit,
  redirected
}) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { appStore } = useStores();
  const config = appStore.getConfig();

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit}
        color="primary"
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Wrapper>
          <Header>
            <h1 className={classes.title}>
              Forgot
              <br />
              Password
            </h1>
            {appStore.config.hasHeaderLogo && (
              <Logo src={config.logo || LogoSrc} />
            )}
          </Header>
          <p className={classes.description}>
            If an account matching your email <br />
            address is found, an email will be <br />
            sent with password reset instructions.
          </p>
          <div className={classes.root}>
            <TextField
              required
              id="email"
              type="email"
              label="Email"
              value={email}
              color="secondary"
              placeholder="Enter your email"
              onInput={e => {
                const target = e.target as HTMLInputElement;
                setEmail(target.value);
              }}
            />
          </div>
        </Wrapper>
        <Wrapper>
          <div className={classes.root}>
            <ButtonBase type="submit" className={classes.button}>
              SEND RESET LINK
            </ButtonBase>
            <CancelButton
              className={`${classes.customerColor} ${classes.cancel}`}
              onClick={() => {
                redirected ? appStore.setPage('Login') : push(Route.LOGIN);
              }}
            >
              Cancel
            </CancelButton>
          </div>
        </Wrapper>
      </form>
    </React.Fragment>
  );
};

export default SendResetLink;
