export default [
  '',
  'What is your favorite book?',
  'What is the name of the road you grew up on?',
  'What is your mother’s maiden name?',
  'What was the name of your first/current/favorite pet?',
  'What was the first company that you worked for?',
  'Where did you meet your spouse?',
  'Where did you go to high school/college?',
  'What is your favorite food?',
  'What city were you born in?',
  'Where is your favorite place to vacation?'
];
