import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import fontSizes from '@assets/styles/fontSizes';
import Button from '@material-ui/core/Button';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

import ContentWrapper from '@components/ContentWrapper';

interface IProps {}

const Icon = styled(PersonAddDisabledIcon)`
  color: #c7ccd4;
  margin-top: 30px;
  margin-bottom: 50px;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: ${fontSizes.h3};
`;

const Text = styled.p`
  margin: 48px 0 20px 0;
  font-size: ${fontSizes.paragraph1};
`;

const AccountClosed: React.FC<IProps> = () => {
  return (
    <ContentWrapper>
      <React.Fragment>
        <Icon fontSize="large" />
        <Title>Account Closed</Title>
        <Text>Your account has been closed, as you requested.</Text>
        <Button
          component={Link}
          to="/login"
          color="primary"
          variant="contained"
        >
          Ok
        </Button>
      </React.Fragment>
    </ContentWrapper>
  );
};

export default AccountClosed;
