import React from 'react';
import styled from 'styled-components';

import ContentWrapper from '@components/ContentWrapper';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';
import ButtonBase from '@material-ui/core/ButtonBase';
import fontSizes from '@assets/styles/fontSizes';
import { Events } from '@utils/enums/events';

import FailSrc from '@assets/icons/id_fail';

interface IEIdFailedProps {}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
`;

const Icon = styled.div`
  display: flex;
  margin: auto;
  margin-top: -20px;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: ${fontSizes.h2};
  font-weight: bold;
  margin-bottom: 40px;
`;

const Container = styled.div`
  text-align: center;
`;

const IdSuccessEIdFailed: React.FC<IEIdFailedProps> = () => {
  const gs = globalStyles();
  const { appStore } = useStores();

  return (
    <ContentWrapper hasWrapper={!appStore.config.popupMode}>
      <Wrapper className={gs.wrapper}>
        <Container>
          <Icon>
            <FailSrc />
          </Icon>
          <Title>
            Enrollment Service is Unavailable, Please Try Again Later
          </Title>
          <ButtonBase
            style={{ marginTop: 70 }}
            className={gs.button}
            onClick={() => {
              window.parent.postMessage({ type: Events.ACCREGFAIL_ACK }, '*');
            }}
          >
            Close
          </ButtonBase>
        </Container>
      </Wrapper>
    </ContentWrapper>
  );
};

export default IdSuccessEIdFailed;
