import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import { IQuiz, ISelectedAnswer } from '@interfaces/quiz';
import { useStores } from '@utils/hooks/useStores';
import { observer } from 'mobx-react';

interface IQuizProps {
  quiz: IQuiz;
  verifyQuiz: Function;
}

interface IQuizQuestionStyleProps {
  selected?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    submit: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginBottom: 20,
      marginTop: 24
    },
    disabled: {
      backgroundColor: `${theme.palette.secondary.main}30`
    },
    backContainer: {
      margin: 0,
      textAlign: 'center'
    },
    back: {
      textAlign: 'center',
      fontSize: fontSizes.paragraph2,
      color: theme.palette.secondary.main,
      margin: '-10px 0px',
      padding: '10px 40px'
    }
  })
);

const Title = styled.h3`
  font-weight: bold;
  font-size: ${fontSizes.h3};
  line-height: 18px;
  text-align: center;
  margin-top: 0;
`;

const Description = styled.p`
  font-size: ${fontSizes.h5};
  line-height: 20px;
  margin-left: -20px;
  margin-right: -20px;
  text-align: center;
`;

const QuizQuestion = styled(ButtonBase)<IQuizQuestionStyleProps>`
  background-color: ${colors.lightContainer};
  padding: 14px 16px;
  width: 100%;
  margin-top: 8px;
  border: 1px solid
    ${({ selected }) => (selected ? colors.black : 'transparent')};
`;

const Quiz: React.FC<IQuizProps> = ({ quiz, verifyQuiz }) => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const { userStore } = useStores();
  const { verifyInProgress } = userStore;
  const [quizIndex, setQuizIndex] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [selectedAnswers, setSelectedAnswers] = useState<ISelectedAnswer[]>([]);

  const { questions, key, id } = quiz;

  const handleSubmit = () => {
    if (quizIndex < questions.length - 1) {
      setQuizIndex(quizIndex + 1);
    } else {
      verifyQuiz({ key: key, id: id, answers: selectedAnswers });
    }
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 2000);
  };

  const setQuizAnswer = (id: number) => {
    selectedAnswers[quizIndex] = {
      qid: questions[quizIndex].id,
      aid: id
    };
    setSelectedAnswers([...selectedAnswers]);
  };

  const back = () => {
    if (quizIndex !== 0) {
      setQuizIndex(quizIndex - 1);
    } else {
      goBack();
    }
  };

  return (
    <React.Fragment>
      <Title>Identification Quiz</Title>
      <Description>{questions[quizIndex].text}</Description>
      <div>
        {questions[quizIndex].answers.map(answer => (
          <QuizQuestion
            key={answer.id}
            selected={answer.id === selectedAnswers[quizIndex]?.aid}
            onClick={() => setQuizAnswer(answer.id)}
          >
            {answer.text}{' '}
            {answer.correct && (
              <span>
                <b>(Correct Answer)</b>
              </span>
            )}
          </QuizQuestion>
        ))}
      </div>
      <ButtonBase
        disabled={!selectedAnswers[quizIndex] || disabled || verifyInProgress}
        classes={{ root: classes.submit, disabled: classes.disabled }}
        onClick={handleSubmit}
      >
        CONTINUE
      </ButtonBase>

      <div className={classes.backContainer}>
        <ButtonBase onClick={back} classes={{ root: classes.back }}>
          Back
        </ButtonBase>
      </div>
    </React.Fragment>
  );
};

export default observer(Quiz);
