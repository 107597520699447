import React, { useState, useEffect } from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import { useStores } from '@utils/hooks/useStores';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Notifier: React.FC = () => {
  const { notifyStore } = useStores();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayed, setDisplayed] = useState<SnackbarKey[]>([]);

  const storeDisplayed = (id: SnackbarKey) => {
    setDisplayed([...displayed, id]);
  };

  const onClickDismiss = (key?: any) => {
    closeSnackbar(key);
  };

  // const hello = <div>X</div>;

  useEffect(() => {
    autorun(() => {
      notifyStore.notifications.forEach(notification => {
        // Do nothing if snackbar is already displayed
        if (displayed.includes(notification.key)) return;
        // Display snackbar using notistack
        enqueueSnackbar(notification.message, {
          ...notification.options,
          persist: false,
          autoHideDuration: 3000,
          disableWindowBlurListener: true,
          preventDuplicate: true,
          action: (key: any) => (
            <IconButton onClick={() => onClickDismiss(key)}>
              <CloseIcon color="primary" fontSize="small" />
            </IconButton>
          )
        });
        // Keep track of snackbars that we've displayed
        storeDisplayed(notification.key);
        // Dispatch action to remove snackbar from mobx store
        notifyStore.removeSnackbar(notification.key);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default observer(Notifier);
