import React from 'react';
import styled from 'styled-components';
import colors from '@assets/styles/colors';

import Alert from '@assets/images/alert.svg';

interface IProps {}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${colors.supportive};
`;

const NoMonitoringData: React.FC<IProps> = () => {
  return (
    <Container>
      <img src={Alert} alt="" />
      <br />
      <br />
      <div>You have no alerts to display.</div>
    </Container>
  );
};

export default NoMonitoringData;
