// import * as api from '@api/';
import { NotifyStore } from '@stores/NotifyStore';
import { action, observable, decorate } from 'mobx';
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  removeAccessToken,
  removeRefreshToken
} from '@utils/token';
import * as Sentry from '@sentry/react';

import { IInitialize } from '@interfaces/common';
import { IIdentityInfo } from '@interfaces/user';
import { Route } from '@utils/enums/routes';

function getDefaultConfig() {
  const config = {} as any;
  const query = document.location.search.substring(1).split('&');
  if (query.length > 0)
    query.forEach(elem => {
      const prop = elem.split('=');
      if (prop[0] === 'token') config.token = prop[1];
      if (prop[0] === 'key') config.key = prop[1];
      if (prop[0] === 'ocf') config.ocf = +prop[1];
      if (prop[0] === 'ocl') config.ocl = prop[1];
      if (prop[0] === 'oct')
        config.oct = `#${prop[1].slice(prop[1].length - 6)}`;
      if (prop[0] === 'view') config.view = prop[1];
    });
  return config;
}

export class AppStore {
  constructor(private notify: NotifyStore) {}
  public flagOverride: boolean = false;
  public themeOverride: boolean = false;
  public logoOverride: boolean = false;

  public showPage: string = '';
  public isDirectUser: boolean | null = null;
  public user: IIdentityInfo = {};
  public config: IInitialize = {
    flags: getDefaultConfig().ocf || 32767,
    theme: getDefaultConfig().oct || '#EF4D7E',
    logo: getDefaultConfig().ocl || null,
    view: getDefaultConfig().view || 'dashboard',
    isCredible: false,
    useIDFS: false,
    popupMode: false,
    formLabelColor: '',
    fullDiscOnIdentity: false,
    hasBackgroundImg: true,
    identityScreenTitleText: 'Identity',
    mobileIdentityScreenTitleText: 'Mobile Identity',
    hasHeaderLogo: true,
    eidFailedRedirect: 'default'
  };

  public key: string = '';
  public view: string = 'dashboard';

  public setAccessToken = (token: string | null) => {
    if (token) {
      setAccessToken(token);
    } else {
      removeAccessToken();
    }
  };

  public setRefreshToken = (token: string | null) => {
    if (token) {
      setRefreshToken(token);
    } else {
      removeRefreshToken();
    }
  };

  public setUser = (user: IIdentityInfo) => {
    this.user = user;
  };

  public setKey = (key: string) => {
    this.key = key;
  };

  public setView = (view: string) => {
    this.config.view = view;
  };

  public setIsCredible = (isCredible: boolean) => {
    this.config.isCredible = isCredible;
  };

  public setUseIDFS = (useIDFS: boolean) => {
    this.config.useIDFS = useIDFS;
  };

  public setHeaderLogoVisibility = (hasHeaderLogo: boolean) => {
    this.config.hasHeaderLogo = hasHeaderLogo;
  };

  public setBackgroundImgVisibility = (hasBackgroundImg: boolean) => {
    this.config.hasBackgroundImg = hasBackgroundImg;
  };

  public setIdentityScreenTitleText = (identityScreenTitleText: string) => {
    this.config.identityScreenTitleText = identityScreenTitleText;
  };

  public setEidFailedRedirect = (eidFailedRedirect: string) => {
    this.config.eidFailedRedirect = eidFailedRedirect;
  };

  public setMobileIdentityScreenTitleText = (
    mobileIdentityScreenTitleText: string
  ) => {
    this.config.mobileIdentityScreenTitleText = mobileIdentityScreenTitleText;
  };

  public getConfig = (): IInitialize => {
    if (!this.config) {
      this.config = {
        flags: 65535,
        theme: '#EF4D7E',
        logo: null,
        popupMode: false,
        formLabelColor: '',
        fullDiscOnIdentity: false,
        linkColor: '',
        identityScreenTitleText: 'Identity',
        hasBackgroundImg: true,
        mobileIdentityScreenTitleText: 'Mobile Identity',
        hasHeaderLogo: true,
        eidFailedRedirect: 'default',
        useIDFS: false
      };
      // const configStr = localStorage.getItem('clientConfig');
      const configStr = this.config;
      if (configStr) {
        const configData: IInitialize | null = configStr;
        // const configData: IInitialize | null = JSON.parse(configStr);
        if (configData) {
          if (configData.flags) this.config.flags = configData.flags;
          if (configData.fullDiscOnIdentity)
            this.config.fullDiscOnIdentity = configData.fullDiscOnIdentity;
          if (configData.logo) this.config.logo = configData.logo;
          if (configData.theme) this.config.theme = configData.theme;
          if (configData.formLabelColor)
            this.config.formLabelColor = configData.formLabelColor;
          if (configData.popupMode)
            this.config.popupMode = configData.popupMode;
        }
      }
    }
    return this.config;
  };

  public getRedirectView = () => {
    const redirectView =
      this.config.view &&
      Object.values(Route).includes(this.config.view as Route)
        ? this.config.view
        : Route.DASHBOARD_SCORE;

    return redirectView;
  };

  public overrideConfigFlags = (flags: number) => {
    if (this.config) this.config.flags = flags;
    this.flagOverride = true;
  };

  public overrideConfigLogo = (logo: string) => {
    if (this.config) this.config.logo = decodeURIComponent(logo);
    this.logoOverride = true;
  };

  public overrideConfigTheme = (theme: string) => {
    if (this.config) this.config.theme = theme;
    this.themeOverride = true;
  };

  public setFormLabelColor = (formLabelColor: string) => {
    this.config.formLabelColor = formLabelColor;
  };

  public setLinkColor = (linkColor: string) => {
    this.config.linkColor = linkColor;
  };

  public setPopupModeForms = (popupModeEnabled: boolean) => {
    this.config.popupMode = popupModeEnabled;
  };

  public setIdentityDisclaimer = (fullDiscOnIdentity: boolean) => {
    this.config.fullDiscOnIdentity = fullDiscOnIdentity;
  };

  public isFlag(flag: any): boolean {
    return this.config.flags && flag;
  }

  public isLoggedOut(): boolean {
    return !getAccessToken();
  }

  public logout = () => {
    this.user = {};
    Sentry.configureScope(scope => scope.setUser(null));
    this.setAccessToken(null);
    this.setRefreshToken(null);
  };

  public setPage = (page: string) => {
    this.showPage = page;
  };

  public setIsDirectUser = (isDirectUser: boolean) => {
    this.isDirectUser = isDirectUser;
  };
}

decorate(AppStore, {
  showPage: observable,
  isDirectUser: observable,
  user: observable,
  config: observable,
  setAccessToken: action,
  setRefreshToken: action,
  overrideConfigFlags: action,
  overrideConfigLogo: action,
  overrideConfigTheme: action,
  setUser: action,
  getConfig: action,
  getRedirectView: action,
  isFlag: action,
  isLoggedOut: action,
  logout: action,
  setPage: action,
  setIsDirectUser: action,
  themeOverride: observable,
  flagOverride: observable,
  logoOverride: observable,
  key: observable,
  setView: action,
  view: observable,
  setKey: action
});
