import React from 'react';
import MaskedInput from 'react-text-mask';

// Input masks
export const MobileMask = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[0-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      guide={false}
    />
  );
};

export const SsnMask = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      key="ssn"
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

export const SsnSanitizedMask = (props: any) => {
  const { inputRef, ...other } = props;

  const mask = [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/ // Masked and unmasked sections
  ];

  const pipe = (conformedValue: any, config: any) => {
    // Replace specific characters with "*"
    const maskedValue = conformedValue
      .split('')
      .map((char: string, index: number) => {
        if (
          index === 0 ||
          index === 1 ||
          index === 2 ||
          index === 4 ||
          index === 5
        ) {
          return '*';
        }
        return char;
      })
      .join('');

    return maskedValue;
  };
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      pipe={pipe}
      mask={mask}
      guide={false} // Prevents placeholders from being displayed as the user types
    />
  );
};

export const DateMask = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-1]/,
        props.value && props.value[0] === '0' ? /[0-9]/ : /[0-2]/,
        '-',
        /[0-3]/,
        props.value && props.value[3] === '0'
          ? /[0-9]/
          : props.value && (props.value[3] === '1' || props.value[3] === '2')
          ? /[0-9]/
          : /[0-1]/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      guide={false}
    />
  );
};

export const DateSanitizedMask = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-1]/,
        props.value && props.value[0] === '0' ? /[0-9]/ : /[0-2]/,
        '-',
        /[0-3]/,
        props.value && props.value[3] === '0'
          ? /[0-9]/
          : props.value && (props.value[3] === '1' || props.value[3] === '2')
          ? /[0-9]/
          : /[0-1]/,
        '-',
        /\d/,
        /\d/,
        '*',
        '*'
      ]}
      guide={false}
    />
  );
};

// Get Unmasked values
export const getUnmaskedPhoneNumber = (value: string) => {
  return value.replace(/[^0-9]+/g, '');
};

export const getSsnUnmasked = (value: string) => {
  return value.replace(/[^0-9]+/g, '');
};

export const getDateUnmasked = (value: string) => {
  return value.replace(/[^0-9]+/g, '');
};
