import React from 'react';
import styled from 'styled-components';
import Checkmark from '@assets/images/checkmark.svg';

import { globalStyles } from '@assets/styles/globalStyles';
import ContentWrapper from '@components/ContentWrapper';
import fontSizes from '@assets/styles/fontSizes';

interface ISmsVerificationCompletedProps {}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.p`
  font-size: ${fontSizes.h2};
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
`;

const Info = styled.p`
  color: #90929b;
  text-align: center;
`;

const SmlsVerificationCompleted: React.FC<ISmsVerificationCompletedProps> = () => {
  const gs = globalStyles();

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={false}
      hasBackgroundImg={false}
    >
      <Wrapper className={gs.wrapper}>
        <>
          <img src={Checkmark} alt="" />
          <Title> Authentication complete </Title>
          <Info>You may close this window and return to your application </Info>
        </>
      </Wrapper>
    </ContentWrapper>
  );
};

export default SmlsVerificationCompleted;
