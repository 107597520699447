import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98.6737 60.6754L60.0254 99.3236"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M98.6737 99.3236L60.0254 60.6754"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M79.3504 126.7C105.142 126.7 126.05 105.792 126.05 80C126.05 54.2083 105.142 33.3 79.3504 33.3C53.5587 33.3 32.6504 54.2083 32.6504 80C32.6504 105.792 53.5587 126.7 79.3504 126.7Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
