import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import { globalStyles } from '@assets/styles/globalStyles';
import Button from '@material-ui/core/Button';

import ContentWrapper from '@components/ContentWrapper';

import Lock from '@assets/icons/lock';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const Logo = styled.div`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const RegisterLock: React.FC<IProps> = () => {
  const gs = globalStyles();
  const { pathname } = useLocation();

  const render24HContent = () => {
    return (
      <React.Fragment>
        <p>
          Too many registration attempts from this location <br />
          have resulted in a temporary lockout.
        </p>
        <p>
          Please wait at least 24 hours <br />
          before trying again.
        </p>
      </React.Fragment>
    );
  };

  const render72HContent = () => {
    return (
      <React.Fragment>
        <p>
          Too many recent registration attempts <br />
          have resulted in a temporary lockout.
        </p>
        <p>
          Please wait at least 72 hours <br />
          before trying again.
        </p>
      </React.Fragment>
    );
  };

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <Logo>
          <Lock />
        </Logo>
        <h2>Registration Lockout</h2>
        {pathname.indexOf('reg-lock') > -1 && render24HContent()}
        {pathname.indexOf('reg-ip-lock') > -1 && render72HContent()}
        <Button
          to="/login"
          component={Link}
          color="secondary"
          variant="contained"
          className={gs.button2}
        >
          Ok
        </Button>
      </Wrapper>
    </ContentWrapper>
  );
};

export default RegisterLock;
