export default {
  names: { EFX: 'Equifax', EXP: 'Experian', TU: 'TransUnion' },
  _processScores(scores: any) {
    if (!scores) return null;
    return scores.providerViews.reduce((data: any, score: any) => {
      data[score.provider] = {
        date: this._getUTCDate(scores.generatedDate),
        score: score.score,
        rating: this._getScoreRange(score),
        factors: this._getScoreFactors(score)
      };

      return data;
    }, {});
  },
  _processScoreHistory(scoresHistory: any) {
    if (!scoresHistory) return null;
    const history = scoresHistory.reduce(
      (history: any, score: any) => {
        history = score.providerViews.reduce(
          (history: any, providerView: any) => {
            history[providerView.provider].scores.push({
              score: providerView.score,
              date: score.generatedDate
            });
            history[providerView.provider].min = Math.min(
              history[providerView.provider].min,
              providerView.score
            );
            history[providerView.provider].max = Math.max(
              history[providerView.provider].max,
              providerView.score
            );
            history[providerView.provider].lastScoreDate = Math.max(
              history[providerView.provider].lastScoreDate,
              score.generatedDate
            );
            if (
              history[providerView.provider].lastScoreDate ===
              score.generatedDate
            )
              history[providerView.provider].lastScore = providerView.score;
            return history;
          },
          history
        );
        return history;
      },
      {
        EFX: {
          min: 860,
          max: 280,
          lastScore: 280,
          lastScoreDate: 0,
          scores: [],
          history: {}
        },
        EXP: {
          min: 860,
          max: 280,
          lastScore: 280,
          lastScoreDate: 0,
          scores: [],
          history: {}
        },
        TU: {
          min: 860,
          max: 280,
          lastScore: 280,
          lastScoreDate: 0,
          scores: [],
          history: {}
        }
      }
    );

    history.EFX.scores = this._filterScoreHistoryDuplicates(history.EFX.scores);
    history.EFX.history = history.EFX.scores.reduce(
      (history: any, score: any) => {
        history[this._getUTCDate(score.date).toLocaleDateString()] =
          score.score;
        return history;
      },
      {}
    );
    history.EXP.scores = this._filterScoreHistoryDuplicates(history.EXP.scores);
    history.EXP.history = history.EXP.scores.reduce(
      (history: any, score: any) => {
        history[this._getUTCDate(score.date).toLocaleDateString()] =
          score.score;
        return history;
      },
      {}
    );
    history.TU.scores = this._filterScoreHistoryDuplicates(history.TU.scores);
    history.TU.history = history.TU.scores.reduce(
      (history: any, score: any) => {
        history[this._getUTCDate(score.date).toLocaleDateString()] =
          score.score;
        return history;
      },
      {}
    );

    return history;
  },
  _filterScoreHistoryDuplicates(history: any) {
    history = history.filter((score: any, index: number, history: any) => {
      return (
        index === 0 || Math.abs(score.date - history[index - 1].date) > 1000
      );
    });
    return history;
  },
  _processReports(reports: any) {
    const summaries = reports.map((a: any) => a.summary);
    return this._processSummary(summaries);
  },
  _processSummary(summaries: any) {
    if (!summaries) return null;
    return summaries.reduce((data: any, summary: any) => {
      // let repDate = new Date(summary.reportGenerated);
      const creditScore = summary.creditScore;
      data[summary.provider] = {
        id: summary.id,
        date: this._getUTCDate(summary.reportGenerated),
        score: creditScore ? creditScore.score : 'Not Available',
        rating: creditScore
          ? this._getScoreRange(creditScore)
          : 'Not Available',
        factors: creditScore ? this._getScoreFactors(creditScore) : [],
        accountsDetail: {
          Revolving: this._getAccountsData(
            'Revolving',
            summary.revolvingAccounts
          ),
          Mortgage: this._getAccountsData('Mortgage', summary.mortgageAccounts),
          Installment: this._getAccountsData(
            'Installment',
            summary.installmentAccounts
          ),
          Other: this._getAccountsData('Other', summary.otherAccounts),
          Total: this._getAccountsData('Total', summary.totalOpenAccounts)
        },
        additional: this._getOtherData(summary)
      };

      return data;
    }, {});
  },
  _getAllNegativePaymentHistory(details: any) {
    let rv: any = [];
    rv = rv.concat(this._getPaymentNegativeHistory(details?.revolvingAccounts));
    rv = rv.concat(this._getPaymentNegativeHistory(details?.mortgageAccounts));
    rv = rv.concat(this._getPaymentNegativeHistory(details?.otherAccounts));
    return rv.concat(
      this._getPaymentNegativeHistory(details?.installmentAccounts)
    );
  },
  _getPaymentNegativeHistory(accounts: any) {
    return accounts?.reduce(
      (total: any, obj: any) =>
        total.concat(
          obj?.paymentHistory?.reduce(
            (t: any, o: any) =>
              t.concat(
                Object.keys(o)?.reduce((t1: any, key: string) => {
                  let val = o[key];
                  if (val?.monthType === 'NEGATIVE') {
                    t1.push({
                      date: new Date(key + ' 02, ' + o.year),
                      accountName: obj.accountName,
                      accountType: obj.accountType
                    });
                  }
                  return t1;
                }, [])
              ),
            []
          )
        ),
      []
    );
  },
  _getScoreRange(score: any) {
    return score.scoreRanges.reduce((rating: any, range: any) => {
      if (score.score >= range.low && score.score <= range.high) {
        return range.name;
      }

      return rating;
    }, '');
  },
  _getScoreFactors(score: any) {
    const factors = {
      positive: [],
      negative: []
    };
    const reasons = score.scoreReasons;
    if (reasons && reasons.length) {
      reasons.forEach(function(reason: any) {
        if (reason.code.charAt(0) === 'P') {
          factors.positive.push({
            //@ts-ignore
            code: reason.code,
            //@ts-ignore
            description: reason.description
          });
        } else {
          factors.negative.push({
            //@ts-ignore
            code: reason.code,
            //@ts-ignore
            description: reason.description
          });
        }
      });
    }

    return factors;
  },
  _getAccountsData(label: string, account: any) {
    return account
      ? {
          title: label,
          balance: account.balance ? account.balance.amount : 0,
          limit: account.creditLimit ? account.creditLimit.amount : 0,
          avail: account.available ? account.available.amount : 0,
          paymentAmt: account.monthlyPaymentAmount
            ? account.monthlyPaymentAmount.amount
            : 0,
          debtRatio: account.debtToCreditRatio,
          open: account.totalAccounts,
          openWithBalance: account.totalAccountsWithBalance,
          negativeAccounts: account.totalNegativeAccounts
        }
      : {
          title: label,
          balance: 0,
          limit: 0,
          paymentAmt: 0,
          debtRatio: 0,
          open: 0,
          openWithBalance: 0,
          negativeAccounts: 0
        };
  },
  _getOtherData(report: any) {
    const acctOpen = new Date(report.oldestAccountOpenDate);
    return {
      consumer: report.totalConsumerStatements,
      personal: report.totalPersonalInformation,
      inquiries: report.totalInquires,
      public: report.totalPublicRecords,
      collections: report.totalCollections,
      avgAccountAge: report.averageAccountAgeMonths,
      oldestAccount: report.oldestAccountName,
      oldestAccountDate: new Date(
        report.oldestAccountOpenDate + acctOpen.getTimezoneOffset() * 60000
      )
    };
  },
  _getUTCDate(date: any, offset: number = 0) {
    const tDate = new Date(date);
    return new Date(date + tDate.getTimezoneOffset() * 60000 + offset);
  }
};
