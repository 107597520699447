import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import {
  LineChart as Chart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from 'recharts';
import colors from '@assets/styles/colors';

interface IProps {
  width?: number;
  height?: number;
  data: any | any[];
  stroke?: string;
}

const CustomTooltip = styled.div`
  padding: 5px;
  text-align: center;
  border-radius: 4px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);

  > p {
    margin: 0;
  }
`;

const customizedXAxisTick = (data: any) => {
  return (
    <g>
      <text
        dy={10}
        x={data.x}
        y={data.y}
        fontSize={13}
        fill="#53AAC7"
        textAnchor="middle"
      >
        {moment(data.payload.value).format('MMM Y')}
      </text>
    </g>
  );
};

const customizedYAxisTick = (data: any) => {
  return (
    <g>
      <text
        dy={4}
        x={data.x}
        y={data.y}
        fontSize={13}
        fill="#C7CCD4"
        textAnchor="end"
      >
        {data.payload.value}
      </text>
    </g>
  );
};

const customizedTooltip = (data: any) => {
  if (!data.payload || !data.payload.length) return null;

  return (
    <CustomTooltip>
      <p>Score: {data.payload[0].payload.score}</p>
      <p>{moment(data.payload[0].payload.date).format('MMM DD, Y')}</p>
    </CustomTooltip>
  );
};

const LineChart: React.FC<IProps> = ({ data, width, height, stroke }) => {
  return (
    <div>
      <Chart
        // @ts-ignore
        width={width}
        height={height}
        data={data}
        margin={{
          left: -30
        }}
      >
        <CartesianGrid fontSize={13} strokeDasharray="3 3" vertical={false} />
        <XAxis
          padding={{ left: 15, right: 15 }}
          dataKey="date"
          type="category"
          tick={customizedXAxisTick}
          stroke={stroke}
        />
        <YAxis
          type="number"
          domain={[280, 850]}
          tick={customizedYAxisTick}
          stroke={stroke}
        />
        <Tooltip payload={data} content={customizedTooltip} />
        <Line r={3} type="linear" dataKey="score" stroke="#53AAC7" />
      </Chart>
    </div>
  );
};

LineChart.defaultProps = {
  width: 500,
  height: 160,
  stroke: colors.lightInputText
};

export default LineChart;
