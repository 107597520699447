import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.963 88.1034C118.963 98.4195 114.865 108.313 107.571 115.607C100.276 122.902 90.3826 127 80.0666 127C69.7506 127 59.8571 122.902 52.5626 115.607C45.2681 108.313 41.17 98.4195 41.17 88.1034V42.7241L80.0666 33L118.963 42.7241V88.1034Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M90.3895 74.8344V65.6513C90.3817 62.9475 89.3042 60.3568 87.3924 58.4449C85.4805 56.5331 82.8898 55.4556 80.186 55.4478V55.4478C77.4823 55.4556 74.8915 56.5331 72.9797 58.4449C71.0679 60.3568 69.9904 62.9475 69.9825 65.6513V74.8344"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M98.5526 74.834H61.8199V100.343H98.5526V74.834Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M80.1864 89.1198C82.4405 89.1198 84.2678 87.2925 84.2678 85.0384C84.2678 82.7843 82.4405 80.957 80.1864 80.957C77.9323 80.957 76.105 82.7843 76.105 85.0384C76.105 87.2925 77.9323 89.1198 80.1864 89.1198Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M80.1852 89.1191V94.2209"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
