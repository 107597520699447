import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Events } from '@utils/enums/events';
import { observer } from 'mobx-react';

import ContentWrapper from '@components/ContentWrapper';

import { globalStyles } from '@assets/styles/globalStyles';
import ButtonBase from '@material-ui/core/ButtonBase';
import fontSizes from '@assets/styles/fontSizes';

import { useStores } from '@utils/hooks/useStores';
import { IProviderView } from '@interfaces/latestCreditScore';
import { Button } from '@material-ui/core';

interface IIDSuccessProps {}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
`;

const Title = styled.h2`
  font-size: ${fontSizes.h1};
  font-weight: bold;
  margin-bottom: 0px;
`;

const IDSuccess: React.FC<IIDSuccessProps> = () => {
  const gs = globalStyles();

  const {
    dashboardStore: { latestScore },
    dashboardStore,
    appStore,
    userStore,
    appStore: { getRedirectView }
  } = useStores();

  useEffect(() => {
    const fetchData = async () => {
      await dashboardStore.getLatestScore();
    };

    fetchData();
  }, [dashboardStore]);

  const redirectView = getRedirectView();

  const efxScore =
    latestScore &&
    latestScore.providerViews.length &&
    latestScore.providerViews
      // @ts-ignore
      .find(
        (item: IProviderView, i: number, ar: any) => item.provider === 'EFX'
      ).score;

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!appStore.config.popupMode}
      hasBackgroundImg={appStore.config.hasBackgroundImg}
    >
      <Wrapper className={gs.wrapper}>
        <Title>
          {efxScore ? (
            <>
              {' '}
              Your credit score is <strong> {efxScore} </strong>{' '}
            </>
          ) : (
            `Welcome`
          )}
        </Title>
        <span style={{ fontSize: 12 }}>
          {' '}
          VantageScore® 3.0 credit score using Equifax data.{' '}
        </span>

        <br />
        <p style={{ marginBottom: 0 }}>
          You’re successfully enrolled in free credit monitoring!
        </p>
        <p style={{ marginTop: 0 }}>
          Come back any time to check your score and credit report on your
          Credit dashboard.
        </p>

        {userStore.justEnrolled && (
          <p style={{ marginBottom: 0, fontSize: 14, fontStyle: 'italic' }}>
            Note: Please be advised that certain identity verification steps
            have been omitted for your convenience by leveraging your previous
            identity application to save your time.
          </p>
        )}
        <br />

        {appStore.config.isCredible && appStore.config.popupMode && (
          <>
            <Button
              style={{ textTransform: 'none' }}
              classes={{ root: gs.button }}
              onClick={() =>
                window.parent.postMessage(
                  { type: Events.IDENTITY_COMPLETED_REDIRECT },
                  '*'
                )
              }
            >
              View Credit Dashboard
            </Button>
            <Button
              style={{ textTransform: 'none' }}
              classes={{ root: gs.buttonOutlined }}
              onClick={() =>
                window.parent.postMessage({ type: Events.CLOSE }, '*')
              }
            >
              Go Back to My Personal Loan Rates
            </Button>
          </>
        )}

        {!appStore.config.popupMode && (
          <>
            <ButtonBase
              style={{ textTransform: 'none' }}
              component={Link}
              to={`/${redirectView}`}
              classes={{ root: gs.button }}
              onClick={() =>
                window.parent.postMessage(
                  { type: Events.IDENTITY_COMPLETED_REDIRECT },
                  '*'
                )
              }
            >
              View Credit Dashboard
            </ButtonBase>
          </>
        )}
      </Wrapper>
    </ContentWrapper>
  );
};

export default observer(IDSuccess);
