import React from 'react';
import styled from 'styled-components';

import { Link as L } from 'react-router-dom';
import { Header } from '@assets/styles/commonComponents';
import { ButtonBase, TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import questions from '@utils/constants/questions';

import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

interface IProps {
  recQuestion: number;
  handleSubmit: any;
  recQuestionAnswer: string;
  setRecQuestionAnswer: Function;
}

const CancelButton = styled(L)`
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: ${fontSizes.paragraph1};
`;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 100
    },
    customerColor: {
      color: theme.palette.secondary.main
    }
  })
);

const RecoveryQuestion: React.FC<IProps> = ({
  recQuestion,
  recQuestionAnswer,
  setRecQuestionAnswer,
  handleSubmit
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Header>
        <h1>Close Account</h1>
      </Header>
      <form className={classes.root} onSubmit={handleSubmit} color="primary">
        <p className={classes.description}>
          Please answer your recovery question.
        </p>
        <TextField
          required
          type="text"
          id="recQuestion"
          color="secondary"
          value={recQuestionAnswer}
          label={questions[recQuestion]}
          placeholder="Enter your answer"
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setRecQuestionAnswer(target.value);
          }}
        />
        <ButtonBase type="submit" className={classes.button}>
          Continue
        </ButtonBase>
        <CancelButton className={classes.customerColor} to={'/settings'}>
          Cancel
        </CancelButton>
      </form>
    </React.Fragment>
  );
};

export default RecoveryQuestion;
