import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';
import IconButton from '@material-ui/core/IconButton';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

interface IPageSwipeHeaderProps {
  pages?: number;
  activeIndex?: number;
  title?: string;
  back: Function;
  next: Function;
  style?: any;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: 10,
      color: '#eef1f8'
    },
    button: {
      background: `linear-gradient(0deg, ${colors.white}, ${colors.white}), ${colors.linearGradient}`,
      boxShadow: '0px 4px 7px rgba(37, 57, 104, 0.06)',
      [theme.breakpoints.down('sm')]: {
        margin: '0 15px'
      }
    },
    container: {
      height: 80,
      display: 'flex',
      alignItems: 'center',
      color: colors.lightBlack,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        height: 60
      }
    },
    dot: {
      width: 8,
      height: 8,
      margin: '0px 8px',
      marginBottom: 16,
      borderRadius: '50%',
      backgroundColor: 'white',
      '&.active': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('sm')]: {
        width: 6,
        height: 6,
        margin: '0px 4px',
        marginBottom: 8
      }
    },
    title: {
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: fontSizes.h2,
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizes.h3
      }
    }
  })
);

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageSwipeHeader: React.FC<IPageSwipeHeaderProps> = ({
  pages,
  activeIndex,
  title,
  back,
  next,
  style
}) => {
  const classes = useStyles();
  const { formatMessage: t } = useIntl();
  const pagesArray = new Array(pages).fill('dot');

  const handleBack = () => {
    back();
  };

  const handleNext = () => {
    next();
  };

  return (
    <div style={style} className={classes.root}>
      {pages && pages > 1 && (
        <div className={classes.container}>
          <IconButton
            disabled={activeIndex === 0}
            className={classes.button}
            aria-label="left"
            onClick={handleBack}
          >
            <LeftIcon />
          </IconButton>
          <Column>
            <DotContainer>
              {pagesArray.map((page, index) => (
                <div
                  key={page + index}
                  className={`${classes.dot} ${
                    index === activeIndex ? 'active' : ''
                  }`}
                />
              ))}
            </DotContainer>
            <span className={classes.title}>
              {t({
                id: `providers.${title}`
              })}
            </span>
          </Column>
          <IconButton
            disabled={activeIndex === pagesArray.length - 1}
            className={classes.button}
            aria-label="right"
            onClick={handleNext}
          >
            <RightIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default PageSwipeHeader;
