import 'babel-polyfill';
import 'es6-promise';
import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';

import { IntlProvider } from 'react-intl';
import translations, { Lang } from '@utils/locales';

import { SnackbarProvider } from 'notistack';

import { StylesProvider } from '@material-ui/core/styles';
import ErrorBoundary from './components/ErrorBoundary';
import Theme from './components/Theme';

import App from './App';
import Notifier from './Notifier';

import 'normalize.css';
import './assets/styles/main.scss';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Initial Sentry Setup
Sentry.init({
  dsn:
    'https://2be7ebb2f1c9459b982cb0d4f8006988@o362446.ingest.sentry.io/5851068',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || 'development'
});

ReactDOM.render(
  <ErrorBoundary>
    <React.Fragment>
      <Theme>
        <StylesProvider injectFirst>
          <IntlProvider
            locale={Lang.EN}
            defaultLocale={Lang.EN}
            messages={translations[Lang.EN]}
          >
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <Notifier />
              <App />
            </SnackbarProvider>
          </IntlProvider>
        </StylesProvider>
      </Theme>
    </React.Fragment>
  </ErrorBoundary>,
  document.getElementById('root')
);
