import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';

import Loading from '@components/Loading';
import ContentWrapper from '@components/ContentWrapper';
import SendLink from '@components/DITVerification/SendLink';
import VerificationFailure from '@components/DITVerification/VerificationFailure';
import VerifyLink from '@components/DITVerification/VerifyLink';

interface IDITVerificationProps {}

const Wrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;
  text-align: center;
`;

const DITVerification: React.FC<IDITVerificationProps> = () => {
  const gs = globalStyles();
  const { userStore } = useStores();
  const history = useHistory();
  const [step, setStep] = useState<number>(0);
  const [mobileToken, setMobileToken] = useState<string>('');
  const [smfaToken, setSmfaToken] = useState<string>('');
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [isDemo, setIsDemo] = useState<boolean>(false);
  const [endingNumber, setEndingNumber] = useState<string>('');
  const { appStore } = useStores();

  const navigate = (route: string) => {
    history.push(route);
  };

  useEffect(() => {
    const verifyIdentity = async () => {
      const result = await userStore.verifyDITIdentity(navigate);
      if (result && result.token && result.mobile) {
        setMobileToken(result.token);
        setEndingNumber(result.mobile.substr(-4));
        // setExpires(result.expires); // need a timer to expire and fail
        smfaStep();
      }
    };

    verifyIdentity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifySMFA = async (): Promise<boolean> => {
    const { isSuccess, redirect, allowRetry } = await userStore.verifySMFA(
      smfaToken
    );
    if (isSuccess && redirect) {
      navigate(redirect);
    } else {
      if (allowRetry) {
        return true;
      }

      if (redirect) {
        navigate(redirect);
      } else {
        failStep();
      }
    }
    return false;
  };

  const sendSMFA = async () => {
    const result = await userStore.sendSMFALink(mobileToken, navigate);
    if (result) {
      verifyStep();
      setSmfaToken(result.token);
      //setSmfaExpires(result.exipres);
      if (result.smsMessage) {
        setSmsMessage(result.smsMessage);
      }
      setIsDemo(result?.linkUrl?.startsWith('NO_URL_'));
    }
  };

  const smfaStep = () => {
    setStep(1);
  };

  const verifyStep = () => {
    setStep(2);
  };

  const failStep = () => {
    setStep(3);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return <Loading />;
      case 1:
        return <SendLink endingNumber={endingNumber} sendLink={sendSMFA} />;
      case 2:
        return (
          <VerifyLink
            SMSMessage={smsMessage}
            isDemo={isDemo}
            verifySMFA={verifySMFA}
            endingNumber={endingNumber}
            resendLink={sendSMFA}
          />
        );
      case 3:
        return <VerificationFailure />;
    }
  };

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!appStore.config.popupMode}
      hasBackgroundImg={appStore.config.hasBackgroundImg}
    >
      <Wrapper className={gs.wrapper}>
        <div>{renderContent(step)}</div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default DITVerification;
