import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import fontSizes from '@assets/styles/fontSizes';
import colors from '@assets/styles/colors';

import { Route } from '@utils/enums/routes';

import LogoSrc from '@assets/images/logo.svg';
import { useStores } from '@utils/hooks/useStores';

interface ISendTextProps {
  sendCode: Function;
  endingNumber: string;
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: '17px 0',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '0.075em',
    textTransform: 'uppercase',
    color: colors.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: fontSizes.h5,
    width: '100%',
    marginTop: 100,
    marginBottom: 20
  },
  customerColor: {
    color: theme.palette.secondary.main
  }
}));

const Container = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: ${fontSizes.h3};
  margin-top: 80px;
  text-align: left;
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  font-size: ${fontSizes.h5};
`;

const AnswerText = styled.div`
  text-align: center;
`;

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.span`
  font-size: ${fontSizes.h1};
`;

const SendText: React.FC<ISendTextProps> = ({ endingNumber, sendCode }) => {
  const classes = useStyles();
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const { appStore } = useStores();
  const config = appStore.getConfig();

  const sendMobileCode = () => {
    sendCode();
    setSubmitDisabled(true);
    setTimeout(() => {
      setSubmitDisabled(false);
    }, 1000);
  };

  return (
    <React.Fragment>
      <Header>
        <Title>
          {config.mobileIdentityScreenTitleText}
          <br />
        </Title>
        {appStore.config.hasHeaderLogo && <Logo src={config.logo || LogoSrc} />}
      </Header>
      <Container>
        <span>
          StitchCredit will attempt to verify your identity by sending a text
          with a one-time code to your mobile phone number ending in{' '}
          {endingNumber}.
        </span>
        <p>Message and data rates may apply.</p>
        <ButtonBase
          disabled={submitDisabled}
          className={classes.button}
          onClick={sendMobileCode}
        >
          SEND ME A TEXT
        </ButtonBase>
        <AnswerText>
          <CustomLink className={classes.customerColor} to={`/${Route.EID}`}>
            Answer financial questions instead
          </CustomLink>
        </AnswerText>
      </Container>
    </React.Fragment>
  );
};

export default SendText;
