import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
// import { Route } from '@utils/enums/routes';
import { refreshToken, isTokenValid, getAccessToken } from '@utils/token';
import * as Sentry from '@sentry/react';

const CACHE_EXPIRE = 900000;
const cache = setupCache({ maxAge: CACHE_EXPIRE });

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use(config => {
  if (
    // @ts-ignore
    config.url.indexOf('users/initialize') !== -1 ||
    // @ts-ignore
    config.url.indexOf('/users/register') !== -1 ||
    // @ts-ignore
    config.url.indexOf('/users/login') !== -1 ||
    // @ts-ignore
    config.url.indexOf('/users/password-recovery') !== -1
  ) {
    // @ts-ignore
    config.headers.ctoken = window.apiToken;
  } else {
    delete config.headers.ctoken;
  }
  return config;
});

instance.interceptors.response.use(
  response => response,
  error => {
    const errorData = error?.response?.data;
    Sentry.captureException(errorData);
    if (error.response && error.response.status === 401) {
      // window.location.pathname = `/${Route.LOGIN}`;
    }
    return Promise.reject();
  }
);

export const sAxios = axios.create({ baseURL: process.env.REACT_APP_API_URL });

sAxios.interceptors.request.use(
  config => {
    if (isTokenValid()) {
      config.headers.Authorization = 'Bearer ' + getAccessToken();
      return config;
    }
    return Promise.reject(new Error('Auth Required'));
  },
  error => {
    return Promise.reject(error);
  }
);

sAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const errorData = error?.response?.data;
    Sentry.captureException(errorData);
    if (!error.response || error.response.status === 401) {
      refreshToken();
      delete sAxios.defaults.headers.common['Authorization'];
      // window.location.pathname = '/';
    }
    return Promise.reject(error);
  }
);

export const eAxios = axios.create({ adapter: cache.adapter });

export default instance;
