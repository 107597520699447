import { IState } from '@interfaces/common';

export const states: IState = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  AS: 'American Samoa',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MP: 'Northern Mariana Islands',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  TT: 'Trust Territories',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const statesArr = [
  { shortName: 'AL', name: 'Alabama' },
  { shortName: 'AK', name: 'Alaska' },
  { shortName: 'AZ', name: 'Arizona' },
  { shortName: 'AR', name: 'Arkansas' },
  { shortName: 'AS', name: 'American Samoa' },
  { shortName: 'CA', name: 'California' },
  { shortName: 'CO', name: 'Colorado' },
  { shortName: 'CT', name: 'Connecticut' },
  { shortName: 'DE', name: 'Delaware' },
  { shortName: 'DC', name: 'District of Columbia' },
  { shortName: 'FL', name: 'Florida' },
  { shortName: 'GA', name: 'Georgia' },
  { shortName: 'GU', name: 'Guam' },
  { shortName: 'HI', name: 'Hawaii' },
  { shortName: 'ID', name: 'Idaho' },
  { shortName: 'IL', name: 'Illinois' },
  { shortName: 'IN', name: 'Indiana' },
  { shortName: 'IA', name: 'Iowa' },
  { shortName: 'KS', name: 'Kansas' },
  { shortName: 'KY', name: 'Kentucky' },
  { shortName: 'LA', name: 'Louisiana' },
  { shortName: 'ME', name: 'Maine' },
  { shortName: 'MD', name: 'Maryland' },
  { shortName: 'MA', name: 'Massachusetts' },
  { shortName: 'MI', name: 'Michigan' },
  { shortName: 'MN', name: 'Minnesota' },
  { shortName: 'MS', name: 'Mississippi' },
  { shortName: 'MO', name: 'Missouri' },
  { shortName: 'MP', name: 'Northern Mariana Islands' },
  { shortName: 'MT', name: 'Montana' },
  { shortName: 'NE', name: 'Nebraska' },
  { shortName: 'NV', name: 'Nevada' },
  { shortName: 'NH', name: 'New Hampshire' },
  { shortName: 'NJ', name: 'New Jersey' },
  { shortName: 'NM', name: 'New Mexico' },
  { shortName: 'NY', name: 'New York' },
  { shortName: 'NC', name: 'North Carolina' },
  { shortName: 'ND', name: 'North Dakota' },
  { shortName: 'OH', name: 'Ohio' },
  { shortName: 'OK', name: 'Oklahoma' },
  { shortName: 'OR', name: 'Oregon' },
  { shortName: 'PA', name: 'Pennsylvania' },
  { shortName: 'PR', name: 'Puerto Rico' },
  { shortName: 'RI', name: 'Rhode Island' },
  { shortName: 'SC', name: 'South Carolina' },
  { shortName: 'SD', name: 'South Dakota' },
  { shortName: 'TN', name: 'Tennessee' },
  { shortName: 'TT', name: 'Trust Territories' },
  { shortName: 'TX', name: 'Texas' },
  { shortName: 'UT', name: 'Utah' },
  { shortName: 'VT', name: 'Vermont' },
  { shortName: 'VA', name: 'Virginia' },
  { shortName: 'VI', name: 'Virgin Islands' },
  { shortName: 'WA', name: 'Washington' },
  { shortName: 'WV', name: 'West Virginia' },
  { shortName: 'WI', name: 'Wisconsin' },
  { shortName: 'WY', name: 'Wyoming' }
];
