import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';

import Loading from '@components/Loading';
import ContentWrapper from '@components/ContentWrapper';
import SendText from '@components/MobileVerification/SendText';
import CodeFailure from '@components/MobileVerification/CodeFailure';
import VerificationCode from '@components/MobileVerification/VerificationCode';

interface IMobileVerificationProps {}

const Wrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;
  text-align: center;
`;

const MobileVerification: React.FC<IMobileVerificationProps> = () => {
  const gs = globalStyles();
  const { userStore } = useStores();
  const history = useHistory();
  const [step, setStep] = useState<number>(0);
  const [code, setCode] = useState<string>('');
  const [mobileToken, setMobileToken] = useState<string>('');
  const [endingNumber, setEndingNumber] = useState<string>('');
  const [transactionKey, setTransactionKey] = useState<string>('');
  const [SMSCode, setSMSCode] = useState<string>('');
  const { appStore } = useStores();

  const navigate = (route: string) => {
    history.push(route);
  };

  useEffect(() => {
    const verifyPhone = async () => {
      const result = await userStore.verifyPhone(navigate);
      if (result && result.token && result.mobile) {
        setMobileToken(result.token);
        setEndingNumber(result.mobile);
        nextStep();
      }
    };

    verifyPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerifyCode = async () => {
    const { isSuccess, redirect, allowRetry } = await userStore.verifyCode({
      key: transactionKey,
      code
    });
    if (isSuccess && redirect) {
      navigate(redirect);
    } else {
      if (allowRetry) {
        return;
      }

      if (redirect) {
        navigate(redirect);
      } else {
        failStep();
      }
    }
  };

  const sendCode = async () => {
    const result = await userStore.sendCode(mobileToken, navigate);
    if (result) {
      nextStep();
      setTransactionKey(result.key);
      if (result.passcode) {
        setSMSCode(result.passcode);
      }
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const failStep = () => {
    setStep(3);
  };

  const resendCode = () => {
    userStore.sendNewCode({ key: transactionKey }).then(resp => {
      if (resp) {
        setTransactionKey(resp.key);
        setSMSCode(resp.passcode);
      }
    });
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return <Loading />;
      case 1:
        return <SendText endingNumber={endingNumber} sendCode={sendCode} />;
      case 2:
        return (
          <VerificationCode
            SMSCode={SMSCode}
            handleSubmit={handleVerifyCode}
            code={code}
            transactionKey={transactionKey}
            setCode={setCode}
            resendCode={resendCode}
          />
        );
      case 3:
        return <CodeFailure />;
    }
  };

  return (
    <ContentWrapper
      fullHeight={true}
      hasWrapper={!appStore.config.popupMode}
      hasBackgroundImg={appStore.config.hasBackgroundImg}
    >
      <Wrapper className={gs.wrapper}>
        <div>{renderContent(step)}</div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default MobileVerification;
