import React from 'react';
import { Route } from '@utils/enums/routes';

import Home from '@routes/home';
import About from '@routes/about';
import Score from '@routes/score';
import ScoreUp from '@routes/score_up';
import Login from '@routes/login';
import Report from '@routes/report';
import IdQuiz from '@routes/id_quiz';
import Monitor from '@routes/monitor';
import Register from '@routes/register';
import Settings from '@routes/settings';
import Identity from '@routes/identity';
import NotFound from '@routes/not_found';
import SysError from '@routes/sys_error';
import LoginTile from '@routes/login-tile';
import EIdFailed from '@routes/eid_failed';
import IdSuccessEIdFailed from '@routes/id_success_eid_failed';
import IdSuccess from '@routes/id_success';
import IdRestore from '@routes/id_restore';
import TermsOfUse from '@routes/terms_of_use';
import DirectLogin from '@routes/login-direct';
import AccountLock from '@routes/account_lock';
import ChangeEmail from '@routes/change_email';
import LoginAIO from '@routes/login-all-in-one';
import RegisterLock from '@routes/register_lock';
import CloseAccount from '@routes/close_account';
import AccountClosed from '@routes/account_closed';
import UserNotFound from '@routes/user_not_found';
import PrivacyPolicy from '@routes/privacy_policy';
import ChangePassword from '@routes/change_password';
import RegistrationFail from '@routes/register_fail';
import PasswordRecovery from '@routes/password_recovery';
import MobileVerification from '@routes/mobile_verification';
import DITVerification from '@routes/dit_verification';
import ThinFile from '@routes/thin_file';
import ServiceFailure from '@routes/service_failure';
import AlertNotifications from '@routes/alert-notifications';
import ChangeRecoveryQuestion from '@routes/change_recovery_question';
import SmlsVerificationCompleted from '@routes/SmsVerificationCompleted';

export interface IRouteProps {
  exact?: boolean;
  component: React.FC;
  path: string | string[];
}

export const privateRoutes: IRouteProps[] = [
  {
    path: `/${Route.DASHBOARD_SCORE}`,
    component: Score,
    exact: true
  },
  {
    path: `/${Route.DASHBOARD_SCORE_UP}`,
    component: ScoreUp,
    exact: true
  },
  {
    path: `/${Route.DASHBOARD_REPORT}`,
    component: Report
  },
  {
    path: `/${Route.DASHBOARD_MONITOR}`,
    component: Monitor
  },
  {
    path: `/${Route.DASHBOARD_IDRESTORE}`,
    component: IdRestore
  },
  {
    path: `/${Route.SETTINGS}`,
    component: Settings
  },
  {
    path: `/${Route.ABOUT}`,
    component: About
  },
  {
    path: `/${Route.REGISTER_LOCK}`,
    component: RegisterLock
  },
  {
    path: `/${Route.CLOSE_ACCOUNT}`,
    component: CloseAccount
  },
  {
    path: `/${Route.ACCOUNT_CLOSED}`,
    component: AccountClosed
  },
  {
    path: `/${Route.ID_RESTORE}`,
    component: IdRestore
  },
  {
    path: `/${Route.CHANGE_PASSWORD}`,
    component: ChangePassword
  },
  {
    path: `/${Route.CHANGE_EMAIL}`,
    component: ChangeEmail
  },
  {
    path: `/${Route.CHANGE_RECOVERY_QUESTION}`,
    component: ChangeRecoveryQuestion
  },
  {
    path: `/${Route.ALERT_NOTIFICATIONS}`,
    component: AlertNotifications
  },
  {
    path: `/${Route.THIN_FILE}`,
    component: ThinFile
  },
  {
    path: `/${Route.SERVICE_FAILURE}`,
    component: ServiceFailure
  }
];

export const publicRoutes: IRouteProps[] = [
  {
    path: `/`,
    exact: true,
    component: Home
  },
  {
    path: `/${Route.LOGIN}`,
    component: Login
  },
  {
    path: `/${Route.DIRECT_LOGIN}`,
    component: DirectLogin
  },
  {
    path: `/${Route.LOGIN_TILE}`,
    component: LoginTile
  },
  {
    path: `/${Route.LOGIN_AIO}`,
    component: LoginAIO
  },
  {
    path: `/${Route.SIGNUP}`,
    exact: true,
    component: Register
  },
  {
    path: `/${Route.IDENTITY}`,
    component: Identity
  },
  {
    path: `/${Route.MOBILE}`,
    component: MobileVerification
  },
  {
    path: `/${Route.DIT}`,
    component: DITVerification
  },
  {
    path: `/${Route.EID}`,
    component: IdQuiz
  },
  {
    path: `/${Route.EID_FAILED}`,
    component: EIdFailed
  },
  {
    path: `/${Route.ID_SUCCESS_EID_FAILED}`,
    component: IdSuccessEIdFailed
  },
  {
    path: `/${Route.ID_SUCCESS}`,
    component: IdSuccess
  },
  {
    path: `/${Route.REGISTRATION_FAIL}`,
    component: RegistrationFail
  },
  {
    path: `/${Route.PASSWORD_RECOVERY}`,
    component: PasswordRecovery
  },
  {
    path: `/${Route.ACCOUNT_LOCK_TEMP}`,
    component: AccountLock
  },
  {
    path: `/${Route.ACCOUNT_LOCK_PERM}`,
    component: AccountLock
  },
  {
    path: `/${Route.TERMS_OF_USE}`,
    component: TermsOfUse
  },
  {
    path: `/${Route.PRIVACY_POLICY}`,
    component: PrivacyPolicy
  },
  {
    path: `/${Route.REGISTER_LOCK}`,
    component: RegisterLock
  },
  {
    path: `/${Route.REGISTER_IP_LOCK}`,
    component: RegisterLock
  },
  {
    path: `/${Route.SMS_VERIFICATION_COMPLETED}`,
    component: SmlsVerificationCompleted
  },
  {
    path: `/${Route.SYS_ERROR}`,
    component: SysError
  },
  {
    path: `/${Route.USER_NOT_FOUND}`,
    component: UserNotFound
  },
  {
    path: `/${Route.NOT_FOUND}`,
    component: NotFound
  }
  // {
  //   path: '*',
  //   component: NotFound
  // }
];
