import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@utils/hooks/useStores';
import SwipeableViews from 'react-swipeable-views';

import Loading from '@components/Loading';
import ReportPage from '@components/Report';
import Container from '@components/Container';
import PageSwipeHeader from '@components/PageSwipeHeader';

import ICreditReportSummary from '@interfaces/creditReportSummary';
import NoData from '@components/NoData';

interface IReportProps {}

const Report: React.FC<IReportProps> = () => {
  const { dashboardStore } = useStores();
  const [pages, setPages] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [multipleSources, setMultipleSources] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!dashboardStore.reportList) dashboardStore.getReportList();
  }, [dashboardStore]);

  useEffect(() => {
    if (dashboardStore.reportList) {
      setPages(
        dashboardStore.reportList
          .map((d: ICreditReportSummary) => d.provider)
          .filter(
            (item: ICreditReportSummary, i: number, ar: any) =>
              ar.indexOf(item) === i
          )
      );

      setData(
        dashboardStore.reportList.filter(
          (item: ICreditReportSummary) => item.provider === pages[activeStep]
        )
      );

      const multi = pages.length > 1;
      if (multi) {
        setMultipleSources(true);
      } else {
        setMultipleSources(false);
      }
    }
    // TODO: Change pages to useReducer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardStore.reportList, pages.length, activeStep]);

  useEffect(() => {
    if (dashboardStore.reportListLoaded) {
      setIsDataLoaded(true);
    }
  }, [dashboardStore.reportListLoaded]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Container>
      {isDataLoaded && !data.length && (
        <NoData text={'You have no report data to display.'} />
      )}
      {isDataLoaded && (
        <React.Fragment>
          <PageSwipeHeader
            back={handleBack}
            next={handleNext}
            activeIndex={activeStep}
            pages={pages.length}
            title={pages[activeStep]}
          />
          <SwipeableViews
            disabled={true}
            enableMouseEvents
            index={activeStep}
            onChangeIndex={handleStepChange}
          >
            {pages.map((page, index) => (
              <React.Fragment key={index}>
                {data.map((d: any, i: number) => (
                  <ReportPage data={d} key={i} multi={multipleSources} />
                ))}
              </React.Fragment>
            ))}
          </SwipeableViews>
        </React.Fragment>
      )}
      {!isDataLoaded && <Loading />}
    </Container>
  );
};

export default observer(Report);
