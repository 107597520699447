import React, { useState } from 'react';
import styled from 'styled-components';
import * as api from '@api/';
import { useStores } from '@utils/hooks/useStores';

import { globalStyles } from '@assets/styles/globalStyles';

import Success from '@components/ChangeEmail/Success';
import NewEmail from '@components/ChangeEmail/NewEmail';
import ContentWrapper from '@components/ContentWrapper';
import CurrentPassword from '@components/ChangeEmail/CurrentPassword';
import RecoveryQuestion from '@components/ChangeEmail/RecoveryQuestion';

interface IProps {}

const Wrapper = styled.div`
  position: relative;
`;

const ChangeEmail: React.FC<IProps> = () => {
  const gs = globalStyles();
  const { userStore, notifyStore } = useStores();
  const [step, setStep] = useState<number>(0);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [recQuestion, setRecQuestion] = useState<number>(0);
  const [recQuestionAnswer, setRecQuestionAnswer] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const checkPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await api.actionToken(
        userStore.user!.email,
        currentPassword
      );
      setToken(response.data.token);
      setRecQuestion(response.data.recoveryQuestion);
      nextStep();
    } catch (error) {
      notifyStore.error(error.message);
    }
  };

  const checkRecQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextStep();
  };

  const changeEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.changeEmail(newEmail, recQuestionAnswer, token);
      nextStep();
    } catch (error) {
      notifyStore.error('Email change failed');
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CurrentPassword
            password={currentPassword}
            handleSubmit={checkPassword}
            setPassword={setCurrentPassword}
          />
        );
      case 1:
        return (
          <RecoveryQuestion
            recQuestion={recQuestion}
            handleSubmit={checkRecQuestion}
            recQuestionAnswer={recQuestionAnswer}
            setRecQuestionAnswer={setRecQuestionAnswer}
          />
        );
      case 2:
        return (
          <NewEmail
            newEmail={newEmail}
            setNewEmail={setNewEmail}
            handleSubmit={changeEmail}
          />
        );
      case 3:
        return <Success />;
    }
  };

  return (
    <ContentWrapper>
      <Wrapper className={gs.wrapper}>
        <div>{renderContent(step)}</div>
      </Wrapper>
    </ContentWrapper>
  );
};

export default ChangeEmail;
