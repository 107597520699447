import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import fontSizes from '@assets/styles/fontSizes';

import SuccessIcon from '@assets/images/success.svg';
import colors from '@assets/styles/colors';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: 'center'
    },
    description: {
      marginTop: '15px',
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 50
    }
  })
);

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const Success: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo src={SuccessIcon} />
      <h2>Email changed</h2>
      <p className={classes.description}>
        You have successfully changed <br />
        your email.
      </p>
      <ButtonBase to={'/settings'} component={Link} className={classes.button}>
        Ok
      </ButtonBase>
    </div>
  );
};

export default Success;
