import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    customerColor: {
      stroke: theme.palette.secondary.main
    }
  })
);

interface IProps {}

const Icon: React.FC<IProps> = () => {
  const classes = useStyles();

  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.7042 58.3665C54.6394 57.5099 49.6232 56.8936 43.6037 56.8936C31.3222 56.8936 23.2146 59.4635 18.8499 61.3994C16.4598 62.4589 14.9395 64.827 14.9395 67.441V78.943H50.2185"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1689 30.4346C28.1689 21.9103 35.0792 15 43.6035 15C52.1278 15 59.0381 21.9103 59.0381 30.4346C59.0381 38.9589 52.1278 48.0741 43.6035 48.0741C35.0792 48.0741 28.1689 38.9589 28.1689 30.4346Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M67.8704 81.1609C75.1771 81.1609 81.1003 75.2377 81.1003 67.9311C81.1003 60.6245 75.1771 54.7013 67.8704 54.7013C60.5638 54.7013 54.6406 60.6245 54.6406 67.9311C54.6406 75.2377 60.5638 81.1609 67.8704 81.1609Z"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M71.1007 65L65 71.1006"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M65 65L71.1007 71.1006"
        className={classes.customerColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Icon;
