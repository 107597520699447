import React, { useEffect, useRef, useState } from 'react';
import { Animate } from 'react-move';

interface IProps {
  valueStart: number;
  valueEnd: number;
  duration: number;
  easingFunction: any;
  children: any;
  repeat: boolean;
}

const Animation: React.FC<IProps> = ({
  children,
  valueStart,
  valueEnd,
  duration,
  easingFunction,
  repeat
}) => {
  const [isAnimated, setIsAnimated] = useState<boolean>(false);
  let interval: any = useRef(undefined);

  useEffect(() => {
    if (repeat) {
      interval.current = window.setInterval(() => {
        setIsAnimated(!isAnimated);
      }, duration * 1000);
    } else {
      setIsAnimated(true);
    }

    return () => {
      window.clearInterval(interval.current);
    };
  }, [repeat, duration, isAnimated]);

  return (
    <Animate
      start={() => ({
        value: valueStart
      })}
      update={() => ({
        value: [isAnimated ? valueEnd : valueStart],
        timing: {
          duration: duration * 1000,
          ease: easingFunction
        }
      })}
    >
      {children}
    </Animate>
  );
};

export default Animation;
