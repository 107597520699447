import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { object, string, ref } from 'yup';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase, TextField } from '@material-ui/core';
import { Header } from '@assets/styles/commonComponents';

import { useStores } from '@utils/hooks/useStores';
import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';

import LogoSrc from '@assets/images/logo.svg';

import questions from '@utils/constants/questions';
import { passwordRegex } from '@utils/constants/regex';

interface IProps {
  handleSubmit: any;
  newPassword: string;
  setNewPassword: Function;
  repeatNewPassword: string;
  setRepeatNewPassword: Function;
  recQuestion?: number;
  recQuestionAnswer?: string;
  setRecQuestionAnswer?: Function;
  wrongAnswer: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxWidth: '300px',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%'
      }
    },
    description: {
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 50,
      [theme.breakpoints.down('md')]: {
        fontSize: `${fontSizes.paragraph} !important`
      }
    },
    title: {
      fontWeight: 'bold',
      fontSize: fontSizes.h1,
      [theme.breakpoints.down('md')]: {
        fontSize: fontSizes.h2
      }
    }
  })
);

const Logo = styled.img`
  height: 25px;
  padding: 6px 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const newPasswordSchema = object({
  newPass: string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters longs.')
    .matches(
      passwordRegex,
      'Password must contain a capital letter, a special character and a digit.'
    ),
  repeatNewPass: string()
    .required('Password confirm is required')
    .oneOf([ref('newPass')], 'Passwords do not match')
});

const NewPassword: React.FC<IProps> = ({
  handleSubmit,
  newPassword,
  setNewPassword,
  repeatNewPassword,
  setRepeatNewPassword,
  recQuestion,
  recQuestionAnswer,
  setRecQuestionAnswer,
  wrongAnswer
}) => {
  const { appStore } = useStores();
  const classes = useStyles();
  const config = appStore.getConfig();

  const formik = useFormik({
    initialValues: {
      newPass: newPassword,
      repeatNewPass: repeatNewPassword,
      recQuestAns: recQuestionAnswer
    },
    validationSchema: newPasswordSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: ({ newPass, repeatNewPass, recQuestAns }) => {
      formik.setFieldError('recQuestAns', undefined);
      setNewPassword(newPass);
      setRepeatNewPassword(repeatNewPass);
      if (setRecQuestionAnswer !== undefined) setRecQuestionAnswer(recQuestAns);
      handleSubmit(recQuestAns, newPass);
    }
  });

  useEffect(() => {
    if (wrongAnswer) {
      formik.setFieldError('recQuestAns', 'Wrong secret answer');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrongAnswer]);

  return (
    <React.Fragment>
      <form
        className={classes.root}
        onSubmit={formik.handleSubmit}
        color="primary"
      >
        <Wrapper className={classes.root}>
          <Header>
            <h1 className={classes.title}>
              Forgot
              <br />
              Password
            </h1>
            {appStore.config.hasHeaderLogo && (
              <Logo src={config.logo || LogoSrc} />
            )}
          </Header>
          {recQuestion !== undefined && (
            <p className={classes.description}>
              Please answer your password <br />
              recovery question.
            </p>
          )}
          {recQuestion !== undefined && (
            <TextField
              required
              type="text"
              id="recQuestAns"
              name="recQuestAns"
              color="secondary"
              label={questions[recQuestion]}
              placeholder="Enter your answer"
              value={formik.values.recQuestAns}
              onChange={formik.handleChange}
              error={!!formik.errors.recQuestAns}
              helperText={formik.errors.recQuestAns}
            />
          )}

          <p className={classes.description}>Please enter your new password.</p>
          <TextField
            required
            type="password"
            id="newPass"
            name="newPass"
            color="secondary"
            label="New Password"
            placeholder="Enter your new password"
            value={formik.values.newPass}
            onChange={formik.handleChange}
            error={!!formik.errors.newPass}
            helperText={formik.errors.newPass}
          />

          <TextField
            required
            type="password"
            id="repeatNewPass"
            name="repeatNewPass"
            color="secondary"
            label="Re-write new password"
            placeholder="Re-write your new password"
            value={formik.values.repeatNewPass}
            onChange={formik.handleChange}
            error={!!formik.errors.repeatNewPass}
            helperText={formik.errors.repeatNewPass}
          />
        </Wrapper>
        <Wrapper className={classes.root}>
          <ButtonBase type="submit" className={classes.button}>
            CONTINUE
          </ButtonBase>
        </Wrapper>
      </form>
    </React.Fragment>
  );
};

export default NewPassword;
