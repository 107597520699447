import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route } from '@utils/enums/routes';
import { Events } from '@utils/enums/events';
import { useStores } from '@utils/hooks/useStores';

interface IProps {
  redirect?: string;
}

const Listener: React.FC<IProps> = ({ redirect }) => {
  const { push } = useHistory();
  const { userStore, notifyStore, appStore } = useStores();

  useEffect(() => {
    const receiveMessage = (event: any) => {
      if (
        event
        // &&
        // event.source &&
        // event.source.frameElement &&
        // event.source.frameElement.id === 'sc-iframe'
      ) {
        if (event.data) {
          if (event.data.type === Events.WIDGET_CONFIGS) {
            const config = event.data.data;

            if (config.key) appStore.setKey(config.key);
            if (config.flags) appStore.overrideConfigFlags(config.flags);
            if (config.logo) appStore.overrideConfigLogo(config.logo);
            if (config.theme) appStore.overrideConfigTheme(config.theme);
            if (config.redirectView) appStore.setView(config.redirectView);
            if (config.isCredible) appStore.setIsCredible(config.isCredible);
            if (config.useIDFS) appStore.setUseIDFS(config.useIDFS);
            if (config.formLabelColor)
              appStore.setFormLabelColor(config.formLabelColor);
            if (config.linkColor) appStore.setLinkColor(config.linkColor);
            if (config.popupModeForms)
              appStore.setPopupModeForms(config.popupModeForms);
            if (config.identityDisclaimer)
              appStore.setIdentityDisclaimer(config.identityDisclaimer);

            if (config.identityScreenTitleText)
              appStore.setIdentityScreenTitleText(
                config.identityScreenTitleText
              );

            if (config.hideBackgroundImg)
              appStore.setBackgroundImgVisibility(!config.hideBackgroundImg);

            if (config.mobileIdentityScreenTitleText)
              appStore.setMobileIdentityScreenTitleText(
                config.mobileIdentityScreenTitleText
              );
            if (config.eidFailedRedirect)
              appStore.setEidFailedRedirect(config.eidFailedRedirect);

            if (config.hideHeaderLogo)
              appStore.setHeaderLogoVisibility(!config.hideHeaderLogo);
          } else if (event.data.type === Events.PREAUTH) {
            // if (appStore.isLoggedOut()) {

            // Todo: Fix double call
            // @ts-ignore
            // if (window.preAuthInProcess) return null;

            // @ts-ignore
            window.preAuthInProcess = true;
            const token = event.data.token;
            if (token) {
              userStore
                .preauthLogin(token)
                .then((res: any) => {
                  // @ts-ignore
                  window.preAuthInProcess = false;
                  if (res.just_enrolled) {
                    userStore.setJustEnrolled(true);
                    window.parent.postMessage(
                      { type: Events.USER_ENROLLED },
                      '*'
                    );

                    push(Route.ID_SUCCESS);
                    return;
                  }

                  if (res.idpass) {
                    if (redirect) {
                      push(redirect);
                    }
                  } else {
                    push(Route.IDENTITY);
                  }
                })
                .catch(() => {
                  window.location.href = Route.SYS_ERROR;
                  // @ts-ignore
                  window.preAuthInProcess = false;
                });
            } else {
              window.location.href = Route.SYS_ERROR;
              // @ts-ignore
              window.preAuthInProcess = false;
            }
            // }
          } else if (event.data.type === Events.AUTH) {
            // eslint-disable-next-line no-console
            console.log('Auth Data: ', event.data.data);
          } else if (event.data.type === Events.REG) {
            notifyStore.setRegistrationData(event.data.data);
          } else if (event.data.type === Events.IDENTITY) {
            notifyStore.setIdentityData(event.data.data);
          }
        }
      }
    };

    window.addEventListener('message', receiveMessage, false);
    window.parent.postMessage({ type: Events.INIT_CONFIG }, '*');
  }, [notifyStore, push, redirect, userStore, appStore]);

  return <></>;
};

export default React.memo(Listener);
// export default Listener;
