import React from 'react';
import styled from 'styled-components';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import fontSizes from '@assets/styles/fontSizes';

import PassRecovery from '@assets/images/pass_recovery.svg';
import colors from '@assets/styles/colors';

interface IProps {
  email: string;
  nextStep: Function;
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    description: {
      marginTop: '15px',
      lineHeight: '175%',
      letterSpacing: '0.03em',
      fontSize: fontSizes.paragraph1
    },
    button: {
      padding: '17px 0',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '0.075em',
      textTransform: 'uppercase',
      color: colors.white,
      backgroundColor: theme.palette.secondary.main,
      fontSize: fontSizes.h5,
      width: '100%',
      marginTop: 50
    }
  })
);

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const CheckYourEmail: React.FC<IProps> = ({ email, nextStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo src={PassRecovery} />
      <h2>Check your email</h2>
      <p className={classes.description}>
        We sent an email to <br />
        {email} <br />
        with instructions to reset your password.
      </p>
      <ButtonBase
        className={classes.button}
        onClick={() => {
          nextStep();
        }}
      >
        Ok
      </ButtonBase>
    </div>
  );
};

export default CheckYourEmail;
