import React from 'react';
import styled, { keyframes } from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '@assets/styles/colors';
import { ButtonBase } from '@material-ui/core';
import { useStores } from '@utils/hooks/useStores';

interface IProps {
  score: number;
  isScoreDisabled: (val: number) => boolean;
  handleScoreChange: (value: number) => void;
  isTimeDisabled: (val: number) => boolean;
  setTimeChange: (value: number) => void;
  scoreUp: any;
  rangeKey: number;
  timeChange: any;
}

const SCORE_VALUES = [10, 20, 40, 60, 80, 100];
const TIME_VALUES = [6, 12, 18, 24];

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      margin: '10px 0'
    },
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& > div': {
          margin: 0,
          marginBottom: 10
        }
      }
    },
    incButton: {
      background: 'green',
      color: 'white',
      border: 0,
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 15px',
      marginRight: 5,
      marginLeft: 5
    },
    timeButton: {
      background: '#bbb',
      color: 'white',
      border: 0,
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 15px'
    },
    disabledButton: {
      background: '#666',
      color: 'white',
      border: 0,
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 15px'
    }
  })
);

const BadgeButton = styled(ButtonBase)`
  margin: 5px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #c7ccd4;
  background-color: #c7ccd4;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: white;

  &:hover {
    background-color: ${colors.lightGreen};
    transform: translateY(-2px);
    color: white;
  }

  &:disabled {
    background-color: ${colors.lightGreen};
    color: white;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.span`
  font-weight: bold;
  margin: 10px 0;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AnimatedScoreRange = styled.div`
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 28px;
  color: ${colors.lightGreen};
  animation: ${fadeIn} 0.4s ease-in-out;
`;

const ScoreControls: React.FC<IProps> = ({
  score,
  isScoreDisabled,
  handleScoreChange,
  isTimeDisabled,
  setTimeChange,
  scoreUp,
  timeChange,
  rangeKey
}) => {
  const classes = useStyles();
  const { dashboardStore } = useStores();

  const lowerBound = scoreUp?.scoreIncrementLowerBound;
  const upperBound = scoreUp?.scoreIncrementUpperBound;

  const shouldShowFilterButton = (scoreChange: number) => {
    const directPathway = dashboardStore.scoreUp.directPathway;
    const possiblePathways = dashboardStore.scoreUp.possiblePathways;

    const isWithinDirectPathwayBounds =
      scoreChange >= directPathway.scoreIncrementLowerBound &&
      scoreChange <= directPathway.scoreIncrementUpperBound &&
      timeChange === directPathway.timeHorizon;

    const isWithinPossiblePathwaysBounds =
      possiblePathways?.some(
        (pw: any) =>
          scoreChange >= pw.scoreIncrementLowerBound &&
          scoreChange <= pw.scoreIncrementUpperBound &&
          timeChange === pw.timeHorizon
      ) ?? false;

    return isWithinDirectPathwayBounds || isWithinPossiblePathwaysBounds;
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <BottomContainer>
          <SubTitle>Set Your Target Score</SubTitle>
          <ButtonGroup>
            {SCORE_VALUES.map(
              value =>
                shouldShowFilterButton(value) && (
                  <BadgeButton
                    key={value}
                    onClick={() => handleScoreChange(value)}
                    disabled={isScoreDisabled(value)}
                    classes={{
                      root: classes.incButton,
                      disabled: classes.disabledButton
                    }}
                  >
                    {score + value}
                  </BadgeButton>
                )
            )}
          </ButtonGroup>
          <SubTitle>Set Your Time Horizon</SubTitle>
          <ButtonGroup>
            {TIME_VALUES.map(value => (
              <BadgeButton
                key={value}
                onClick={() => setTimeChange(value)}
                disabled={isTimeDisabled(value)}
                classes={{
                  root: classes.timeButton,
                  disabled: classes.disabledButton
                }}
              >
                {value}mo
              </BadgeButton>
            ))}
          </ButtonGroup>
          {lowerBound && upperBound && (
            <>
              <SubTitle>Your New Score Range</SubTitle>
              <AnimatedScoreRange key={rangeKey}>
                {score + lowerBound} - {score + upperBound}
              </AnimatedScoreRange>
            </>
          )}
        </BottomContainer>
      </div>
    </div>
  );
};

export default ScoreControls;
