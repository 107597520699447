import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  privateRoutes,
  IRouteProps,
  publicRoutes
} from '@utils/constants/routes';

import Home from '@routes/home';
import NotFound from '@routes/not_found';
import AllInOne from '@routes/all_in_one';
import TileView from '@routes/tile_view';
import Listener from '@utils/helpers/msgListener';
import PublicLayout from '@components/PublicLayout';
import PrivateLayout from '@components/PrivateLayout';
import { Route as Routes } from '@utils/enums/routes';

const AuthRoute = ({ ...rest }) => (
  <PrivateLayout>
    <Route {...rest} />
  </PrivateLayout>
);

const PublicRoute = ({ ...rest }) => (
  <PublicLayout>
    <Route {...rest} />
  </PublicLayout>
);

const directLoginPath = () => {
  let view;
  switch (window.location.pathname) {
    case '/login-aio':
      view = Routes.LOGIN_AIO;
      break;
    case '/login-direct':
      view = Routes.DIRECT_LOGIN;
      break;
    case '/login-tile':
      view = Routes.LOGIN_TILE;
      break;
    default:
      break;
  }

  return view;
};

const App: React.FC = () => {
  return (
    <Router>
      {!directLoginPath && <Listener />}
      <Switch>
        <Route exact path={'/'} component={Home} />
        {privateRoutes.map(({ ...rest }: IRouteProps, i: number) => (
          <AuthRoute key={`private-route-${i}`} {...rest} />
        ))}
        {publicRoutes.map(({ ...rest }, i: number) => (
          <PublicRoute key={`public-route-${i}`} {...rest} />
        ))}
        <Route component={AllInOne} exact path={'/all-in-one'} />
        <Route component={TileView} exact path={'/tile-view'} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
