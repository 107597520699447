import { createContext } from 'react';
import { AppStore, UserStore, NotifyStore, DashboardStore } from '.';

const notifyStore = new NotifyStore();
const appStore = new AppStore(notifyStore);
const userStore = new UserStore(appStore, notifyStore);
const dashboardStore = new DashboardStore(notifyStore, appStore);

export const storesContext = createContext({
  appStore,
  userStore,
  notifyStore,
  dashboardStore
});
